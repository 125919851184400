import mainimg from './images/popup/mainimg.png'
import img_01_1 from './images/popup/01-1.png'
import img_01_2 from './images/popup/01-2.png'
import img_01_3 from './images/popup/01-3.png'
import img_02_1 from './images/popup/02-1.png'
import img_03_1 from './images/popup/03-1.png'
import img_03_2 from './images/popup/03-2.png'
import img_03_3 from './images/popup/03-3.png'
import img_04_1 from './images/popup/04-1.png'
import img_04_2 from './images/popup/04-2.png'
import img_04_3 from './images/popup/04-3.png'
import img_05_1 from './images/popup/05-1.png'
import img_05_2 from './images/popup/05-2.png'
import img_06_1 from './images/popup/06-1.png'
import img_06_2 from './images/popup/06-2.png'
import img_07_1 from './images/popup/07-1.png'
import img_07_2 from './images/popup/07-2.png'
import img_07_3 from './images/popup/07-3.png'

export default {
    "overview": {
        // "ko": "스타필드 고양점에 위치한 스포츠몬스터는 아날로그와 디지털을 접목시킨 신개념 놀이 문화공간으로 지금까지 경험하지 못했던 다양한 콘텐츠 체험의 기회를 제공하는 공간입니다.\n<br /> 이번 프로젝트에서 파모즈는 Sports Ability 시스템을 접목하여 인터랙티브 미디어 UI를 활용한 새로운 유형의 스포츠 컨텐츠를 제작하였습니다. 각 종목의 디지털 스포츠를 체험하고 그에 해당되는 측정 결과를 총체적으로 분석하여 나의 스포츠 성향을 알 수 있는 시스템입니다.",
        // "en": "Sports Monster located inside Goyang Starfield is a new cultural area that incorporated both analog and digital. It is a space where various contents that could never be seen before can be experienced. For this project, FAMOZ incorporated the Sports Ability system and created a new type of sports contents using interactive media UT. It is a system where it allows users to figure out their own sports styles by experiencing digital sports and analyzing each and every results of the sports and provide an overall analysis.",
        // "jp": "",
        // "cn": ""
    },
    "video": "https://player.vimeo.com/video/433503365",
    "detail": [
        {
            "title": {
                "ko": "Soccer",
                "en": "Soccer",
                "jp": "",
                "cn": ""
            },
            "subtitle": {
                "ko": "Monster Lab-Sport Ability",
                "en": "Monster Lab-Sport Ability",
                "jp": "",
                "cn": ""
            },
            "desc": {
                "ko": "도심 속 옥상 경기장 연출을 위해 Unity 3D엔진을 활용하여 Full 3D로 구현하였으며, Sport Ability 중 순발력과 하지 근력을 측정하여 스포츠 성향을 분석합니다.",
                "en": "It was expressed in full 3D utilizing the unity 3D engine to recreate an outdoor rooftop play area in the city, and the explosive muscular strength and the lower muscular strength is measured to analyze sports style during Sports Ability.",
                "jp": "",
                "cn": ""
            },
            "contents": [
                {
                    "width": "100%",
                    "image": [
                        img_01_1
                    ]
                },
                {
                    "width": "50%",
                    "image": [
                        img_01_2,
                        img_01_3
                    ]
                }
            ]
        },
        {
            "title": {
                "ko": "Baseball",
                "en": "Baseball",
                "jp": "",
                "cn": ""
            },
            "subtitle": {
                "ko": "Monster Lab-Sport Ability",
                "en": "Monster Lab-Sport Ability",
                "jp": "",
                "cn": ""
            },
            "desc": {
                "ko": "정형화된 야구 경기장이 아닌 자유로운 스트릿 스타일을 배경으로 투수가 되어 피칭할 수 있는 체험입니다. 센서를 통하여 Sports Ability 중 순발력과 상지근력을 측정하고 스포츠 성향을 분석합니다.",
                "en": "Rather than the stereotypical baseball arena, the user can be a pitcher in a free street style. Through a sensor, the explosive muscular strength and the lower muscular strength is measured during Sports Ability. ",
                "jp": "",
                "cn": ""
            },
            "contents": [
                {
                    "width": "100%",
                    "image": [
                        img_02_1
                    ]
                }
            ]
        },
        {
            "title": {
                "ko": "Steper",
                "en": "Steper",
                "jp": "",
                "cn": ""
            },
            "subtitle": {
                "ko": "Monster Lab-Sport Ability",
                "en": "Monster Lab-Sport Ability",
                "jp": "",
                "cn": ""
            },
            "desc": {
                "ko": "고전 향수를 일으키는 2D 도트 스타일로 제작되었으며 4명의 플레이어가 경쟁하면서 재미와 승부욕을 불러일으켜 운동효과를 극대화 합니다. 더 빨리, 오래 스텝을 밟는 운동을 통해 근지구력 측정으로 스포츠 성향을 분석합니다.",
                "en": "Was created in a 2D dot style and 4 other plays compete with each other maximizing the exercise effectivity. The sports style is analyzed through muscular endurance measures during exercises that require making steps more quickly and for a longer period of time. ",
                "jp": "",
                "cn": ""
            },
            "contents": [
                {
                    "width": "100%",
                    "image": [
                        img_03_1
                    ]
                },
                {
                    "width": "50%",
                    "image": [
                        img_03_2,
                        img_03_3
                    ]
                }
            ]
        },
        {
            "title": {
                "ko": "American Football",
                "en": "American Football",
                "jp": "",
                "cn": ""
            },
            "subtitle": {
                "ko": "Monster Lab-Sport Ability",
                "en": "Monster Lab-Sport Ability",
                "jp": "",
                "cn": ""
            },
            "desc": {
                "ko": "Unity 3D엔진을 활용한 Full 3D로 구현된 미식 축구장에서 달려오는 수비수를 피해 터치다운까지 달려가는 스토리로 높은 성취감을 제공하며 민첩성과 협응성을 측정하여 스포츠 성향을 분석합니다.",
                "en": "Unity 3D engine was utilized to create this Full 3D touch football ground where you have to run until the touch down point trying to avoid the defender which adds realistic aspects to the game. The sports style is analyzed by measuring the reaction speed and coordination. ",
                "jp": "",
                "cn": ""
            },
            "contents": [
                {
                    "width": "100%",
                    "image": [
                        img_04_1
                    ]
                },
                {
                    "width": "50%",
                    "image": [
                        img_04_2,
                        img_04_3
                    ]
                }
            ]
        },
        {
            "title": {
                "ko": "Trampoline",
                "en": "Trampoline",
                "jp": "",
                "cn": ""
            },
            "subtitle": {
                "ko": "Monster Lab-Sport Ability",
                "en": "Monster Lab-Sport Ability",
                "jp": "",
                "cn": ""
            },
            "desc": {
                "ko": "도심 속에서 몬스터를 물리치는 히어로가 되는 스토리로 몰입감이 증대되도록 제작되었고 트램폴린을 이용한 상하운동을 통해 협응성을 측정하여 스포츠 성향을 분석합니다.",
                "en": "With a story about having to defeat the monster by being a hero in the middle of city, this game adds excitement and concentration level to the users and cooridnation is measured through both top and bottom exercises on the trampoline to find the sports style. ",
                "jp": "",
                "cn": ""
            },
            "contents": [
                {
                    "width": "50%",
                    "image": [
                        img_05_1,
                        img_05_2
                    ]
                }
            ]
        },
        {
            "title": {
                "ko": "Taekwondo",
                "en": "Taekwondo",
                "jp": "",
                "cn": ""
            },
            "subtitle": {
                "ko": "Monster Lab-Sport Ability",
                "en": "Monster Lab-Sport Ability",
                "jp": "",
                "cn": ""
            },
            "desc": {
                "ko": "가상으로 상대 캐릭터와의 겨루기를 하며 직접 샌드백을 타격하여 스트레스를 해소할 수 있는 콘텐츠입니다. 민첩성과 근지구력을 측정하여 스포츠 성향을 분석합니다.",
                "en": "A contents where you fight with a imaginative character, and can release stress by hitting the sandbag yourself. The reaction speed and endurance level is measured to analyze the right sports style. ",
                "jp": "",
                "cn": ""
            },
            "contents": [
                {
                    "width": "50%",
                    "image": [
                        img_06_1,
                        img_06_2
                    ]
                }
            ]
        },
        {
            "title": {
                "ko": "Shouting Nest",
                "en": "Shouting Nest",
                "jp": "",
                "cn": ""
            },
            "subtitle": {
                "ko": "Monster Lab-Sport Ability",
                "en": "Monster Lab-Sport Ability",
                "jp": "",
                "cn": ""
            },
            "desc": {
                "ko": "심플한 카툰스타일의 동화적 배경을 바탕으로 크게 소리를 질러 아두이노 센서의 데시벨 수치를 올려 오브젝트를 파괴하는 콘텐츠입니다.\n<br /> 총 4단계로 진행되며 스텝 별로 점점 높아지는 난이도에서 더욱 큰 소리를 지르다보면 쌓여있던 스트레스가 한순간에 해소됩니다.",
                "en": "With the background of a simple cartoon style background, it is a content where you have to destroy the object by yelling out loud and increase the Arduino sensor’s decibel. It is conducted in a total of 4 different steps, and as the user increases their noise level, they can also relieve their stress at once. ",
                "jp": "",
                "cn": ""
            },
            "contents": [
                {
                    "width": "100%",
                    "image": [
                        img_07_1
                    ]
                },
                {
                    "width": "50%",
                    "image": [
                        img_07_2,
                        img_07_3
                    ]
                }
            ]
        }
    ],
    "mainImg": [
        mainimg
    ]
}