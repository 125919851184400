import React, { Component } from "react";
import { connect } from "react-redux";
import { selectPage } from "../../redux/actions";
import PageWr from "../../common/function/pageWr";
import "./styles/contact.scss";
import i18n from "../../i18n/i18n";
// import map from '../../_assets/other_image/contact_map/map.png';
import { Helmet } from "react-helmet";
class Contact extends Component {
  componentDidMount() {
    this.props.onPageMount("contact");
  }

  renderMap = () => {
    const { lang } = this.props;
    if (lang === "ko") {
      return (
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3164.7150914026856!2d127.03726071566135!3d37.51463733485571!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca3f57acaa355%3A0xdf07a21b5062ed48!2z7ISc7Jq47Yq567OE7IucIOqwleuCqOq1rCDrhbztmIQy64-ZIOyEoOumieuhnDEyNeq4uCAzMA!5e0!3m2!1sko!2skr!4v1590460499652!5m2!1sko!2skr"
          width="600"
          height="450"
          frameborder="0"
          allowfullscreen=""
        ></iframe>
      );
    } else if (lang === "en") {
      return (
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3164.715186172853!2d127.0375689!3d37.5146351!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca3f57acaa355%3A0xdf07a21b5062ed48!2s30%20Seolleung-ro%20125-gil%2C%20Nonhyeon%202(i)-dong%2C%20Gangnam-gu%2C%20Seoul!5e0!3m2!1sen!2skr!4v1590405858925!5m2!1sen!2skr"
          width="600"
          height="450"
          frameborder="0"
          allowfullscreen=""
        ></iframe>
      );
    }
  };
  render() {
    console.log(this.props.lang);
    return (
      <PageWr white={true} padding={false} style={{ width: "100%" }}>
        <div className="contact">
          <Helmet>
            <meta charSet="utf-8" />
            <title>CONTACT | LENNON | Official Website</title>
            <meta
              name="description"
              content="Comemercial, Visual Effects, Post-Production, AI+CGI"
              data-react-helmet="true"
            />
            <meta
              name="keywords"
              content="CONTACT,연착처,전화번호,주소,스튜디오 레논,레논 스튜디오"
              data-react-helmet="true"
            />
            <link
              rel="canonical"
              href="http://lennon.co.kr/contact"
              data-react-helmet="true"
            />

            <meta
              property="og:type"
              content="website"
              data-react-helmet="true"
            />
            <meta
              property="og:title"
              content="CONTACT | LENNON | Official Website"
              data-react-helmet="true"
            />
            <meta
              property="og:description"
              content="스튜디오 레논 Comemercial, Visual Effects, Post-Production, AI+CGI"
              data-react-helmet="true"
            />
            <meta
              property="og:image"
              content="https://drive.google.com/uc?export=download&id=1M08_nZzipZnl73h_RH3kVrZV1Zlxf9WA"
              data-react-helmet="true"
            />
            <meta
              property="og:url"
              content="http://lennon.co.kr/contact"
            ></meta>

            <meta
              name="twitter:title"
              content="CONTACT | LENNON | Official Website"
              data-react-helmet="true"
            />
            <meta
              name="twitter:description"
              content="One-Stop Creative Platform, Comemercial, Visual Effects, Post-Production, AI+CGI"
              data-react-helmet="true"
            />
            <meta
              name="twitter:image"
              content="https://drive.google.com/uc?export=download&id=1M08_nZzipZnl73h_RH3kVrZV1Zlxf9WA"
              data-react-helmet="true"
            />
          </Helmet>
          <div className="scroll">
            <div className="contact_us">
              Contact Us <br />
            </div>
            <span className="span_wrap" />
            <div className="textDesc">
              <div className="imgMap">{this.renderMap()}</div>

              <div className="explanation">
                <div className="top">
                  {i18n.t("contactus.address1")} <br></br>
                  {i18n.t("contactus.address2")}
                  <br></br>
                  {i18n.t("Tel.Tel_one")}
                </div>
                <div className="bottom">
                  <div className="title">Partnership.</div>
                  <div className="text">
                    <a href="mailto:business@lennon.co.kr" title="">
                      business@lennon.co.kr
                    </a>
                  </div>
                  <br></br>
                  <br></br>
                  <div className="title">Careers.</div>
                  <div className="text">
                    <a href="mailto:John@lennon.co.kr" title="">
                      john@lennon.co.kr
                    </a>
                  </div>
                  <br></br>
                  <br></br>
                  <div className="title">Services</div>
                  <div className="text1">
                    {i18n.t("contactus.business1")} <br></br>
                    {i18n.t("contactus.business2")} <br></br>
                    {i18n.t("contactus.business3")}
                    <br></br>
                    {i18n.t("contactus.business4")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWr>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.langChange.value,
});
const mapDispatchToProps = (dispatch) => {
  return {
    onPageMount: (value) => dispatch(selectPage(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
