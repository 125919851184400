import React, { Component } from 'react'
import './styles/solutionInfo.scss';

export default class SolutionIntro extends Component {

  render() {
    return (
        <div className="solutionInfo_" >
          <div className="top">
            <div className="top_in">
          <p className="bold_text_info">
              기존의 공간에 특별한 가치를 부여하고 싶으신가요?
          </p>
          <p className="small_text">
              파모즈는 다양한 뉴미디어 공간을 구축한 노하우를 바탕으로 방문객에게<br/>
              새로운 경험과 가치를 전달하기 위해 데이터를 분석하고 효과적으로 관리할 수 있는 통합 솔루션을 제공합니다.<br/>
              <span className="margin_top_text">이를 통해 공간의 가치를 올리고 방문 고객에게 새로운 경험과 즐거운 체험을 제공해보세요.</span>
            </p>
            </div>
          </div>
          <div className="bottom">
            <div className="bottom_in">
            <p className="bold_text_feature">
              <span className="span_wrap">
                FEATURE
              </span>
            </p>
            <p className="middle_text_feature">호라이즌을 도입하여 ...</p>
            <ul className="bottom_list">
              <li>
                직관적이고 전문적인 관리도구를 이용하여 매니지먼트 코스트를 절감해보세요.
              </li>
              <li>
                플랫폼에 제약받지 않는 수많은 콘텐츠를 관리해보세요.
              </li>
              <li>
                어떠한 데이터 구조도 관리 가능한 데이터 관리 체계를 구축해보세요.
              </li>
              <li>
                자동으로 수집된 로그를 바탕으로 신속하고 정확하게 장애에 대응하세요.
              </li>
            </ul>
            </div>
        </div>
       </div>
    )
  }
}