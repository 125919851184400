import { EventEmitter } from "events";


export default class FullpageEvent extends EventEmitter {
    static _;

    constructor() {
        super();
        this.map = new Map();

        this.on("openPopup",  () => {
            for(const key of this.map.keys()) {
                if(key === "openPopup") {
                    this.map.get(key)();
                }
            }
        });

        
        this.on("closePopup",  () => {
            for(const key of this.map.keys()) {
                if(key === "closePopup") {
                    this.map.get(key)();
                }
            }
        });
    }

    static root = () => {
        if(this._ === undefined) {
            this._ = new FullpageEvent();
        }
        return this._;
    }

    setEventListener = (key, func) => {
        this.map.set(key, func);
    }

}