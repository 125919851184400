import React, { Component } from 'react';
import PageWr from '../../../../../common/function/pageWr'
import './kimhan01.scss'
import port from '../portfolio/port-03.jpg'

class kimhan03 extends Component {
    render() {
        return (
            <PageWr>
                <div className='KimImgContainer'>
                    <div className='Name'>김한욱</div>
                    <div className='Job'>ART DIRECTOR [GOUST]</div>
                    <div className='Text'>DIGITAL PRINT / LOOK BOOK / C.I B.I / PACKAGE DESIGN</div>
                    <img className='Portfolio' src={port} alt='portfolio'></img>
                </div>
            </PageWr>
        );
    }
}

export default kimhan03;