import React, { Component } from "react";
import { connect } from "react-redux";
import PopupWr from "../../../common/render/popupWr";
import CardInner from "./cardInner";
import "../styles/worksPopup.scss";
//import NET from '../../../common/function/net';
import i18n from "../../../i18n/i18n";
import { openPopup } from "./../../../redux/actions/index";

class WorksPopup extends Component {
  renderProjectDetail = datas => {
    if (!datas) return;
    let result = [];

    for (let i = 0; i < datas.length; i++) {
      const dt = datas[i];
      result.push(<CardInner key={i} data={dt} />);
    }
    return result;
  };

  handleOnClickContactUs = async () => {
    await this.props.openPopup(false, null);
    await this.props.setStateHandler("open", true); 
  };

  render() {
    if (this.props.data === undefined || this.props.data.length <= 0) {
      return <PopupWr></PopupWr>;
    }

    // 원래 서버에서 가져왔을때 로직
    const { mainImg, overview, video, detail } = this.props.data;
    const { handleOnClickContactUs } = this;
    const { name, company, type, experience, extext, youtube } = this.props.worksTitle;

    return (
      <PopupWr height={"100%"}>
        <div className="mainImage">
          <img src={mainImg[0]} alt="메인 이미지" />
        </div>

        {/* <div className="mainVideo">
          <iframe
            id="iMovie"
            title="mainVideo"
            name="movieFrame"
            width={"100%"}
            height={"100%"}
            src={video}
            frameBorder="0"
            allowFullScreen=""
            mozallowfullscreen=""
            webkitallowfullscreen=""
          />
        </div> */}

        <div className="overView">
          <div className="overViewWrap">
            <div className="left-title">
              {/* <div className="type">{type}</div> */}
              <div>
                <div className='firstline'>
                  <div className="name">{name[i18n.language]}</div>
                  {/* <div className="company">{company[i18n.language]}</div> */}
                  <div className="company" dangerouslySetInnerHTML={{ __html: company[i18n.language] }} />
                </div>
                {/* <div className="experience">{experience[i18n.language]}</div> */}
                <div className="experience" dangerouslySetInnerHTML={{ __html: experience[i18n.language] }} />

                {/* <div className="extext">{extext[i18n.language]}</div> */}

              </div>
              <div>
                <div className="extext" dangerouslySetInnerHTML={{ __html: extext[i18n.language] }} />
                {/* <div className="extext">{extext[i18n.language]}</div> */}
                <div className="youtube" dangerouslySetInnerHTML={{ __html: youtube[i18n.language] }} />
              </div>
            </div>
            {/* <div className="right-title">
              <div
                dangerouslySetInnerHTML={{ __html: overview[i18n.language] }}
              />
            </div> */}
          </div>

          {/* <div className="border"></div>
          <div></div> */}
        </div>
        {/* <div className="description"> */}
        {/* <div dangerouslySetInnerHTML={{ __html: overview[i18n.language] }} /> */}
        {/* { danger  overview } */}
        {/* </div> */}

        {/* <div className="overView"> */}
        {/* <p className="title">PROJECT Detail</p> */}
        {/* <p className="border"></p> */}
        {/* </div> */}

        {/* {this.renderProjectDetail(detail)} */}
        {/* <div className="contact_works" onClick={e => handleOnClickContactUs()}>
          Contact us
        </div> */}
      </PopupWr>
    );
  }
}

const getProps = state => {
  return {
    lang: state.langChange.value
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openPopup: (open, value) => dispatch(openPopup(open, value))
  };
};

export default connect(getProps, mapDispatchToProps)(WorksPopup);
