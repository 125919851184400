import React, { Component } from 'react'
import { connect } from 'react-redux'
import Card from './card';

class CardWr extends Component {

  state = {
    index: 0
  }

  renderCards = (data) => {
    let result = [];
    data = data.sort((a,b) => {
      const ao = Number(a.order);
      const bo = Number(b.order);
      return bo < ao ? -1 : bo > ao ? 1 : 0;
    })

    for (let i = 0; i < data.length; i++) {
      const dt = data[i];
      result.push(
        <Card getSelectData={this.props.getSelectData} keys={i} key={i} data={dt} />
      )
    }

    return result;
  }

  render() {
    const {selectTab, data} = this.props;

    return (
      <div className={"card_wr " + selectTab}>
        <div className="scroll_genuin">
          {this.renderCards(data)}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    selectTab: state.selectTabs.value
  }
}

export default connect(mapStateToProps, undefined)(CardWr)
