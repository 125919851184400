import React, { Component } from 'react'
import { connect } from 'react-redux'
import PageWr from '../../common/function/pageWr';
import CardWr from './function/cardWr';
import './styles/works.scss';
import MenuTab from './function/menuTab';
import Footer from '../../common/render/footer';
import { selectPage, openPopup } from '../../redux/actions';
import WorksPopup from './function/worksPopup';
// import NET from './../../common/function/net';
import Constants from '../../common/function/constants'
import SuccessPopup from './../../common/render/successPopup';
import ContactPopup from './../home/function/contactPopup';
import i18n from '../../i18n/i18n';
import { Helmet } from 'react-helmet';
class WorksGenuin extends Component {

  _isMounted = false;

  state = {
    work: [],
    worksTitle : [],
    selectCardData: [],
    renderer: false,
    modalOpen: false,
    open: false,
    home: null,
    selectType: "",
    selectMenuType: "",
  }

  // constructor() {
  //   super();
  //   this.net = new NET();
  // }

  componentDidMount() {
    this._isMounted = true;

    this.props.onPageMount("works");
    this.getWorksData();
    this.getHomeData()
  }


  componentWillUnmount() {
    this._isMounted = false;
    this.setState({
      renderer: false
    });
  }

  getWorksData = () => {
    // 대충 처음에 서버에서 데이터 불러오는 메서드
    //처음에 전체데이터를 가져오는 함수를 사용함
    //아래 코드는 type을 가져와서 특정 데이터를 가져오게함.
    // const data = Constants.WorkGenuin() <-전체 데이터를 가져옴
    const data = Constants.WorkGenuin() //<- type이 yang jeongrim 만 가져옴
    if (this._isMounted) {
      this.setState({ work: data });
    }
  }

  getSelectData = (title, name, all) => {
    const data = Constants.WorkIdGenuin(title)
    const worstitle = Constants.WorkTitleGenuin(title)
    this.setState({ selectCardData: data,worksTitleGenuin : worstitle, selectType: name, selectMenuType: all.type }, () => {
      this.props.openPopup(true, title);
    });
    // 대충 서버에서 이름으로 특정 데이터만 가져오는 메서드
  }

  getDataOfType = (types, name, all) => {
    let type;
    let data;
    switch (types) {
      case "ALL":
        type = false;
        break
      case "CM":
        type = "CM";
        break;
      case "VFX":
        type = "VFX";
        break;
      case "CR":
        type = "CR";
        break;
      default:
        break;
    }
    if (!type) {
      data = Constants.WorkGenuin()
    } else {
      data = Constants.WorkTypeGenuin(type)
    }
    this.setState({ work: data });
  }

  getHomeData = () => {
    const data = Constants.Home()
    this.setState({ home: data, render: true }, () => {
      this.setState({ home: data[4], renderer: true })
    });
  }

  setStateHandler = (name, value) => {
    this.setState({ [name]: value })
  }  
  
  // 메일 콘택트  
  postMailContact = (data) => {
    // this.net.post(`/api/mail/post`, {
    //   query : {
    //     category: data.category,   // 카테고리(옵션)
    //     company: data.company,  // 회사
    //     name: data.name,    // 이름
    //     phone: data.phone,   // 번호
    //     email: data.email,      // 이메일(옵션)
    //     desc: data.desc,      // 설명
    //     type: this.state.selectMenuType,
    //     title: this.state.selectType
    //   }
    // })
    // .then(res => {
    //     this.setState({ modalOpen: true, selectType: "", selectMenuType: "" }, () => {
    //   })
    // })
    // .catch(err => {
    // })
  }


  render() {

    const { work, selectCardData, modalOpen, open, home, selectType ,worksTitleGenuin} = this.state;
    const { getSelectData, getDataOfType, setStateHandler, postMailContact } = this;

    if (this.state.renderer) {
      return (
        <React.Fragment>
          <PageWr image={false} white={true}>
            <div className="works_genuin">
            <Helmet>
                <meta charSet="utf-8" />
                <title>WORKS | LENNON | Official Website</title>
                <meta name="description" content="LENNON WORKS, Commercial, Showreel, Motion " data-react-helmet="true"/>
                <meta name="keywords"content="WORKS,스튜디오 레논,레논 스튜디오,LENNON,studio lennon" data-react-helmet="true"/>
                <link rel="canonical" href="http://lennon.co.kr/works" data-react-helmet="true"/>

                <meta property="og:type" content="website" data-react-helmet="true"/> 
                <meta property="og:title" content="WORKS | LENNON | Official Website" data-react-helmet="true"/>
                <meta property="og:description" content="스튜디오 레논 One-Stop Creative Platform, Comemercial, Visual Effects, Post-Production, AI+CGI" data-react-helmet="true"/>
                <meta property="og:image" content="https://drive.google.com/uc?export=download&id=1M08_nZzipZnl73h_RH3kVrZV1Zlxf9WA" data-react-helmet="true"/>
                <meta property="og:url" content="http://lennon.co.kr/works"></meta>

                <meta name="twitter:title" content="WORKS | LENNON | Official Website" data-react-helmet="true"/>
                <meta name="twitter:description" content= "스튜디오 레논 One-Stop Creative Platform, Comemercial, Visual Effects, Post-Production, AI+CGI" data-react-helmet="true"/>
                <meta name="twitter:image" content="https://drive.google.com/uc?export=download&id=1M08_nZzipZnl73h_RH3kVrZV1Zlxf9WA" data-react-helmet="true"/>
            </Helmet>
              <div className="logo_title">
                {/* <div className="logo">
                  <NavLink to="/">
                    <img src={Icon} alt={"FAMOZ Logo"} />
                  </NavLink>
                </div> */}
                <div className="title">
                  <p className="bold"><h1>WORKS</h1></p>
                  {/* <p>our work design</p> */}
                </div>
              </div>

              <div className="card_all_wrapper">
                <MenuTab getDataOfType={getDataOfType} />
                <CardWr getSelectData={getSelectData} data={work} />
              </div>

              <Footer />
              
            </div>
            <WorksPopup setStateHandler={setStateHandler} data={selectCardData} worksTitleGenuin = {worksTitleGenuin} />
            <SuccessPopup title={i18n.t("home.thanks1")/*감사합니다*/} open={modalOpen} subtitle={i18n.t("home.thanks2")/*성공적으로 작업을 마쳤습니다*/} description={i18n.t("home.thanks3"/*최대한 빠르게 답변 드리겠습니다.*/)} close={(e) => setStateHandler("modalOpen", false)}/>

          </PageWr>
          <ContactPopup contents={selectType} wrapper={"works_popup"} setStateHandlerProps={setStateHandler} data={home} open={open} postMailContact={postMailContact} />
        </React.Fragment>
      );
    }
    else {
      return (<div />);
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onPageMount: (value) => dispatch(selectPage(value)),
    openPopup: (open, value) => dispatch(openPopup(open, value))
  }
}

export default connect(undefined, mapDispatchToProps)(WorksGenuin)
