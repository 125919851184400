import mainimg from './images/popup/mainimg.png'
import img_01_1 from './images/popup/01-1.png'
import img_01_2 from './images/popup/01-2.png'
import img_01_3 from './images/popup/01-3.png'
import img_02_1 from './images/popup/02-1.png'
import img_02_2 from './images/popup/02-2.png'
import img_02_3 from './images/popup/02-3.png'
import img_03_1 from './images/popup/03-1.png'
import img_03_2 from './images/popup/03-2.png'
import img_03_3 from './images/popup/03-3.png'
import img_03_4 from './images/popup/03-4.png'
import img_03_5 from './images/popup/03-5.png'
import img_03_6 from './images/popup/03-6.png'

export default {
    "overview": {
        "ko": "포스코 스마트 데이터센터는 포항에 위치해 있으며 세계의 중심이 될 스마트 데이터센터입니다.\n<br /> 이번 프로젝트에서 파모즈는 포스코 스마트 데이터 센터의 커넥티드라는 컨셉으로 미디어 조형물과 라이브러리 3면영상 등 인터렉티브 컨텐츠를 제작하였습니다.",
        "en": "POSCO Smart Data Center is located in Pohang, and is the future smart data center of the world. For this project, FAMOZ has created interactive contents with media objects and library 3D videos, with the concept of “Connected” in mind.",
        "jp": "",
        "cn": ""
    },
    "video": "https://player.vimeo.com/video/287604007",
    "detail": [
        {
            "title": {
                "ko": "Line LED",
                "en": "Line LED",
                "jp": "",
                "cn": ""
            },
            "subtitle": {
                "ko": "POSCO Smart Data Center",
                "en": "POSCO Smart Data Center",
                "jp": "",
                "cn": ""
            },
            "desc": {
                "ko": "키넥티드를 표현한 LINE LED를 이용하여 화려한 색감의 모션 그래픽을 감상할 수 있으며 키넥트를 이용한 인터렉티브가 가능한 인터렉티브 조형물입니다.",
                "en": "LINE LED has been used to express connection, and showcase motion graphics in vibrant colors, and is an interactive object using connection.",
                "jp": "",
                "cn": ""
            },
            "contents": [
                {
                    "width": "100%",
                    "image": [
                        img_01_1
                    ]
                },
                {
                    "width": "50%",
                    "image": [
                        img_01_2,
                        img_01_3
                    ]
                }
            ]
        },
        {
            "title": {
                "ko": "미디어 라이브러리",
                "en": "Media Library",
                "jp": "",
                "cn": ""
            },
            "subtitle": {
                "ko": "POSCO Smart Data Center",
                "en": "POSCO Smart Data Center",
                "jp": "",
                "cn": ""
            },
            "desc": {
                "ko": "4계절 영상과 업무관련 템플릿 영상들이 재생되며 근접센서를 이용하여 접근 시 미디어 라이브러리를 통하여 포스코 관련 정보들을 상세히 확인 할 수 있습니다.",
                "en": "Videos of the 4 seasons, and work related template videos are played, and proximity sensors are used to show media library automatically when it is approached.",
                "jp": "",
                "cn": ""
            },
            "contents": [
                {
                    "width": "100%",
                    "image": [
                        img_02_1
                    ]
                },
                {
                    "width": "50%",
                    "image": [
                        img_02_2,
                        img_02_3
                    ]
                }
            ]
        },
        {
            "title": {
                "ko": "브리핑 룸",
                "en": "Briefing room",
                "jp": "",
                "cn": ""
            },
            "subtitle": {
                "ko": "POSCO Smart Data Center",
                "en": "POSCO Smart Data Center",
                "jp": "",
                "cn": ""
            },
            "desc": {
                "ko": "간편한 버튼 조작으로 테이블 위치 설정이 가능하며 PC를 통하여 포스코 3면영상과 360도 VR투어를 체험할 수 있으며 윈도우 모드를 사용하여 PPT등을 이용한 회의도 가능합니다.",
                "en": "Table layout can be adjusted with simple button controls. 3D video and 360 VR tour can be experienced via PC, and Windows mode is also available for using Power Point for presentations during conferences.",
                "jp": "",
                "cn": ""
            },
            "contents": [
                {
                    "width": "50%",
                    "image": [
                        img_03_1,
                        img_03_2,
                        img_03_3,
                        img_03_4,
                        img_03_5,
                        img_03_6
                    ]
                }
            ]
        }
    ],
    "mainImg": [
        mainimg
    ]
}