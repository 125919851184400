import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./styles/menuArea.scss";
import { connect } from "react-redux";
import * as actions from "../redux/actions/index";
import i18n from "../i18n/i18n";

class menuArea extends Component {
  state = {
    selectedOption: null,
    menu: ["home", "works", "supermen", "executives", "contact"],
    checked: false,
    isMobile: false,
  };

  componentDidMount = () => {
    if (window.innerWidth <= 414) {
      this.setState({ isMobile: true });
    }
  };

  componentDidUpdate = (prevPros) => {
    if (prevPros.path !== this.props.path) {
      this.setStateInput();
    }
  };

  renderMenu = (datas) => {
    let result = [];
    const { path } = this.props;

    for (let i = 0; i < datas.length; i++) {
      const dt = datas[i];
      const name = this.initCap(dt);
      result.push(
        <NavLink
          key={i}
          activeClassName="active"
          className={path === dt ? "list select" : "list noSelect"}
          exact
          to={dt === "home" ? "/" : "/" + dt}
        >
          <li>{name}</li>
        </NavLink>
      );
    }
    return result;
  };

  initCap = (str) => {
    const stri =
      str.substring(0, 1).toUpperCase() +
      str.substring(1, str.length).toLowerCase();
    return stri;
  };

  setStateInput = (e) => {
    if (!e) {
      this.setState({ checked: false });
      return;
    }
    this.setState({ checked: e.target.checked });
  };

  handleChange = (selectedOption) => {
    this.setState({});
    i18n.changeLanguage(selectedOption);
    this.props.langChange(selectedOption);
  };

  renderToLanguageSelect = () => {
    if (this.state.isMobile) {
      if (this.state.checked) {
        return (
          <div className="changeLangWrapper">
            <div
              className={
                i18n.language === "ko" ? "changeLang select" : "changeLang"
              }
              onClick={(e) => {
                this.handleChange("ko");
              }}
            >
              KR
            </div>
            <div
              className={
                i18n.language === "en"
                  ? "changeLang en select"
                  : "changeLang en"
              }
              onClick={(e) => {
                this.handleChange("en");
              }}
            >
              EN
            </div>
          </div>
        );
      } else {
        return <div />;
      }
    } else {
      return (
        <div className="changeLangWrapper">
          <div
            className={
              i18n.language === "ko" ? "changeLang select" : "changeLang"
            }
            onClick={(e) => {
              this.handleChange("ko");
            }}
          >
            KR
          </div>
          <div
            className={
              i18n.language === "en" ? "changeLang en select" : "changeLang en"
            }
            onClick={(e) => {
              this.handleChange("en");
            }}
          >
            EN
          </div>
        </div>
      );
    }
  };

  render() {
    const { menu, checked } = this.state;
    return (
      <div className="menuAreaWrapper">
        <nav role="navigation">
          <div id="menuToggle">
            <input
              type="checkbox"
              onChange={(e) => this.setStateInput(e)}
              checked={checked}
            />
            {this.renderToLanguageSelect()}
            <span></span>
            <span></span>
            <span></span>

            <ul id="menu">
              <div className="changeLangWrapperMenu">
                <div
                  className={
                    i18n.language === "ko"
                      ? "changeLangMemnu ko select"
                      : "changeLangMemnu ko"
                  }
                  onClick={(e) => {
                    this.handleChange("ko");
                  }}
                >
                  KR
                </div>
                <div
                  className={
                    i18n.language === "en"
                      ? "changeLangMemnu en select"
                      : "changeLangMemnu en"
                  }
                  onClick={(e) => {
                    this.handleChange("en");
                  }}
                >
                  EN
                </div>
              </div>
              {this.renderMenu(menu)}

              <div className="infoWrapper">
                <p id="adress">
                  {i18n.t("common.address")}
                  <br />
                  {i18n.t("Tel.Tel_one")}
                </p>
                <p className="link">
                  <a
                    href="https://goo.gl/maps/8nzgDkc3Mg7XbMuW6"
                    target="_blank"
                  >
                    View Map
                  </a>
                </p>
                <p>Partnership</p>
                <p className="link">
                  <a href="mailto:business@lennon.co.kr" title="">
                    business@lennon.co.kr
                  </a>
                </p>
                <p>Careers</p>
                <p className="link">
                  <a href="mailto:john@lennon.co.kr" title="">
                    john@lennon.co.kr
                  </a>
                </p>
              </div>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

const props = (state) => {
  return {
    path: state.selectPage.value,
    lang: i18n.language,
  };
};

const mapDispatchToProps = (dispatch) => ({
  langChange: () => dispatch(actions.langChange(i18n.language)),
});

export default connect(props, mapDispatchToProps)(menuArea);
