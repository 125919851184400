import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { Transition, TransitionGroup } from 'react-transition-group';
import { play, exit } from '../reactTransition';

import MainPage from '../fullpage/fullpageWr';
import People from '../page/works/works';
import Works from '../page/works_genuin/works';
import Teams from '../page/teams/teams';
import Contact from '../page/contanct/contact';
import MenuArea from './../menuArea/menuArea';
import { connect } from 'react-redux';
import SolutionInfo from '../page/solution/solutionInfo';
import Kimhan from '../common/function/constants/work/kimImg/kimhan01';
import Kimhan02 from '../common/function/constants/work/kimImg/kimhan02';
import Kimhan03 from '../common/function/constants/work/kimImg/kimhan03';
import Kimhan04 from '../common/function/constants/work/kimImg/kimhan04';
import Kimhan05 from '../common/function/constants/work/kimImg/kimhan05';
import Kimhan06 from '../common/function/constants/work/kimImg/kimhan06';
import Kimhan07 from '../common/function/constants/work/kimImg/kimhan07';

// import SuccessPopup from '../common/render/successPopup';

class router extends Component {

  state = {
    node: null,
  }

  renderRoute = (path) => {
    if (path === "home") {
      return (
        <React.Fragment>
          <Route exact path="/"
            render={(props) => (
              <MainPage {...props} />
            )} />

          <Route path="/supermen"
            render={(props) => (
              <People {...props} />
            )} />

          <Route path="/works"
            exact
            render={(props) => (
              <Works {...props} />
            )} />

          {/* <Route path="/solution/info"
            render={(props) => (
              <SolutionInfo {...props} />
            )} /> */}

          <Route path="/executives"
            render={(props) => (
              <Teams {...props} />
            )} />

          <Route path="/contact"
            render={(props) => (
              <Contact {...props} />
            )} />

          <Route path="/kimhan"
            render={(props) => (
              <Kimhan {...props} />
            )} />

          <Route path="/kimhan02"
            render={(props) => (
              <Kimhan02 {...props} />
            )} />

          <Route path="/kimhan03"
            render={(props) => (
              <Kimhan03 {...props} />
            )} />

          <Route path="/kimhan04"
            render={(props) => (
              <Kimhan04 {...props} />
            )} />

          <Route path="/kimhan05"
            render={(props) => (
              <Kimhan05 {...props} />
            )} />

          <Route path="/kimhan06"
            render={(props) => (
              <Kimhan06 {...props} />
            )} />
            <Route path="/kimhan07"
                    render={(props) => (
                      <Kimhan07 {...props} />
                    )} />

        </React.Fragment>
      )
    } else {
      return (
        <Route render={({ location }) => {
          const { pathname, key } = location;

          return (
            <TransitionGroup component={null}>
              <Transition
                key={key}
                appear={true}
                transitionLeave={false}
                onEnter={(node, appears) => {
                  play(pathname, node, appears);
                }}
                exit={false}
                onExit={(node, appears) => exit(node, appears)}
                timeout={{ enter: 750, exit: 150 }}>

                <Switch location={location}>
                  <Route exact path="/"
                    render={(props) => (
                      <MainPage {...props} />
                    )} />

                  <Route path="/supermen"
                    render={(props) => (
                      <People {...props} />
                    )} />

                  <Route path="/executives"
                    render={(props) => (
                      <Teams {...props} />
                    )} />

                  <Route path="/works"
                    exact
                    render={(props) => (
                      <Works {...props} />
                    )} />

                  {/* <Route path="/solution/info"
                    render={(props) => (
                      <SolutionInfo {...props} />
                    )} /> */}

                  <Route path="/kimhan"
                    render={(props) => (
                      <Kimhan {...props} />
                    )} />

                  <Route path="/kimhan02"
                    render={(props) => (
                      <Kimhan02 {...props} />
                    )} />

                  <Route path="/kimhan03"
                    render={(props) => (
                      <Kimhan03 {...props} />
                    )} />

                  <Route path="/kimhan04"
                    render={(props) => (
                      <Kimhan04 {...props} />
                    )} />

                  <Route path="/kimhan05"
                    render={(props) => (
                      <Kimhan05 {...props} />
                    )} />

                  <Route path="/kimhan06"
                    render={(props) => (
                      <Kimhan06 {...props} />
                    )} />
                    <Route path="/kimhan07"
                    render={(props) => (
                      <Kimhan07 {...props} />
                    )} />
                  {/* <Route path="/solution/info"
                      render={(props) => (
                        <Solutioninfo {...props} />
                      )} />

                      <Route path="/solution/usecase"
                      render={(props) => (
                        <Solutionusecase {...props} />
                      )} />

                      <Route path="/solution/function"
                        render={(props) => (
                        <Solutionfunction {...props} />
                        )} /> */}

                  <Route path="/contact"
                    render={(props) => (
                      <Contact {...props} />
                    )} />
                </Switch>

              </Transition>
            </TransitionGroup>
          )

        }} />
      )
    }
  }

  render() {
    const { path } = this.props;
    return (
      <Router>
        <Grid container>
          {this.renderRoute(path)}
          <MenuArea />
        </Grid>
      </Router>
    )
  }
}

const props = (state) => {
  return {
    path: state.selectPage.value,
    openPopup: state.openConfirmPopup.value
  }
}

export default connect(props, undefined)(router)