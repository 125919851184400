import React, { Component } from "react";
import clipboardCopy from "clipboard-copy";
import i18n from "../../../i18n/i18n";
import { connect } from "react-redux";


class TeamList extends Component {
  state = {
    showTooltip: []
  };

  componentDidMount = () => {
    let toolTipArray = [];
    for (let i = 0; i < this.props.data.length; i++) {
      toolTipArray.push(false);
    }
    this.setState({ showTooltip: toolTipArray });
  };

  renderList = datas => {
    let results = [];

    datas = datas.sort((a, b) => {
      const ao = Number(b.order);
      const bo = Number(a.order);
      return bo < ao ? -1 : bo > ao ? 1 : 0;
    });

    for (let i = 0; i < datas.length; i++) {
      const dt = datas[i];

      results.push(
        <li
          key={dt.name + i}
          onClick={e => {
            this.setState(
              prevState => {
                const newItems = [...prevState.showTooltip];
                newItems[i] = true;
                return { showTooltip: newItems };
              },
              () => {
                clipboardCopy(dt.email);

                // n초 뒤 원복
                setTimeout(() => {
                  this.setState(prevState => {
                    const newItems = [...prevState.showTooltip];
                    newItems[i] = false;
                    return { showTooltip: newItems };
                  });
                }, 1500);
              }
            );
          }}
        >
        {/* <p className="name" lang={this.props.lang} dangerouslySetInnerHTML={{ __html: dt.name[i18n.language].replace(/<br\/>/gi, '') }} /> */}
        <p className="name" dangerouslySetInnerHTML={{ __html: dt.name[i18n.language].replace(/<br\/>/gi, '') }} />
        </li>
      );
    }
    return results;
  };

  render() {
    const { name, data, peopleImg } = this.props;

    return (
      <div className="list_all">

        <div className="list_">
          <img  src = {peopleImg} alt=""></img>
            <h2 className="list_name"dangerouslySetInnerHTML={{ __html: name }}/>
            <ul className="list_scroll">{this.renderList(data)}</ul>
          </div>
      </div>
    );
  }
}

const getProps = state => {
  return {
    lang: state.langChange.value
  };
};

export default connect(getProps, undefined)(TeamList);
