import React, { Component } from 'react'
import image from '../../_assets/back_image/back_5.jpg';
import './styles/solutionFirst.scss';

export default class SolutionFirst extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="solutionFirst_">
          <div style = {{backgroundImage: `url(${image})`}}>
            <div className="inner_text">
              <p className="md_text bold_text" style={{textAlign:'center'}}>
               FAMOZ SOLUTION
              </p>
              <p className="md_text">
                <span className="middle_text">The World Best Solution<span className="weight600"> To Lead World Market</span></span>
             </p>
            </div>
          </div>  
        </div>
     </React.Fragment>
    )
  }
}
