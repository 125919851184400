import React, { Component } from 'react'
import Reactfullpage from "@fullpage/react-fullpage";
import Sections from './sections';
import { connect } from 'react-redux';
import './styles/fullpageWr.scss';
import { location, selectPage } from '../redux/actions/index';
import ScrollNav from '../navigation/scrollNav';
//import NET from "../common/function/net";
import Constants from '../common/function/constants'
import FullpageEvent from './fullpageEvent';
import { Helmet } from 'react-helmet';
class fullpageWr extends Component {

  state = {
    home: [],
    render: false,
    scroll: true
  }

  scrollEvent;


  constructor() {
    super();
    //this.net = new NET();
    this.scrollEvent = FullpageEvent.root();

    // 스크롤 되면 안돼
    this.scrollEvent.setEventListener("openPopup", () => {
      this.setState({ scroll: false });
    });

    // 스크롤 사용 가능
    this.scrollEvent.setEventListener("closePopup", () => {
      this.setState({ scroll: true });
    });

  }

  anchors = [
     "secondPage","firstPage", "thirdPage","fifthPage", "fourthPage"
  ]

  componentDidMount = () => {
    this.props.onPageMount("home");
    this.getData();
  }

  componentWillUnmount = () => {
    this.setState({ render: true });
  }

  getData = () => {
    this.setState({ home: Constants.Home(), render: true }, () => {
      if (window.location.href.match("#") !== null) {
        const page = String(window.location.href).slice(window.location.href.lastIndexOf("#") + 1)
        this.props.onScrollPage(page);
      } else {
        this.props.onScrollPage(this.anchors[0]);
      }
    });
  }


  onLeave = (index, nextI, dir) => {
    if (dir === "up") {
      const page = nextI.anchor;
      this.props.onScrollPage(page);
    } else {
      const page = nextI.anchor;
      this.props.onScrollPage(page);
    }
  }

  render() {
    if (this.state.render) {
      return (
        <React.Fragment>
          <Helmet>
                <meta charSet="utf-8" />
                <title>STUDIO LENNON | Official Website</title>
                <meta name="description" content="One-Stop Creative Platform, Comemercial, Visual Effects, Post-Production, AI+CGI" data-react-helmet="true"/>
                <meta name="keywords"content="스튜디오 레논, 레논 스튜디오, LENNON, studio lennon, lennon studio, lennon" data-react-helmet="true"/>
                <link rel="canonical" href="http://lennon.co.kr/" data-react-helmet="true"/>

                <meta property="og:type" content="website" data-react-helmet="true"/> 
                <meta property="og:title" content="STUDIO LENNON | Official Website" data-react-helmet="true"/>
                <meta property="og:description" content="One-Stop Creative Platform, Comemercial, Visual Effects, Post-Production, AI+CGI" data-react-helmet="true"/>
                <meta property="og:image" content="https://drive.google.com/uc?export=download&id=1M08_nZzipZnl73h_RH3kVrZV1Zlxf9WA" data-react-helmet="true"/>
                <meta property="og:url" content="http://lennon.co.kr"></meta>

                <meta name="twitter:title" content="STUDIO LENNON | Official Website" data-react-helmet="true"/>
                <meta name="twitter:description" content= "One-Stop Creative Platform, Comemercial, Visual Effects, Post-Production, AI+CGI" data-react-helmet="true"/>
                <meta name="twitter:image" content="https://drive.google.com/uc?export=download&id=1M08_nZzipZnl73h_RH3kVrZV1Zlxf9WA" data-react-helmet="true"/>
                
                
            </Helmet>
          <Reactfullpage
            
            licenseKey={"91806B22-3FDC4AEF-9AA48D1D-B669247E"}
            anchors={this.anchors}
            onLeave={this.onLeave}
            setKeyboardScrolling={true}
            resetSliders={true}
            dragAndMove={true}
            normalScrollElements={".slide_list_wr, textarea, .info_use_box"}
            render={({ state, fullpageApi }) => {

              if (fullpageApi) {
                fullpageApi.setAllowScrolling(this.state.scroll);
                fullpageApi.setKeyboardScrolling(this.state.scroll);
              }

              return (
                <Sections state={state} home={this.state.home} fullpageApi={fullpageApi} />
              );
            }}
          />
          <ScrollNav />
        </React.Fragment>
      );
    } else {
      return <div />;
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onScrollPage: (value) => dispatch(location(value)),
    onPageMount: (value) => dispatch(selectPage(value))
  }
}

export default connect(undefined, mapDispatchToProps)(fullpageWr)
