import home from "./constants/home";
import team from "./constants/team";
import work from "./constants/work";
import workgenuin from "./constants/work_genuin/";
import workId from "./constants/workId";
import workIdgenuin from "./constants/workId_genuin/";

export default class Constants {
  static Home = () => home;
  static Team = () => team;
  static Work = () => work;
  static WorkTitle = (id) => {
    for (let i = 0; i < work.length; i++) {
      if (work[i].id === id) return work[i];
    }
    return null;
  };
  static WorkId = (id) => workId[id];
  static WorkType = (type) => work.filter((item) => item.type === type);

  // 위에는 People 아래는 진짜 Works
  static WorkGenuin = () => workgenuin;
  static WorkTitleGenuin = (id) => {
    for (let i = 0; i < workgenuin.length; i++) {
      if (workgenuin[i].id === id) return workgenuin[i];
    }
    return null;
  };
  static WorkIdGenuin = (id) => workIdgenuin[id];
  static WorkTypeGenuin = (type) =>
    workgenuin.filter((item) => item.type === type);
}
