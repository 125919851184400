import React, { Component } from 'react'
import SliderWrapper from "react-slick";
import Slider from './slider';
import { connect } from 'react-redux';


class SliderWr extends Component {

  state = {
    settings: {
      dots:true,
      autoplay: window.innerWidth >= 1024 ? true : false, 
      infinite: false,
      autoplaySpeed: 2000,
      speed: 500,
      slidesToShow: window.innerWidth >= 1024 ? 4 : 1,
      slidesToScroll: 1,
    }
  }

  slider = null;

  componentWillReceiveProps = (next) => { 
    if(this.props.slickStart !== next.slickStart) { 
      // 페이지가 달라졌을때 slick의 페이지를 0번째로 초기화
        if (!this.props.data) return;
        this.slider.slickGoTo(0);
      }
  }

  componentDidMount = (e) => {
      window.addEventListener("resize", this.updateDimensions);
  };

  componentWillUnmount = () => {
      window.removeEventListener("resize", this.updateDimensions);
  };
  
  updateDimensions = (window) => {
    if ( window.target.innerWidth >= 1024) {
      this.setState({
        settings: {
          autoplay: window.innerWidth >= 1024 ? true : false,
          infinite: false,
          speed: 200,
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 0,
        }
      })
    } 
    else if ( window.target.innerWidth < 1024) { 
      this.setState({
        settings: {
          autoplay: window.innerWidth >= 1024 ? true : false,
          infinite: false,
          // lazyLoad: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      })
    }
  };

  renderSlide = (datas) => {
    if (!datas) return;

    let result = [];
    const { fullpageApi } = this.props; 
    
    datas = datas.sort( function(a,b)
     {
        return b.year-a.year;
     });

    if(datas !== undefined) {
      for (let i = 0; i < datas.length; i ++) {
        const dt = datas[i];
        result.push(
          <Slider fullpageApi={fullpageApi} /*keys={i}*/ key={i} data={dt} />
        );
      }     
    }
    
    return result;
  }
      
  render() {
    const {data} = this.props;
     return (
        <SliderWrapper ref={slider => (this.slider = slider)}      
        {...this.state.settings}>
            {this.renderSlide(data)}
        </SliderWrapper>
    )
  }
}

const getProps = (state) => {
  return {
    slickStart: state.location.value
  }
}

export default connect (getProps , undefined) (SliderWr);