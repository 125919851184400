import React, { Component } from "react";
import Section from "../../fullpage/section";
import famozTypo from "../../_assets/other_image/logo/famoztypo.png";
import { NavLink } from "react-router-dom";
import "./styles/fifthPage.scss";
//import NET from '../../common/function/net';
import ContactPopup from "./function/contactPopup";
import { connect } from "react-redux";
import { openConfirmPopup } from "../../redux/actions";
import SuccessPopup from "./../../common/render/successPopup";
import FullpageEvent from "./../../fullpage/fullpageEvent";
import i18n from "../../i18n/i18n";
import mailiconsvg from "../../../src/common/function/constants//home/images/email.svg";
import moveiconsvg from "../../../src/common/function/constants//home/images/move.svg";
import logosvg from "../../../src/common/function/constants//home/images/logo.svg";

class FifthPage extends Component {
  state = {
    open: false,
    modalOpen: false,
  };

  // constructor() {
  //   super();
  //   //this.net = new NET();
  // }

  setStateHandler = (name, value) => {
    if (name === "open") {
      if (value === true) {
        // disableScrol l.on();
        FullpageEvent.root().emit("openPopup");
      } else {
        // disableScroll.off();
        FullpageEvent.root().emit("closePopup");
      }
    }
    this.setState({ [name]: value });
  };

  // 메일 콘택트
  postMailContact = (data) => {
    // this.net.post(`/api/mail/post`, {
    //   query : {
    //     category: data.category,   // 카테고리(옵션)
    //     company: data.company,  // 회사
    //     name: data.name,    // 이름
    //     phone: data.phone,   // 번호
    //     email: data.email,      // 이메일(옵션)
    //     desc: data.desc      // 설명
    //   }
    // })
    // .then(res => {
    //   this.setState({ modalOpen: true })
    // })
    // .catch(err => {
    // })
  };

  render() {
    const home = this.props.home;
    const { open, modalOpen } = this.state;
    const { setStateHandler, postMailContact } = this;

    return (
      <Section image={home.mainImg} classN={"five"}>
        <ContactPopup
          setStateHandlerProps={setStateHandler}
          data={home}
          open={open}
          postMailContact={postMailContact}
        />
        <div className="fifth_">
          <div className="logoimg">
            <img src={logosvg} alt={"logoimg"} />
          </div>
          {/* <img src={famozTypo} className="famoztypo" alt="famoz lion"/> */}
          <div className="one">
            <p>STUDIO LENNON</p>
            <div className="white">Best Partner</div>
            <span>For Content Solution</span>
          </div>
          <div className="two"></div>
          <div className="three">
            <div className="threeInner">
              <div className="three_inner_wrapper">
                <h3>Partnership.</h3>

                <div
                  // onClick={e => setStateHandler("open", true)}
                  className="emailArea"
                >
                  <a
                    href="mailto:business@lennon.co.kr"
                    title="지금 LENNON의 파트너가 되세요!"
                  >
                    <p>{home === undefined ? "" : home.partnership}</p>
                  </a>

                  <div className="mailicon">
                    <img src={mailiconsvg} alt={"mailicon"} />
                  </div>
                </div>
              </div>
            </div>
            <div className="threeInner">
              <h3>Careers.</h3>
              <div className="emailAreaTwo">
                <a
                  href="mailto:john@lennon.co.kr"
                  title="지금 LENNON에 지원해 보세요 !"
                >
                  <p>{home === undefined ? "" : home.career}</p>
                </a>
                <div className="mailicon">
                  <img src={mailiconsvg} alt={"mailicon"} />
                </div>
              </div>
            </div>
            <div className="threeInner">
              <h3>Supermen.</h3>
              <div className="lernMoreArea">
                <NavLink to={"/supermen"}>
                  <div className="learnText">Learn more about us</div>
                </NavLink>
                <div className="moveicon">
                  <img src={moveiconsvg} alt={"learn more"} />
                </div>
              </div>
            </div>
            {/* <div className='lennon_logo'>
              <img src={logosvg} alt={logosvg}></img>
            </div> */}
          </div>
          <SuccessPopup
            title={i18n.t("home.thanks1") /*감사합니다*/}
            open={modalOpen}
            subtitle={i18n.t("home.thanks2") /*성공적으로 작업을 마쳤습니다*/}
            description={i18n.t(
              "home.thanks3" /*최대한 빠르게 답변 드리겠습니다.*/
            )}
            close={(e) => setStateHandler("modalOpen", false)}
          />
        </div>
      </Section>
    );
  }
}

const getProps = (state) => {
  return {
    lang: state.langChange.value,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openPopup: (open, value) => dispatch(openConfirmPopup(open, value)),
  };
};

export default connect(getProps, mapDispatchToProps)(FifthPage);
