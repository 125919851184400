import React, { Component } from 'react'
import { connect } from 'react-redux'
import TeamList from './teamList';
import i18n from '../../../i18n/i18n';

export class TeamListWr extends Component {

    state = {
        a_t: [],
        b_t: [],
        c_t: [],
        d_t: []
    }

    sortArray = (datas) => {
        const { business, develop, motion, design } = datas;
        this.setState({
            a_t: business, b_t: develop, c_t: motion, d_t: design
        })
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.team !== this.props.team) {
            const datas = this.props.team;
            if (!datas) { return }
            this.sortArray(datas);
        }
    }

    renderList = (datas, name, peopleImg, idx) => {
        if (!datas) { return }
        return (
            <TeamList key={idx} name={name} data={datas} peopleImg={peopleImg}/>
        )
    }

    renderListAll = (datas) => {
        if (!datas || datas.length === 0) return;
        let result = [];
        for (let i = 0; i < datas.list.length; i++) {
            const dt = datas.list[i];
            result.push(
                this.renderList(dt.member, dt.team[i18n.language], dt.peopleImg, i)
            )
        }

        return result;
    }

    render() {
        const { team } = this.props;

        return (
            <div className="list_wr">
                <div className="wr">
                    {this.renderListAll(team)}
                </div>
                
            </div>
            
            
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(TeamListWr)
