import mainimg_01 from "./images/mainimg-01.jpg";
import mainimg_02 from "./images/mainimg-02.png";
import mainimg_03 from "./images/mainimg-03.png";
import mainimg_04 from "./images/mainimg-04.png";
import mainimg_05 from "./images/mainimg-05.png";

export default [
  {
    title1: "LENNON",
    title2: "STUDIO",
    //title3: "Studio",
    mainImg: [mainimg_02],
  },
  {
    title0: "STUDIO",
    title1: "LENNON",
    title: {
      ko: "Imagine there is no border,\n<br/>One stop creative platform!\n",
      en: "Dreams come true\n<br/>Without that possibility,\n<br/>Nature would not incite\n<br/>us to have them.",
      jp: "",
      cn: "",
    },

    vision: {
      ko: "스튜디오 레논은\n<br/>기업과 브랜드의 변화와 성장을 위한 모든 상상력을 구현하고자 설립되었습니다.\n<br/>최고의 CG/VFX/AI 기술을 가지고 아이디어의 시작부터 그 완성까지\n<br/>모든 크리에이티브 영역을 하나의 파이프라인으로 연결시킨\n<br/>국내 유일의 ‘ONE-STOP CREATIVE PLATFORM’입니다.",
      en: "STUDIO LENNON\n<br/>was founded to make all imaginations that change and grow enterprises and brands become true.\n<br/>With our top CG/VFX/AI technologies, we have connected all creative areas\n<br/>from the start of an idea to its completion into a single pipeline.\n<br/>Making us the nation’s only ‘ONE-STOP CREATIVE PLATFORM’.",
      jp: "",
      cn: "",
    },

    mainImg: [mainimg_01],
  },
  {
    contents: [
      {
        title: {
          ko: "CM Studio",
          en: "CM Studio",
          jp: "CM Studio",
          cn: "",
        },
        description: {
          ko: "<span> Commercial 분야의 최고를 향합니다. </span>\n<br/>\n<br/> 신뢰를 바탕으로한 파트너십, 각 분야의 Specialist와 안정적인 Co-work System은 필요로하는 순간순간의 NEEDS까지 충족시킵니다.\n<br/>\n<br/> 또한, 국내 최고사양의 IT장비와 네트워크 시스템을 갖추어 최상의 퀄리티와 고해상도 작업을 보다 빠르고 효과적으로 구현해낼 수 있습니다.\n<br/>\n<br/> - Composite\n<br/> - Edit \n<br/> - Art\n<br/> - 3D",
          en: "<span> Towards the Best in Commercial </span>\n<br/>\n<br/> A trust-based partnership and a stable collaboration system with experts in each field, satisfy all your needs at every moment. \n<br/>\n<br/> With the top of the line IT equipment and network system, we implement the highest quality and high-resolution projects faster and more effectively.\n<br/>\n<br/> - Composite\n<br/> - Edit \n<br/> - Art\n<br/> - 3D",
          jp: "",
          cn: "",
        },
      },
      {
        title: {
          ko: "VFX Studio",
          en: "VFX Studio",
          jp: "VFX Studio",
          cn: "",
        },
        description: {
          ko: "<span> 뛰어난 팀워크로 새로운 세상을 만들어냅니다.</span>\n<br/>\n<br/> 약 20년 동안의 One-team, 그들이 선보이는 팀워크는 그 누구도 만들어내지 못했던 세상을 당신의 눈 앞에 가져다 놓습니다.\n<br/>\n<br/> VFX 제작에 최적화된 파이프라인을 보유하고 캐릭터 및 FX 솔루션 및 노하우를 가지고 의뢰하는 모든 영상물의 품질을 보장합니다.\n<br/>\n<br/> - Game Cinematic\n<br/> - Animation \n<br/>- Character \n<br/>- 전시/특수영상 \n<br/>- Movie / TV Series VFX",
          en: "<span> Best teamwork creates a brand new world. </span>\n<br/>\n<br/> One-team for about 20 years. Their teamwork will show you the world no one has ever created before.\n<br/>\n<br/> The quality of all projects is guaranteed by the VFX production optimized pipeline, solutions, and know-how for characters/FX.\n<br/>\n<br/> - Game Cinematic\n<br/> - Animation \n<br/>- Character \n<br/>- Display/Special Video \n<br/>- Movie / TV Series VFX",
          jp: "",
          cn: "",
        },
      },
      {
        title: {
          ko: "Creative Studio",
          en: "Creative Studio",
          jp: "Creative Studio",
          cn: "",
        },
        description: {
          ko: "<span>당신의 생각이 처음부터 끝까지 단 한번에 실현됩니다. </span>\n<br/>\n<br/> 국내 최초 오픈 크리에이티브 플랫폼을 통한 ONE STOP SERVICE를 제공합니다.\n<br/>\n<br/> 기획 - 스토리텔링 - 제작까지 하나의 파이프 라인으로 이루어져 ONE FLOW로 완성되는,  이 새롭고 효율적인 크리에이티브 플랫폼은 지금까지 없던 절감된 시간과 비용을 경험하게 하고, 더 나아가 달라진 미디어 환경에 최적화된 IMC 서비스를 누릴 수 있게 합니다.\n<br/>\n<br/> - 전략\n<br/> - 크리에이티브\n<br/> - 프로덕션\n<br/> - 포스트프로덕션 \n<br/>- 미디어마케팅",
          en: "<span>Your ideas come alive all at once.</span>\n<br/>\n<br/>The nation’s first ONE-STOP SERVICE through an open creative platform.\n<br/>\n<br/>This brand new, and effective creative platform that completes Plan-Storytelling-Production into ONE FLOW, saves time and cost like never before. Even further, it lets you enjoy the most optimized IMC service for the new media environment.\n<br/>\n<br/> - Strategy\n<br/> - Creative Planning\n<br/> - Production\n<br/> - Post-production \n<br/>- Media marketing",
          jp: "",
          cn: "",
        },
      },
      {
        title: {
          ko: "New Media Studio",
          en: "New Media Studio",
          jp: "New Media Studio",
          cn: "",
        },
        description: {
          ko: "<span>함께 즐길 수 있는 멀티미디어 엔터테인먼트를 창조합니다.</span>\n<br/>\n<br/> 디자인+기술+공간의 전문가들과 함께 상상을 현실로 만드는 곳, 바로 여기서 시작됩니다.\n<br/>\n<br/> - 컨셉 및 기획\n<br/> - 프러덕션 디자인\n<br/> - 테크니컬 디자인 (시스템 및 하드웨어 설계 및 개발)\n<br/> - 프러덕션 (인터렉티브 & 특수영상)\n<br/> - 통합 및 운영",
          en: "<span>We create Multimedia Entertainment for all to enjoy.</span>\n<br/>\n<br/>Experts of Design + Technology+ Space come together to make imagination into a reality, it all starts right here.\n<br/>\n<br/>- Concept and Planning\n<br/> - Production Design\n<br/> - Technical Design (System / Hardware Design / Development)\n<br/> - Production (Interactive & Special Video)\n<br/>- General Integration and operation",
          jp: "",
          cn: "",
        },
      },
      {
        title: {
          ko: "New BIZ",
          en: "New BIZ",
          jp: "New BIZ",
          cn: "",
        },
        description: {
          ko: "<span>할 수 있는 일들이 늘어납니다.</span>\n<br/>\n<br/> 하루가 다르게 바뀌는 미디어환경에 따른 RND를 진행하고 누적된 ICT 데이터로 또 다른 길을 제시하며 이종산업간의 융합에 도전합니다.\n<br/>\n<br/> 그렇게 할 수 있을지에 대한 의문에서 할 수 있다는 자부심으로 바뀌어 갈 것 입니다\n<br/>\n<br/> - AR / VR / MR\n<br/> - AI + CGI\n<br/> - Virtual Model\n<br/> - Short-form Contents",
          en: "<span>There are more things we can do.</span>\n<br/>\n<br/>We are challenging ourselves to suggest new ways to the convergence of cross-industry by doing R&D and collecting ICT data.\n<br/>\n<br/>From the doubts of its possibilities to the pride of what we can achieve, we will constantly be growing.\n<br/>\n<br/> - AR / VR / MR\n<br/> - AI + CGI\n<br/> - Virtual Model\n<br/> - Short-form Contents",
          jp: "",
          cn: "",
        },
      },
    ],
    mainImg: [mainimg_03],
  },
  [
    // {
    //   year: "2013",
    //   contents: [
    //     {
    //       title: {
    //         ko: "FAMOZ",
    //         en: "FAMOZ",
    //         jp: "FAMOZ",
    //         cn: "FAMOZ"
    //       },
    //       description: {
    //         ko: "개업",
    //         en: "Practice",
    //         jp: "",
    //         cn: ""
    //       }
    //     }
    //   ]
    // },
    // {
    //   year: "2014",
    //   contents: [
    //     {
    //       title: {
    //         ko: "레스모아",
    //         en: "LesMore",
    //         jp: "LesMore",
    //         cn: ""
    //       },
    //       description: {
    //         ko: "레스모아 광고 애니메이션 제작",
    //         en: "Production of Lesmore Advertisement Animation. ",
    //         jp: "",
    //         cn: ""
    //       }
    //     },
    //     {
    //       title: {
    //         ko: "중국 정저우 박물관",
    //         en: "Zhengzhou National Library",
    //         jp: "",
    //         cn: ""
    //       },
    //       description: {
    //         ko:
    //           "중국 정저우 국립도서관 미디어 및 솔루션.\n<br/>내부 미디어 아이템 전체 디자인 및 인터랙티브 솔루션 구축.",
    //         en:
    //           "Media and solution for the national library in Zhengzohu, China. The whole media item for the inside was constructed and an interactive solution was also constructed.",
    //         jp: "",
    //         cn: ""
    //       }
    //     },
    //     {
    //       title: {
    //         ko: "Royal Gems",
    //         en: "Royal Gems",
    //         jp: "Royal Gems",
    //         cn: ""
    //       },
    //       description: {
    //         ko: "태국 방콕 로얄잼 홍보관 미디어 및 솔루션.",
    //         en:
    //           "Media and solution for Bangkok Thailand’s Royal Jam Promotion Center. ",
    //         jp: "",
    //         cn: ""
    //       }
    //     },
    //     {
    //       title: {
    //         ko: "양주 대륜 발전소",
    //         en: "Yangju Daeryun Energy",
    //         jp: "",
    //         cn: ""
    //       },
    //       description: {
    //         ko:
    //           "양주 대륜 발전소 홍보관 미디어 및 솔루션.\n<br/>대형 인터랙티브 월과 프로젝션 맵핑을 이용한 모형을 통해 발전소의 다양한 이야기들을 담아 낸 컨텐츠 제작",
    //         en:
    //           "Media and solution for Daeryun Power Generator’s Promotion Center in Yangju. Contents that holds the story of the power generator was created through large interactive walls and projection mapping. ",
    //         jp: "",
    //         cn: ""
    //       }
    //     },
    //     {
    //       title: {
    //         ko: "China New beacon",
    //         en: "China New beacon",
    //         jp: "",
    //         cn: ""
    //       },
    //       description: {
    //         ko:
    //           "중국 뉴비콘 KTV 전시관 미디어 및 솔루션.\n<br/>사진을 활용한 포토 방명록이자 웰컴 Wall 구축.",
    //         en:
    //           "Media and solution for KTV Display center in New Beacon, China. A welcoming wall using photos were created so that it can be used as a photo foot print of customers. ",
    //         jp: "",
    //         cn: ""
    //       }
    //     },
    //     {
    //       title: {
    //         ko: "DDP 동대문 디자인 플라자",
    //         en: "Dongdaemun Design Plaza",
    //         jp: "",
    //         cn: ""
    //       },
    //       description: {
    //         ko:
    //           "DDP 동대문 디자인 플라자 안내서비스 사업 미디어 및 솔루션.\n<br/>DDP라는 예술적 공간에 정보와 아름다움이 함께 표현되는 미디어 시스템 구축.",
    //         en:
    //           "Media and solution for DDP Design Plaza’s information desk businesses. A media system was constructed where both the information and beauty can be expressed. ",
    //         jp: "",
    //         cn: ""
    //       }
    //     },
    //     {
    //       title: {
    //         ko: "Lotte World",
    //         en: "Lotte World",
    //         jp: "",
    //         cn: ""
    //       },
    //       description: {
    //         ko:
    //           "제2롯데월드 아쿠아리움 미디어 및 솔루션.\n<br/>제2롯데월드 아쿠아리움에 들어가는 다양한 미디어 시스템 구축.\n<br/>입구 외벽의 다면 모니터를 이용한 구성부터 내부 모니터 및 프로젝터에 들어가는 다양한 구성의 컨텐츠 제작 및 설치.",
    //         en:
    //           "Media and solution for Lotte World Mall’s aquarium. Various media systems within the aquarium were constructed. The formation of using various monitors in the outside walls of the entry, along with the contents that go inside the inside monitors and projectors were installed. ",
    //         jp: "",
    //         cn: ""
    //       }
    //     }
    //   ]
    // },
    // {
    //   year: "2015",
    //   contents: [
    //     {
    //       title: {
    //         ko: "Hanwha Galleria",
    //         en: "Hanwha Galleria",
    //         jp: "",
    //         cn: ""
    //       },
    //       description: {
    //         ko: "압구정 갤러리아 백화점 명품관 미디어 파사드.",
    //         en: "Apgujeong Galleria Department Store Luxury Goods Fasad. ",
    //         jp: "",
    //         cn: ""
    //       }
    //     },
    //     {
    //       title: {
    //         ko: "Samsung SDS",
    //         en: "Samsung SDS",
    //         jp: "",
    //         cn: ""
    //       },
    //       description: {
    //         ko:
    //           "삼성 SDS 상암IT센터 신 사옥 통합 미디어 및 솔루션 \n<br/>다양한 방문객과 직원들을 위한 힐링 미디어 시스템 구축\n<br/>공간에 맞는 전체 미디어 설계 및 솔루션, 컨텐츠 제작\n<br/>날씨에 맞춰 계절별, 날씨별, 시간별 감성에 맞게 자동으로 변하는 미디어 시스템\n<br/>데이터센터라는 높은 보안등급을 고려한 관리 솔루션 구축",
    //         en:
    //           "Total media and solution was provided for Samsung SDS Sangam IT Center’s New Building. A healing media system was constructed for various visitors and employees. The total media was constructed according to the concept of the space, and a media system was implemented that automatically changed according to the date of contents depending on weather, season, and time. A management solution with high security levels were introduced considering that it’s a data center. ",
    //         jp: "",
    //         cn: ""
    //       }
    //     },
    //     {
    //       title: {
    //         ko: "LOTTE Stylus",
    //         en: "LOTTE Stylus",
    //         jp: "",
    //         cn: ""
    //       },
    //       description: {
    //         ko:
    //           "롯데 에비뉴엘 남성매장에 설치된 세로 형 미디어 키오스크\n<br/>사용자의 체형, 나이, 목적 등을 입력하면 시즌에 가장 어울리는 남성 셔츠와 타이를 제안해 주는 솔루션 시스템.\n<br/>관리자는 관리 프로그램으로 쉽게 데이터를 수정 변경 가능",
    //         en:
    //           "Vertical media kiosk newly installed at the men’s section at Lotte Avenuel. A solution system that recommends shirts and ties for men depending on the season, after the user types in their body type, age, and purpose. The manager can easily change the data with a management program. ",
    //         jp: "",
    //         cn: ""
    //       }
    //     }
    //   ]
    // },
    {
      year: "Executives",
      contents: [
        {
          title: {
            ko: "재무기획팀",
            en: "",
            jp: "",
            cn: "",
          },
          description: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
          },
        },
        {
          title: {
            ko: "제작관리팀",
            en: "",
            jp: "",
            cn: "",
          },
          description: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
          },
        },
        {
          title: {
            ko: "인사총무팀",
            en: "",
            jp: "",
            cn: "",
          },
          description: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
          },
        },
        {
          title: {
            ko: "IT팀",
            en: "",
            jp: "",
            cn: "",
          },
          description: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
          },
        },
      ],
    },
    {
      year: "CM Studio",
      contents: [
        {
          title: {
            ko: `PD팀`,
            en: ``,
            jp: "",
            cn: "",
          },
          description: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
          },
        },
        {
          title: {
            ko: "Composite Y실",
            en: "",
            jp: "",
            cn: "",
          },
          description: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
          },
        },
        {
          title: {
            ko: `Composite L실`,
            en: ``,
            jp: "",
            cn: "",
          },
          description: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
          },
        },
        {
          title: {
            ko: "Edit실",
            en: "",
            jp: "",
            cn: "",
          },
          description: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
          },
        },
        {
          title: {
            ko: "3D실",
            en: "",
            jp: "",
            cn: "",
          },
          description: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
          },
        },
        {
          title: {
            ko: "Art팀",
            en: "",
            jp: "",
            cn: "",
          },
          description: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
          },
        },
      ],
    },
    {
      year: "VFX Studio",
      contents: [
        {
          title: {
            ko: "PD팀",
            en: "",
            jp: "",
            cn: "",
          },
          description: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
          },
        },
        {
          title: {
            ko: "Design팀",
            en: "",
            jp: "",
            cn: "",
          },
          description: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
          },
        },
        {
          title: {
            ko: "Modeling팀",
            en: "",
            jp: "",
            cn: "",
          },
          description: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
          },
        },
        {
          title: {
            ko: `Anomation팀`,
            en: ``,
            jp: "",
            cn: "",
          },
          description: {
            ko: ``,
            en: ``,
            jp: "",
            cn: "",
          },
        },
        {
          title: {
            ko: "CFX팀",
            en: "",
            jp: "",
            cn: "",
          },
          description: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
          },
        },
        {
          title: {
            ko: "Lighting팀",
            en: "",
            jp: "",
            cn: "",
          },
          description: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
          },
        },
        {
          title: {
            ko: "FX팀",
            en: "",
            jp: "",
            cn: "",
          },
          description: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
          },
        },
        {
          title: {
            ko: "Comp팀",
            en: "",
            jp: "",
            cn: "",
          },
          description: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
          },
        },
        {
          title: {
            ko: "R&D팀",
            en: "",
            jp: "",
            cn: "",
          },
          description: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
          },
        },
      ],
    },
    {
      year: "Creative Studio",
      contents: [
        {
          title: {
            ko: "Story&Shoot",
            en: "",
            jp: "",
            cn: "",
          },
          description: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
          },
        },
        {
          title: {
            ko: "Strategy",
            en: "",
            jp: "",
            cn: "",
          },
          description: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
          },
        },
        {
          title: {
            ko: "Short-form Contents",
            en: "",
            jp: "",
            cn: "",
          },
          description: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
          },
        },
        {
          title: {
            ko: "디지털프린트(고스트)",
            en: "",
            jp: "",
            cn: "",
          },
          description: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
          },
        },
        {
          title: {
            ko: "Viral 영상(허니비)",
            en: "",
            jp: "",
            cn: "",
          },
          description: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
          },
        },
      ],
      mainImg: [mainimg_05],
    },
  ],
  {
    partnership: "business@lennon.co.kr",
    career: "john@lennon.co.kr",
    terms: {
      ko: '개인정보 수집·이용에 대한 동의<br/>\n(주)스튜디오레논에서는 "개인정보 보호법"에 따라 본인의 동의를 얻어 서비스 제공을 위한 개인정보를 수집·이용합니다.<br/>\n  1. 개인정보 수집 목적 및 항목<br/>\n    - 상담 안내 : 회사명, 이름, 휴대폰 번호, 이메일(email)<br/>\n    - 새 컨텐츠 안내 등 마케팅 : 휴대폰 번호, 이메일(email)<br/>\n  2. 자동 수집 목적 및 항목\n    - 수집 항목 : (주)스튜디오레논 홈페이지 내에서 방문한 페이지 목록\n    - 수집 목적 : 개인 맞춤형 상담 준비\n<br/>\n 3. 보유 및 이용기간 : 60일\n<br/><br/>\n개인정보 수집·이용에 동의하지 않을 권리가 있으며 동의를 거부할 경우 상담, 마케팅 답신 등의 안내를 받을 수 없습니다.',
      en: '개인정보 수집·이용에 대한 동의<br/>\n(주)스튜디오레논에서는 "개인정보 보호법"에 따라 본인의 동의를 얻어 서비스 제공을 위한 개인정보를 수집·이용합니다.<br/>\n  1. 개인정보 수집 목적 및 항목<br/>\n    - 상담 안내 : 회사명, 이름, 휴대폰 번호, 이메일(email)<br/>\n    - 새 컨텐츠 안내 등 마케팅 : 휴대폰 번호, 이메일(email)<br/>\n  2. 자동 수집 목적 및 항목\n    - 수집 항목 : (주)스튜디오레논 홈페이지 내에서 방문한 페이지 목록\n    - 수집 목적 : 개인 맞춤형 상담 준비\n<br/>\n 3. 보유 및 이용기간 : 60일\n<br/><br/>\n개인정보 수집·이용에 동의하지 않을 권리가 있으며 동의를 거부할 경우 상담, 마케팅 답신 등의 안내를 받을 수 없습니다.',
      jp: "",
      cn: "",
    },
    mainImg: [mainimg_04],
  },
];
