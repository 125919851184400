import React, { Component } from 'react'
import { connect } from 'react-redux'
import TeamsListWr from './fucntion/teamListWr';
import PageWr from '../../common/function/pageWr';
import './styles/teams.scss';
import { selectPage } from '../../redux/actions';
//import NET from './../../common/function/net';
import Constants from '../../common/function/constants'
import { Helmet } from 'react-helmet';
class Teams extends Component {

  _isMounted = false;

  state = {
    team: [],
    render: false
  }

  // constructor() {
  //   super();
  //   this.net = new NET();
  // }

  componentDidMount() {
    this._isMounted = true;
    this.props.onPageMount("executives");
    this.getTeamsData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.setState({
      render: false
    })
  }

  getTeamsData = () => {
    const data = Constants.Team()
    if (this._isMounted === true) {
      this.setState({ team: data, render: true })
    }
  }

  render() {
    const { team } = this.state;

    if (this.state.render) {
      return (
        <PageWr image={team.mainImg[0]}>
          <Helmet>
                <meta charSet="utf-8" />
                <title>EXECUTIVES | LENNON | Official Website</title>
                <meta name="description" content="Comemercial, Visual Effects, Post-Production, AI+CGI" data-react-helmet="true"/>
                <meta name="keywords"content="EXECUTIVES,창업자,Cofounder,경영자,스튜디오 레논, 레논 스튜디오,  LENNON, studio lennon," data-react-helmet="true"/>
                <link rel="canonical" href="http://lennon.co.kr/executives" data-react-helmet="true"/>

                <meta property="og:type" content="website" data-react-helmet="true"/> 
                <meta property="og:title" content="EXECUTIVES | LENNON | Official Website" data-react-helmet="true"/>
                <meta property="og:description" content="One-Stop Creative Platform, Comemercial, Visual Effects, Post-Production, AI+CGI" data-react-helmet="true"/>
                <meta property="og:image" content="https://drive.google.com/uc?export=download&id=1M08_nZzipZnl73h_RH3kVrZV1Zlxf9WA" data-react-helmet="true"/>
                <meta property="og:url" content="http://lennon.co.kr/executives"></meta>

                <meta name="twitter:title" content="EXECUTIVES | LENNON | Official Website" data-react-helmet="true"/>
                <meta name="twitter:description" content= "One-Stop Creative Platform, Comemercial, Visual Effects, Post-Production, AI+CGI" data-react-helmet="true"/>
                <meta name="twitter:image" content="https://drive.google.com/uc?export=download&id=1M08_nZzipZnl73h_RH3kVrZV1Zlxf9WA" data-react-helmet="true"/>
            </Helmet>
          <div className="teams">
            <div className="title_big">
              <h1>Executives</h1>
            </div>
            <TeamsListWr team={team} />
          </div>
        </PageWr>
      )
    }
    else {
      return (<div />);
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onPageMount: (value) => dispatch(selectPage(value))
  }
}

export default connect(undefined, mapDispatchToProps)(Teams)
