import mainimg from './images/popup/mainimg.png'
import img_01_1 from './images/popup/01-1.png'
import img_02_1 from './images/popup/02-1.png'
import img_03_1 from './images/popup/03-1.png'
import img_04_1 from './images/popup/04-1.png'
import img_05_1 from './images/popup/05-1.png'

export default {
    "overview": {
        "ko": "부산 기장군에 위치한 국립 부산과학관은 빛의 탄생부터 미래의 에너지 기술까지 한번에 만날 수 있는 인터렉티브 경험의 공간입니다.\n<br />이번 프로젝트에서 파모즈는 최첨단 가상현실 기술을 활용하여 과학에 대한 재미를 느끼게 하고, 다양한 인터렉티브 미디어를 통해 시공간 제약 없이 여러가지 현상을 직접 체험할 수 있는 컨텐츠를 제작하였습니다. ",
        "en": "Busan National Science Museum located in the Kijang county of Busan, is full of interactive experiences from the birth of light to the future energy technologies. In this project, FAMOZ has utilized state-of-art VR technologies to create science inspiring contents allowing users to experience various phenomenon, which transcends the restriction of time and space.",
        "jp": "",
        "cn": ""
    },
    "video": "https://player.vimeo.com/video/313323285",
    "detail": [
        {
            "title": {
                "ko": "Full Mapping",
                "en": "Full Mapping",
                "jp": "",
                "cn": ""
            },
            "subtitle": {
                "ko": "Busan Museum",
                "en": "Busan Museum",
                "jp": "",
                "cn": ""
            },
            "desc": {
                "ko": "벽면과 천장 조형물 전체에 이머시브 프로젝션 맵핑을 적용, 공간 전체가 360도 스크린으로 구현되어 최고의 몰입감을 선사합니다. 빅뱅과 우주의 탄생, 지구의 형성과 생명체 진화과정, 과거부터 현재까지의 기술 발전 과정 등을 통해 각 시대별 에너지의 흐름과 변천사를 체험할 수 있습니다. ",
                "en": "Applying the Immersive projection mapping to the entire walls, ceilings and sculptures, the whole space has become a 360 degrees screen, offering a total Immersive experience. The big bang and the birth of the universe, formation of earth and the evolution of life, technological advancements from past to present, the flow and the change of energy can all be visualized and experienced.",
                "jp": "",
                "cn": ""
            },
            "contents": [
                {
                    "width": "100%",
                    "image": [
                        img_01_1
                    ]
                }
            ]
        },
        {
            "title": {
                "ko": "Hologram Screen ",
                "en": "Hologram Screen ",
                "jp": "",
                "cn": ""
            },
            "subtitle": {
                "ko": "Busan Museum",
                "en": "Busan Museum",
                "jp": "",
                "cn": ""
            },
            "desc": {
                "ko": "태양의 에너지 방식인 핵융합과 이를 활용한 미래 에너지를 홀로그램 레이어 입체영상으로 생생하게 보여줍니다.",
                "en": "Hologram layers are used to display 3D video of the Sun’s energy type, the nuclear fusion and how it can be used for future energy.",
                "jp": "",
                "cn": ""
            },
            "contents": [
                {
                    "width": "100%",
                    "image": [
                        img_02_1
                    ]
                }
            ]
        },
        {
            "title": {
                "ko": "Interactive Floor",
                "en": "Interactive Floor",
                "jp": "",
                "cn": ""
            },
            "subtitle": {
                "ko": "Busan Museum",
                "en": "Busan Museum",
                "jp": "",
                "cn": ""
            },
            "desc": {
                "ko": "여러가지 모양의 기물을 이용해 굴절, 반사, 분산, 산란 등 다양한 빛의 현상을 체험해보는 컨텐츠입니다. \n<br/>벽면의 그래픽을 통해 기하학적인 빛의 현상을 이해하고, 기물을 통해 직접 체험할 수 있습니다.",
                "en": "Using various objects, this content allows you to experience various phenomenon of light such as reflections, dispersion, and scatters. It is easy to understand the properties of light with the graphics on the wall, and interactive experiences with the objects.",
                "jp": "",
                "cn": ""
            },
            "contents": [
                {
                    "width": "100%",
                    "image": [
                        img_03_1
                    ]
                }
            ]
        },
        {
            "title": {
                "ko": "Interactive Wall",
                "en": "Interactive Wall",
                "jp": "",
                "cn": ""
            },
            "subtitle": {
                "ko": "Busan Museum",
                "en": "Busan Museum",
                "jp": "",
                "cn": ""
            },
            "desc": {
                "ko": "빛의 파장에 따라 사물이 어떻게 감지되는지를 경험할 수 있는 인터렉티브 미디어 컨텐츠로,\n<br/> 각종 전파에 대한 지식을 시각과 체험을 통해 이해하고, 다양한 빛으로 본 세상을 온 몸으로 경험할 수 있습니다.",
                "en": "This is an interactive media content, showcasing how an object can be perceived differently with different light waves. People can perceive and understand how different waves work, and fully experience the world seen by different lights.",
                "jp": "",
                "cn": ""
            },
            "contents": [
                {
                    "width": "100%",
                    "image": [
                        img_04_1
                    ]
                }
            ]
        },
        {
            "title": {
                "ko": "4D Riding Screen",
                "en": "4D Riding Screen",
                "jp": "",
                "cn": ""
            },
            "subtitle": {
                "ko": "Busan Museum",
                "en": "Busan Museum",
                "jp": "",
                "cn": ""
            },
            "desc": {
                "ko": "해양과학과 조선산업으로 발전된 플로팅 기술을 이용하여 알게된 새로운 바다의 모습을 3면 영상과 4D 체어를 통해 오감으로 만나는 공간입니다.",
                "en": "This place uses 3D display and 4D chair to be immersed in the new appearances of ocean discovered with the floating technology borrowed from ocean science and shipbuilding industry.",
                "jp": "",
                "cn": ""
            },
            "contents": [
                {
                    "width": "100%",
                    "image": [
                        img_05_1
                    ]
                }
            ]
        }
    ],
    "mainImg": [
        mainimg
    ]
}