import React, { Component } from "react";
import { connect } from "react-redux";
import { openPopup } from "../../../redux/actions";
//import NET from '../../../common/function/net';
import { Grow } from "@material-ui/core";
import i18n from "../../../i18n/i18n";

class Card extends Component {
  state = {
    img: "",
    zoom: false
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.data !== this.props.data) {
      const { mainImg } = this.props.data;
      this.setState(
        {
          img: mainImg[0],
          zoom: false
        },
        () => {
          this.setState({ zoom: true });
        }
      );
    }
  };

  componentDidMount = () => {
    const { mainImg } = this.props.data;
    this.setState({
      img: mainImg[0],
      zoom: true
    });
  };

  handleOnClickCard = (title, name, all) => {
    this.props.getSelectData(title, name, all);
  };

  render() {
    const { company, name, type, id } = this.props.data;
    return (
      <Grow
        in={this.state.zoom}
        timeout={{ enter: this.props.keys * 200, exit: 0 }}
      >
        <div
          className="card_pd"
          onClick={e =>
            this.handleOnClickCard(id, name[i18n.language], this.props.data)
          }
        >
          <div className="card">
            <div className="contents_hide">
              <div className="inner_flex">
                <div>
                  <p className="desc">{type.toUpperCase()}</p>
                </div>
                <div>
                  {/* <p className="partners">{company[i18n.language]}</p> */}
                  <p className="title">{name[i18n.language]}</p>
                  <p className="partners" dangerouslySetInnerHTML={{ __html: company[i18n.language] }}/>
                  
                </div>
              </div>
            </div>
            <div
              className="contents_img"
              alt=""
              style={{ backgroundImage: `url(${this.state.img})` }}
            />
          </div>
        </div>
      </Grow>
    );
  }
}

const getProps = state => {
  return {
    lang: state.langChange.value
  };
};

const mapDispatchToProps = dispatch => {
  return {
    openPopup: (open, value) => dispatch(openPopup(open, value))
  };
};

export default connect(getProps, mapDispatchToProps)(Card);
