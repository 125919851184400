import React, { Component } from "react";
import { connect } from "react-redux";
import PopupWr from "../../../common/render/popupWr2";
import CardInner from "./cardInner";
import "../styles/worksPopup.scss";
//import NET from '../../../common/function/net';
import i18n from "../../../i18n/i18n";
import { openPopup } from "./../../../redux/actions/index";
import logoimg1 from "../../../common/function/constants/home/images/logo1.svg";
import Plyr from "plyr-react";
import "plyr-react/dist/plyr.css";

(function () {
  if (typeof window.CustomEvent === "function") return false;

  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: null };
    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );
    return evt;
  }

  window.CustomEvent = CustomEvent;
})();
class WorksPopup extends Component {
  renderProjectDetail = (datas) => {
    if (!datas) return;
    let result = [];

    for (let i = 0; i < datas.length; i++) {
      const dt = datas[i];
      result.push(<CardInner key={i} data={dt} />);
    }
    return result;
  };

  handleOnClickContactUs = async () => {
    await this.props.openPopup(false, null);
    await this.props.setStateHandler("open", true);
  };

  render() {
    if (
      this.props.worksTitleGenuin === undefined ||
      this.props.worksTitleGenuin.length <= 0
    ) {
      return <PopupWr></PopupWr>;
    }

    // 원래 서버에서 가져왔을때 로직
    // const { mainImg, detail } = this.props.data;
    // const { handleOnClickContactUs } = this;
    const {
      name,
      company,
      type,
      // experience,
      // extext,
      // subdectitle1,
      // subdec1,
      video,
      // subdectitle2,
      // subdec2,
      // jobTitle,
      // creditNames,
    } = this.props.worksTitleGenuin;
    console.log(this.props.video, "비디오 주소 :::::::::");
    console.log("Adsfasf");

    this.state = {
      options: {
        // autoplay: true,
        // loop: { active: true },
        // mute :  true ,
        //seekTime: 20,
        // clickToPlay: false,
        controls: [
          "play-large",
          "play",
          "progress",
          "current-time",
          "mute",
          "volume",
          "captions",
          // 'settings',
          "pip",
          "airplay",
          "fullscreen",
        ],
      },
      sources: {
        type: "video",
        sources: [
          {
            src: video,
            provider: "vimeo",
          },
        ],
      },
    };

    return (
      <PopupWr height={"100%"}>
        {/* <div className="mainImage1">
          <img src={mainImg[0]} alt="메인 이미지" />
        </div> */}

        {/* <div className="mainVideo1">
          <iframe
            id="iMovie"
            title="mainVideo1"
            name="movieFrame"
            width={"100%"}
            height={"100%"}
            src={video}
            frameBorder="0"
            allowFullScreen=""
            mozallowfullscreen=""
            webkitallowfullscreen=""
          />
        </div> */}

        <div>
          <Plyr
            volume={this.state.volume}
            options={this.state.options}
            source={this.state.sources}
            // src={video}
          />
        </div>

        <div className="overViewGenuin">
          <div className="overViewWrap">
            <div className="left-title">
              <div className="type">{type}</div>
              <div className="firstline1">
                <div className="name">{name[i18n.language]}</div>
                {/* <div className="company">{company[i18n.language]}</div> */}
                <div
                  className="company"
                  dangerouslySetInnerHTML={{ __html: company[i18n.language] }}
                />
              </div>
              {/* <div className="experience">{experience[i18n.language]}</div> */}
              {/* <div className="experience" dangerouslySetInnerHTML={{ __html: experience[i18n.language] }} /> */}
              {/* <div className="extext">{extext[i18n.language]}</div> */}
              {/* <div className="extext" dangerouslySetInnerHTML={{ __html: extext[i18n.language] }} /> */}
            </div>
            {/* <div className='subImgsBox'>
              <div className='subImgsContainer'>
                <div className="subImg">
                  <img src={subImg_01_01[0]} alt="서브 이미지" />
                </div>
                <div className="subdectitle" dangerouslySetInnerHTML={{ __html: subdectitle1[i18n.language] }} />
                <div className="subdec" dangerouslySetInnerHTML={{ __html: subdec1[i18n.language] }} />
              </div>
              <div className='subImgsContainer1'>
                <div className="subImg">
                  <img src={subImg_01_02[0]} alt="서브 이미지" />
                </div>
                <div className="subdectitle" dangerouslySetInnerHTML={{ __html: subdectitle2[i18n.language] }} />
                <div className="subdec" dangerouslySetInnerHTML={{ __html: subdec2[i18n.language] }} />
              </div>
            </div> */}
            {/* <div className='smallThumbs'>
              <div className="smallThumbImg">
                <img src={smallThumbImg_01[0]} alt="스몰 썸네일 이미지" />
              </div>
              <div className="smallThumbImg">
                <img src={smallThumbImg_02[0]} alt="스몰 썸네일 이미지" />
              </div>
              <div className="smallThumbImg">
                <img src={smallThumbImg_03[0]} alt="스몰 썸네일 이미지" />
              </div>
              <div className="smallThumbImg">
                <img src={smallThumbImg_04[0]} alt="스몰 썸네일 이미지" />
              </div>
              <div className="smallThumbImg">
                <img src={smallThumbImg_05[0]} alt="스몰 썸네일 이미지" />
              </div>
              <div className="smallThumbImg">
                <img src={smallThumbImg_06[0]} alt="스몰 썸네일 이미지" />
              </div>
            </div> */}
            {/* <div className ='behindTheSceneBox'>
              BEHIND THE SCENE
              <div className = 'behindTheSceneImgBox'>
                <div className="behindTheSceneImg">
                  <img src={behindTheSceneImg_01[0]} alt="비하인더 씬 이미지" />
                </div>
                <div className="behindTheSceneImg">
                  <img src={behindTheSceneImg_02[0]} alt="비하인더 씬 이미지" />
                </div>
              </div>
            </div> */}
            {/* <div className='creditSBox'>
              CREDITS
              <div className = 'creditWrapper'>
                <div className="jobTitle" dangerouslySetInnerHTML={{ __html: jobTitle[i18n.language] }} />
                <div className="creditNames" dangerouslySetInnerHTML={{ __html: creditNames[i18n.language] }} />
              
              </div>
            </div> */}
            <div className="footer1">
              <img
                className="footerlogo"
                src={logoimg1}
                alt="비하인더 씬 이미지"
              />
            </div>
          </div>

          {/* <div className="border"></div>
          <div></div> */}
        </div>

        {/* {this.renderProjectDetail(detail)} */}
        {/* <div className="contact_works" onClick={e => handleOnClickContactUs()}>
          Contact us
        </div> */}
      </PopupWr>
    );
  }
}

const getProps = (state) => {
  return {
    lang: state.langChange.value,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openPopup: (open, value) => dispatch(openPopup(open, value)),
  };
};

export default connect(getProps, mapDispatchToProps)(WorksPopup);
