import React, { Component } from "react";
import { connect } from "react-redux";
import { selectTab } from "../../../redux/actions";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
// import { fontFamily } from '@material-ui/system';

class MenuTab extends Component {
  state = {
    menus: ["All", "CM", "CR", "VFX"],
    isDesktop: true,
    clickMenus: "All",
  };

  renderMenu = (datas) => {
    let result = [];
    const { selectTab } = this.props;

    for (let i = 0; i < datas.length; i++) {
      const dt = datas[i];
      result.push(
        <li
          key={i}
          onClick={(e) => this.handleOnClick(dt)}
          className={selectTab === dt ? "select" : "no_select"}
        >
          {dt}
        </li>
      );
    }

    return result;
  };

  componentDidMount = () => {
    this.changeAspectMobile(window);
    window.addEventListener("resize", this.changeAspect);
  };

  changeAspect = (window) => {
    if (window.currentTarget.innerWidth >= 1024) {
      this.setState({ isDesktop: true });
    } else if (window.currentTarget.innerWidth < 1024) {
      this.setState({ isDesktop: false });
    }
  };

  changeAspectMobile = (window) => {
    if (window.innerWidth >= 1024) {
      this.setState({ isDesktop: true });
    } else if (window.innerWidth < 1024) {
      this.setState({ isDesktop: false });
    }
  };

  onChangeSelectTab = (e) => {
    const name = e.value;
    this.setState({ clickMenus: name }, () => {
      this.props.onClickTab(name);
      this.props.getDataOfType(name);
    });
  };

  handleOnClick = (name) => {
    this.props.onClickTab(name);
    this.props.getDataOfType(name);
  };

  updateDimensions = (window) => {
    if (window === undefined) {
      return <div />;
    }

    const { menus } = this.state;
    if (this.state.isDesktop) {
      return (
        <div className="menu_tab">
          <ul>{this.renderMenu(menus)}</ul>
        </div>
      );
    } else {
      return (
        <div className="menu_tab">
          <Dropdown
            className="selectBox"
            options={menus}
            onChange={this.onChangeSelectTab}
            value={this.state.clickMenus}
            placeholder="Select an option"
          />
        </div>
      );
    }
  };

  render() {
    return <React.Fragment>{this.updateDimensions(window)}</React.Fragment>;
  }
}

const props = (state) => {
  return {
    selectTab: state.selectTabs.value,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickTab: (value) => dispatch(selectTab(value)),
  };
};

export default connect(props, mapDispatchToProps)(MenuTab);
