import mainimg from './images/popup/mainimg.png'
import img_01_1 from './images/popup/01-1.png'
import img_01_2 from './images/popup/01-2.png'
import img_02_1 from './images/popup/02-1.png'
import img_02_2 from './images/popup/02-2.png'
import img_02_3 from './images/popup/02-3.png'

export default {
    "overview": {
        "ko": "정글에서 생존하기 위해 적들과 맞서 싸우는 서바이벌 형식의 VR 게임으로 곧 출시될 예정입니다. ",
        "en": "It is a survival type VR game, where you need to fight enemies for survival in the jungle. It is to be released soon.",
        "jp": "",
        "cn": ""
    },
    "video": "https://player.vimeo.com/video/303028871",
    "detail": [
        {
            "title": {
                "ko": "Background",
                "en": "Background",
                "jp": "",
                "cn": ""
            },
            "subtitle": {
                "ko": "숲 속 정글",
                "en": "Jungle",
                "jp": "",
                "cn": ""
            },
            "desc": {
                "ko": "실감나는 숲 속 정글의 연출을 위해서 Unreal 4 엔진을 활용하여 Full 3D로 구현하였습니다. ",
                "en": "Full 3D has been realized with the Unreal 4 engine, in order to create a realistic jungle environment.",
                "jp": "",
                "cn": ""
            },
            "contents": [
                {
                    "width": "50%",
                    "image": [
                        img_01_1,
                        img_01_2
                    ]
                }
            ]
        },
        {
            "title": {
                "ko": "Story",
                "en": "Story",
                "jp": "",
                "cn": ""
            },
            "subtitle": {
                "ko": "게임 전개 스토리",
                "en": "Game Story",
                "jp": "",
                "cn": ""
            },
            "desc": {
                "ko": "홀로 정글에 남겨진 주인공이 되어 살아남기 위해 끊임없이 나타나는 적들과 맞서 싸우는 스토리 입니다.",
                "en": "You are left alone in the jungle, and must fight against constantly approaching enemies in order to survive.",
                "jp": "",
                "cn": ""
            },
            "contents": [
                {
                    "width": "50%",
                    "image": [
                        img_02_1,
                        img_02_2
                    ]
                },
                {
                    "width": "100%",
                    "image": [
                        img_02_3
                    ]
                }
            ]
        }
    ],
    "mainImg": [
        mainimg
    ]
}