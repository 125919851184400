import mainimg from "../../../../_assets/cofounder/backgroundimg.png";

import cofounderimg01 from "../../../../_assets/cofounder/cofounder-01.jpg"
import cofounderimg02 from "../../../../_assets/cofounder/cofounder-02.jpg"
import cofounderimg03 from "../../../../_assets/cofounder/cofounder-03.jpg"
import cofounderimg04 from "../../../../_assets/cofounder/cofounder-04.jpg"

export default {
  mainImg: [mainimg],
  list: [
    {
      team: {
        ko: "Chaewon Seo<span>서채원</span>",
        en: "Chaewon Seo",
        jp: "",
        cn: ""
      },
      member: [
        
        {
          order: "1",
          name: {
            ko: "C.E.O CM STUDIO\n<br/>\n<br/>現 ㈜스튜디오레논 공동대표 / CM사업본부장\n<br/>\n<br/>前 ㈜로커스 CM사업본부장(상무)\n<br/>前 ㈜인디펜던스 Composite Flame 수석실장\n<br/>前 ㈜화이어웍스 Composite Flame 실장 \n<br/>\n<br/>- 국내외 광고 1000편 이상 Composite 실무 제작\n<br/>- 삼성, LG, 현대, SK기업PR, 네이버 CG Supervisor\n<br/>- 중국 KFC, 하웨이핸드폰 CG Supervisor\n<br/>- 영화 ‘변호인’ CG Supervisor",
            en: "C.E.O CM STUDIO\n<br/>\n<br/>Co-Representative of STDIO LENNON\n<br/>\n<br/>CM Div. Managing Director of LOCUS\n<br/>Composite Flame Chief of Staff of INDEPENDENCE\n<br/>Composite Flame Department Head of FIREWORK\n<br/>\n<br/>- Produced more than 1,000 domestic and international ads\n<br/>- SamSung, LG, Hyndai, SK-Corporation PR, Naver\n<br/>- CG Supervisor:\n<br/>　Samsung/LG/Hyundai/SK/Naver/KFC China/Huawei\n<br/>- CG Supervisor of feature film [The Attorney]",
            jp: "",
            cn: ""
          },
          // role: "Food Business",
          // email: "loocreative@naver.com"
        },
        
      ],
      peopleImg: [cofounderimg02],
    },
    {
      team: {
        ko: "Hosuk Jang<span>장호석</span>",
        en: "Hosuk Jang",
        jp: "",
        cn: ""
      },
      member: [
        
        {
          order: "1",
          name: {
            ko: "C.E.O VFX STUDIO\n<br/>\n<br/>現 ㈜스튜디오레논 공동대표 / VFX사업본부장\n<br/>\n<br/>前 ㈜로커스 VFX사업본부장(상무)\n<br/>前 ㈜인디펜던스 CG 실장\n<br/>前 ㈜영구아트무비 CG실장\n<br/>\n<br/>- 게임 씨네메틱 30여편 제작(CG Supervisor)\n<br/>- 국내 광고 400여편 제작\n<br/>- 영화 ‘디워‘ CG Supervisor",
            en: "C.E.O VFX STUDIO\n<br/>\n<br/>Co-Representative of STDIO LENNON\n<br/>\n<br/>VFX Division Managing Director of LOCUS\n<br/>CG Supervisor of INDEPENDENCE\n<br/>CG Supervisor of YOUNGGU ART\n<br/>\n<br/>- Supervised and produced more than 30 game cinematic\n<br/>- Supervised and produced over 400 domestic ads\n<br/>- CG Supervisor of feature film [The War]",
            jp: "",
            cn: ""
          },
          // role: "Food Business",
          // email: "loocreative@naver.com"
        },
        
      ],
      peopleImg: [cofounderimg03],
    },
    {
      team: {
        ko: "Jaewon Choi<span>최재원</span>",
        en: "Jaewon Choi",
        jp: "",
        cn: ""
      },
      member: [
        
        {
          order: "1",
          name: {
            ko: "본부장 CREATIVE STUDIO\n<br/>\n<br/>現 ㈜스튜디오레논 Creative사업본부장\n<br/>\n<br/>前 ㈜마켓쇼 대표이사\n<br/>前 ㈜로커스 마케팅본부장\n<br/>前 ㈜캠페인 대표기획실장\n<br/>前 ㈜밀리그램필름컴퍼니 기획실장\n<br/>\n<br/>- 국내 광고 1,500편 기획 / 제작\n<br/>- 대한민국광고대상 수상 [1997~우루사,KT,삼성증권 등]\n<br/>- 소비자가 뽑은 좋은 광고상 유한킴벌리 [2013]\n<br/>- The New York Festivals Finalist Award [KORNET 2000]",
            en: "Director / CREATIVE STUDIO\n<br/>\n<br/>Creative Studio Managing Director of STUDIO LENNON\n<br/>\n<br/>CEO of MARKETSHOW\n<br/>General Manager of CAMPAIGN\n<br/>Planning Manager of MILIGRAM FILM COMPANY\n<br/>\n<br/>- Planned / Produced over 1,500 domestic ads\n<br/>- Winner of several Korean Ad Awards:\n<br/>- Winner of Consumer’s Choice Award [Yuhan-Kimberly 2013]\n<br/>- The New York Festivals Finalist Award [KORNET 2000]",
            jp: "",
            cn: ""
          },
          // role: "Food Business",
          // email: "loocreative@naver.com"
        },
        
      ],
      peopleImg: [cofounderimg04],
    },
    {
      team: {
        ko: "Myunyoung Jung<span>정면영</span>",
        en: "Myunyoung Jung",
        jp: "",
        cn: ""
      },
      member: [
        
        {
          order: "1",
          
          name: {
            ko: "본부장 신사업본부\n<br/>\n<br/>現 ㈜스튜디오레논 신사업본부장\n<br/>\n<br/>前 ㈜로커스 부사장(CSO)\n<br/>前 ㈜레드로버 전무(콘텐츠사업 총괄)\n<br/>前 ㈜스튜디오닷 대표\n<br/>\n<br/>- 극장 애니메이션 ‘넛잡1,2’, ‘레드슈즈‘ EP\n<br/>- TV시리즈 애니 ‘버블버블마린‘ EP\n<br/>- 영화 ‘결혼원정기’, ‘가족의 탄생’, 제작/투자\n<br/>- 뮤지컬 ‘지킬앤하이드‘, ‘라만차‘ 등 제작/투자\n<br/>- 2015 무역의 날 [산업통상자원부 장관상]\n<br/>- 2014 대한민국ICT대상 [디지털콘텐츠부문 우수상]",
            en: "Director / New Business Director\n<br/>\n<br/>new business Managing Director of STUDIO LENNON\n<br/>\n<br/>Vice President of LOCUS\n<br/>Executive Director REDROVER\n<br/>CEO of STUDIO DOT\n<br/>\n<br/>- Feature animations [The Nut Job I, II]'\n<br/>- [Red Shoes] EP\n<br/>- TV Animation Series [Bubble Bubble Marine] EP\n<br/>- Feature films: Production / Investment \n<br/>　[Wedding Campaign] [Family Ties] \n<br/>- 2015 Minister Awards from Ministry of Trade, Industry and Energy\n<br/>- 2014 Korean ICT Awards Excellence Award in Digital Contents",
            jp: "",
            cn: ""
          },
          
          // role: "Food Business",
          // email: "loocreative@naver.com"
        },
        
      ],
      peopleImg: [cofounderimg01],
    },
  ]
}