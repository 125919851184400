import React, { Component } from "react";
import Section from "../../fullpage/section";
import "./styles/secondPage.scss";
//import NET from '../../common/function/net';
import i18n from "../../i18n/i18n";
import { connect } from "react-redux";

class secondPage extends Component {
  // constructor() {
  //   super();
  //   this.net = new NET();
  // }

  render() {
    const home = this.props.home;
    if (!home) {
      return <div />;
    } else {
      return (
        <Section image={home.mainImg} classN={"two"}>
          <div className="second_">
            <div className="brand_name_wr">
              <p className="par one">
                STUDIO LENNON
              </p>
              <div className="par two">
                Best Partner
              </div>
              <div className="par three">
                For Content Solution
              </div>
            </div>
            <div className="text_wr">
              <div className='top_text_box'>
                <div className='top_text'>
                  Imagine
                </div>
                <div className='top_border'>

                </div>
              </div>
              <div className='middle_text_box'>
                <div className='middle_border'>

                </div>
                <div className='middle_text'>
                  There is
                </div>
              </div>
              <div className='bottom_text_box'>
                <div className='bottom_text'>
                  No Border
                </div>
                <div className='bottom_border'>

                </div>
              </div>

            </div>
          </div>
        </Section>
      );
    }
  }
}

const getProps = state => {
  return {
    lang: state.langChange.value
  };
};

export default connect(getProps, undefined)(secondPage);
