import React, { Component } from 'react'
// import f_icon from '../../_assets/other_image/footer_icon/facebook_icon.png';
// import in_icon from '../../_assets/other_image/footer_icon/in_icon.png';
// import v_icon from '../../_assets/other_image/footer_icon/vimeo_icon.png';
// import y_icon from '../../_assets/other_image/footer_icon/youtube_icon.png';
import logo from '../../_assets/other_image/footer_icon/logo.png';
import './styles/footer.scss';
import { NavLink}from 'react-router-dom';
export default class Footer extends Component {
  render() {
    return (
        <div className="footer">
          {/* <p>LOOCCREATIVE PARTNER</p> */}
          {/* <p><img src={f_icon} alt="facebook logo" /></p>
          <p><img src={in_icon} alt="in logo" /></p>
          <p><img src={v_icon} alt="vimeo logo" /></p>
          <p><img src={y_icon} alt="youtube logo" /></p> */}
             <NavLink to= "/">
             <img src={logo} alt="lennon logo" />
             </NavLink>
              <a href="https://vimeo.com/user118611250" target = " _blank"><p>VIMEO</p></a>
              <a href="/" target = " _blank"><p>YOUTUBE</p></a>
              <a href="https://www.instagram.com/studio_lennon/" target = " _blank"><p>INSTAGRAM</p></a>
              <a href="https://www.facebook.com/pages/%EC%8A%A4%ED%8A%9C%EB%94%94%EC%98%A4-%EB%A0%88%EB%85%BC/106648314317841" target = " _blank"><p>FACEBOOK</p></a>
        </div>
    )
  }
}
