import React, { Component } from 'react'
import './styles/solutionInfo.scss';
import SolutionUsecase from './solutionUsecase';
import SolutionFunction from './solutionFunction';
import SolutionIntro from './solutionIntro';
import SolutionFirst from './solutionFirst';

export default class SolutionInfo extends Component {

  render() {
    return (
      <React.Fragment>
        <div className="scroll_wrapper" />
        <div className="scroll" />
        <SolutionFirst />
        <SolutionIntro />
        <SolutionUsecase />
        <SolutionFunction />
      </React.Fragment>
    )
  }
}