import React, { Component } from 'react'
import { connect } from 'react-redux'
import PageWr from '../../common/function/pageWr';
import CardWr from './function/cardWr';
import './styles/works.scss';
import MenuTab from './function/menuTab';
import Footer from '../../common/render/footer';
import { selectPage, openPopup } from '../../redux/actions';
import WorksPopup from './function/worksPopup';
// import NET from './../../common/function/net';
import Constants from '../../common/function/constants'
import SuccessPopup from './../../common/render/successPopup';
import ContactPopup from './../home/function/contactPopup';
import i18n from '../../i18n/i18n';
import { Helmet } from 'react-helmet';

class Works extends Component {

  _isMounted = false;

  state = {
    work: [],
    worksTitle : [],
    selectCardData: [],
    renderer: false,
    modalOpen: false,
    open: false,
    home: null,
    selectType: "",
    selectMenuType: "CM STUDIO",
  }

  // constructor() {
  //   super();
  //   this.net = new NET();
  // }

  componentDidMount() {
    this._isMounted = true;
    this.props.onPageMount("supermen");
    this.getWorksData();
    this.getHomeData();
  }


  componentWillUnmount() {
    this._isMounted = false;
    this.setState({
      renderer: false
    });
  }

  getWorksData = () => {
    // 전체 불러오기 type all
    // const data = Constants.Work

    // selectMenuType과 일치한 데이터만 가져오기 slect menutype에서 원하는 카테고리 바꿔줘야함
    const data = Constants.WorkType(this.state.selectMenuType)
    
    if (this._isMounted === true) {
      this.setState({ work: data });
    }
  }

  getSelectData = (title, name, all) => {
    const data = Constants.WorkId(title)
    const worstitle = Constants.WorkTitle(title)
    this.setState({ selectCardData: data,worksTitle : worstitle, selectType: name, selectMenuType: all.type }, () => {
      this.props.openPopup(true, title);
    });
    // 대충 서버에서 이름으로 특정 데이터만 가져오는 메서드
  }

  getDataOfType = (types, name, all) => {
    let type = types;

    switch (types) {
      // case "ALL":
      //   type = false;
      //   break
      // case "EXECUTIVES":
      //   type = "EXECUTIVES";
      //   break;
      case "CM STUDIO":
        type = "CM STUDIO";
        break;
      case "VFX STUDIO":
        type = "VFX STUDIO";
        break;
      case "CREATIVE STUDIO":
        type = "CREATIVE STUDIO";
        break;
      default:
        break;
    }

    let data = Constants.WorkType(type)
    if (!type) {
      data = Constants.Work()
    }
    
    this.setState({ work: data });
  }

  getHomeData = () => {
    const data = Constants.Home()
    this.setState({ home: data, render: true }, () => {
      this.setState({ home: data[4], renderer: true })
    });
  }

  setStateHandler = (name, value) => {
    this.setState({ [name]: value })
  }  
  
  // 메일 콘택트  
  postMailContact = (data) => {
    // this.net.post(`/api/mail/post`, {
    //   query : {
    //     category: data.category,   // 카테고리(옵션)
    //     company: data.company,  // 회사
    //     name: data.name,    // 이름
    //     phone: data.phone,   // 번호
    //     email: data.email,      // 이메일(옵션)
    //     desc: data.desc,      // 설명
    //     type: this.state.selectMenuType,
    //     title: this.state.selectType
    //   }
    // })
    // .then(res => {
    //     this.setState({ modalOpen: true, selectType: "", selectMenuType: "" }, () => {
    //   })
    // })
    // .catch(err => {
    // })
  }


  render() {

    const { work, selectCardData, modalOpen, open, home, selectType ,worksTitle} = this.state;
    const { getSelectData, getDataOfType, setStateHandler, postMailContact } = this;

    if (this.state.renderer) {
      return (
        <React.Fragment>
          <PageWr image={false} white={true}>
            <div className="works_">
            <Helmet>
                <meta charSet="utf-8" />
                <title>SUPERMEN | LENNON | Official Website</title>
                <meta name="description" content="One-Stop Creative Platform, Comemercial, Visual Effects, Post-Production, AI+CGI" data-react-helmet="true"/>
                <meta name="keywords"content="SUPERMEN, 슈퍼맨, 인원,스튜디오 레논, 레논 인원," data-react-helmet="true"/>
                <link rel="canonical" href="http://lennon.co.kr/supermen" data-react-helmet="true"/>

                <meta property="og:type" content="website" data-react-helmet="true"/> 
                <meta property="og:title" content="SUPERMEN | LENNON | Official Website" data-react-helmet="true"/>
                <meta property="og:description" content="스튜디오 레논 One-Stop Creative Platform, Comemercial, Visual Effects, Post-Production, AI+CGI" data-react-helmet="true"/>
                <meta property="og:image" content="https://drive.google.com/uc?export=download&id=1M08_nZzipZnl73h_RH3kVrZV1Zlxf9WA" data-react-helmet="true"/>
                <meta property="og:url" content="http://lennon.co.kr/supermen"></meta>

                <meta name="twitter:title" content="SUPERMEN | LENNON | Official Website" data-react-helmet="true"/>
                <meta name="twitter:description" content= "스튜디오 레논 One-Stop Creative Platform, Comemercial, Visual Effects, Post-Production, AI+CGI" data-react-helmet="true"/>
                <meta name="twitter:image" content="https://drive.google.com/uc?export=download&id=1M08_nZzipZnl73h_RH3kVrZV1Zlxf9WA" data-react-helmet="true"/>
            </Helmet>
            
              <div className="logo_title">
                {/* <div className="logo">
                  <NavLink to="/">
                    <img src={Icon} alt={"FAMOZ Logo"} />
                  </NavLink>
                </div> */}
                <div className="title">
                  <h1 className="bold">SUPERMEN</h1>
                  {/* <p>our work design</p> */}
                </div>
              </div>

              <div className="card_all_wrapper">
                <MenuTab getDataOfType={getDataOfType} />
                <CardWr getSelectData={getSelectData} data={work} />
              </div>

              <Footer />
              
            </div>
            <WorksPopup setStateHandler={setStateHandler} data={selectCardData} worksTitle = {worksTitle} />
            <SuccessPopup title={i18n.t("home.thanks1")/*감사합니다*/} open={modalOpen} subtitle={i18n.t("home.thanks2")/*성공적으로 작업을 마쳤습니다*/} description={i18n.t("home.thanks3"/*최대한 빠르게 답변 드리겠습니다.*/)} close={(e) => setStateHandler("modalOpen", false)}/>

          </PageWr>
          <ContactPopup contents={selectType} wrapper={"works_popup"} setStateHandlerProps={setStateHandler} data={home} open={open} postMailContact={postMailContact} />
        </React.Fragment>
      );
    }
    else {
      return (<div />);
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onPageMount: (value) => dispatch(selectPage(value)),
    openPopup: (open, value) => dispatch(openPopup(open, value))
  }
}

export default connect(undefined, mapDispatchToProps)(Works)
