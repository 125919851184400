import React, { Component } from 'react'
import { connect } from 'react-redux'
import './styles/popupWr2.scss';
import Icon from "@material-ui/core/Icon/Icon";
import { openPopup } from '../../redux/actions';
import Dialog from '@material-ui/core/Dialog';
import Fade from '@material-ui/core/Fade';

class PopupWr extends Component {

    constructor(props) {
        super(props);
        this._ref_Scroll = React.createRef();
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.popup_show !== prevProps.popup_show) {
            if(this._ref_Scroll.current !== null && this.props.popup_show) {
                this._ref_Scroll.current.scrollTop = 0;
            }
        }        
    }



    render() {
        const { openPopup, children, width, height, popup_show} = this.props;

        return (
            // <Slide direction="up" in={true}>
                <Dialog   
                    keepMounted 
                    open={popup_show !== false} 
                    scroll={"body"}>


                    <Fade in={popup_show !== false}
                    mountOnEnter 
                    // >
                    
                    // {/* <Slide direction="down" in={popup_show !== false} mountOnEnter  */}
                    timeout={{
                    appear: 500,
                    enter: 1200,
                    exit: 500,
                    }} unmountOnExit>
                        <div className="popup_wrapper_all_genuin" style={{width: width, height: height}}>
                            <div className="header">
                                <div className="X_btn" onClick={(e) => openPopup(false, null)}>
                                    <Icon>close</Icon>
                                </div>
                            </div>
                            <div ref={this._ref_Scroll} className="popup_contetns">
                                <div className="scroll_inner">
                                    {children}
                                </div>
                            </div>
                        </div>
                    </Fade> 
                </Dialog> 
        )
    }
}

const mapStateToProps = (state) => ({
    popup_show: state.openPopup.open,
    popup_data: state.openPopup.value
})

const mapDispatchToProps = (dispatch) => {
    return {
        openPopup: (open, value) => dispatch (openPopup(open, value))
    }
}

PopupWr.defaultProps = {
    width: "80%",
    height: "90%",
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupWr)