import React, { Component } from 'react'
import image from '../../_assets/back_image/back_2.jpg';
import './styles/solutionUsecase.scss';
import Icon from "@material-ui/core/Icon";

export default class SolutionUsecase extends Component {

  // renderCard = (datas) => {
  //   // 객체 예시 
  //   // { title: "STARFIELD SPORTS MONSTER", subTitle: "스타필드 스포츠몬스터", image: "미정" }
  //   let result = [];
  //   // 결과물(div)을 담을 배열

  //   for (let i = 0; i < datas.length; i ++) {
  //     const dt = datas[i];
  //     // eslint-disable-next-line no-unused-expressions
  //     result.push(
  //       <div className="fullbox" style={{ backgroundImage: `url(${dt.image})` }}>
  //         <div className="inner">
  //           <p className="inner_title"> { dt.title } </p>
  //           <p className="imginner_sub_title"> { dt.subTitle } </p>
  //         </div>
  //       </div>
  //     )
  //   }

  //   return result;
  // }

  render() {
    return (
      <div className="solutionUsepage_">
        <div className="bold_text_feature">
            <span className="span_wrap">
              USE CASE
            </span>
        </div>
        <div className="imgad">
          <p className="righttext" >
            *마우스를 올려보세요
          </p>
        <div className="fullbox">
            {/* 스타필드 스포츠몬스터 같은거 */}
              <div className="imgbox" style={{backgroundImage: `url(${image})`}}>
                <div className="inner_hover">
                  <div className="flex_inner">
                    <div className="text_left">
                      <div className="inner_title">
                        STARFIELD<br/>
                        SPROTS MONSTER
                      </div><br/>
                      <div className="inner_sub_title">
                          스타필드 스포츠몬스터
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* 자세히 알아보기 버튼 있는거 */}
              <div className="imgbox" style={{backgroundColor: "#CB4051"}}>
                <div className="inner">
                  <div className="position">
                  <p className="desctext">
                    게임 결과 데이터와<br/>
                    이용객의 데이터를 수집/분석하여<br/>
                    웹사이트를 통해서 조회하는 기능<br/>  
                    </p>
                  <p className="desctext">
                    게임에 사용되는<br/>
                    센서와 프로그램의 장애 상태를<br/>
                    실시간으로 모니터링
                  </p>
                  <div className="more_btn">
                    자세히 알아보기
                    <span className="add_icon">
                    <Icon>add</Icon>
                    </span>
                    </div>
                  </div>
                </div>
              </div>

            <div className="imgbox" style={{backgroundImage: `url(${image})`}}>
            </div>

            <div className="imgbox" style={{backgroundColor: `#3F7390`}}>
              <div className="inner_hover">
                <div className="inner_color">
                  <div className="flex_inner">
                    <div className="text_left">
                      <div className="inner_title">
                        POSCO<br/>
                        SMART DATA<br/>
                        CENTER
                      </div><br/>
                      <div className="inner_sub_title">
                        포스코 스마트데이터센터
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="imgbox" style={{backgroundImage: `url(${image})`}}>
              
            </div>
            <div className="imgbox" style={{backgroundColor: `#534847`}}>
              <div className="inner_hover">
                <div className="inner_color">
                  <div className="flex_inner">
                    <div className="text_left">
                      <div className="inner_title">
                        BUSAN NATIONAL<br/>
                        SCIENCE MUSEUM<br/>
                      </div><br/>
                      <div className="inner_sub_title">
                          부산 과학관
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="imgbox" style={{backgroundImage: `url(${image})`}}>
              
            </div>
            <div className="imgbox" style={{backgroundColor: `#548687`}}>  
              <div className="inner_hover">
                <div className="inner_color">
                  <div className="flex_inner">
                    <div className="text_left">
                      <div className="inner_title">
                        YONHAP NEWS<br/>
                      </div><br/>
                        <div className="inner_sub_title">
                            연합뉴스 홍보관
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <div className="imgbox" style={{backgroundImage: `url(${image})`}}>  
            
            </div>
            <div className="imgbox" style={{backgroundColor: `#8D8D5A`}}>
              <div className="inner_hover">
                <div className="inner_color">
                  <div className="flex_inner">
                    <div className="text_left">
                      <div className="inner_title">
                        THE<br/>
                        UNIFICATION CHURCH<br/>
                        PUBLIC RELATIONS<br/>
                        OFFICER<br/>
                      </div><br/>
                      <div className="inner_sub_title">
                          통일교 천보원
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="imgbox" style={{backgroundImage: `url(${image})`}}>  
            
            </div>
            <div className="imgbox" style={{backgroundColor: `#60598F`}}>  
              <div className="inner_hover">
                <div className="inner_color">
                  <div className="flex_inner">
                    <div className="text_left">
                      <div className="inner_title">
                        WAY<br/>
                        FINDING<br/>
                      </div><br/>
                    <div className="inner_sub_title">
                        웨이파인딩
                    </div>
                  </div>
                </div>
              </div>
            </div>  
          </div>
        </div>
      </div>
    </div>
    )
  }
}
