import React, { Component } from "react";
//import NET from '../../../common/function/net.js';
import i18n from "../../../i18n/i18n.js";
import { connect } from "react-redux";

class Slider extends Component {
  _isMounted = false;
  state = {};

  // constructor() {
  //     super();
  //     this.net = new NET();
  // }

  renderContents = () => {
    const { data } = this.props;
    let result = [];
    for (let i = 0; i < data.contents.length; i++) {
      const a = data.contents[i];
      result.push(
        <div key={i} className="Year">
          <h2 dangerouslySetInnerHTML={{ __html: a.title[i18n.language] }} />
          <p
            dangerouslySetInnerHTML={{ __html: a.description[i18n.language] }}
          />
        </div>
      );
    }
    return result;
  };

  renderYear = () => {
    const { data } = this.props;
    const year = data === undefined ? "" : data.year;

    let result = <p>{year}</p>;

    return result;
  };

  render() {
    return (
      <div
        className="SliderInner"
        id="SliderInner" /*style={{ transitionDelay: ((0.3 * keys) + 0.9 ) + "s" }}*/
      >
        <div className="years">{this.renderYear()}</div>
        <div className="slide_list_wr">{this.renderContents()}</div>
        
      </div>
    );
  }
}

const getProps = state => {
  return {
    lang: state.langChange.value
  };
};

export default connect(getProps, undefined)(Slider);
