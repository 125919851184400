import React, { Component, Fragment } from 'react'
import Section from '../../fullpage/section';
// section을 import 해와서 그 안에서만 작업 해주세요.
import './styles/firstPage.scss';
import Clock from '../../common/function/clock';
import { NavLink } from "react-router-dom";
//import NET from '../../common/function/net';
import { Icon } from '@material-ui/core';
import Expand from "react-expand-animated";
import i18n from '../../i18n/i18n';

function refreshPage() {
  window.location.reload(false);
}

export default class FirstPage extends Component {
  _isMounted = false;
  state = {
    time: "00:00:00"
  };

  state = { open: true };


  toggle = () => {
    this.setState(prevState => ({ open: !prevState.open }));
  };

  renderTime = (time) => {

    // let time = "18:30:00";
    let opens = false;

    const hour = Number(String(time).slice(0, 2));
    const min = Number(String(time).slice(3, 5));
    const sec = Number(String(time).slice(6, 8));
    if ((hour >= 9) && (hour <= 18)) {
      if (hour === 18) {
        if (min <= 29 && sec < 60) {
          opens = true;
        } else {
          opens = false;
        }
      } else {
        if (hour === 9 && min >= 30) {
          opens = true;
        } else {
          opens = true;
        }
      }
    } else {
      opens = false;
    }
    //루크레이티브에는 off가 없어서 기능을 제외하고 문구로 대체
    // if (opens) {
    if (true) {
      return //(
      //<React.Fragment>
      // {/* ON AIR <span>09:30 - 18:30</span><br/> */}
      //  <div>Time is our only limitation.</div>
      //</React.Fragment>
      // )
      // } else {
      //   return (
      //     <React.Fragment>
      //       OFF AIR<br/>
      //     </React.Fragment>
      //   )
    }

  }

  changeTime = (time) => {
    this.setState({ time: time })
  }
  setTimer = (time) => {
    let p = this.hoverContent;
    setTimeout(function () {
      p.classList.add("hoverConentsRevmover")
    }, time);
  }
  componentDidMount = () => {
    this.setTimer(12000)
  }
  clickedLennon = () => {
    let p = this.hoverContent;
    p.classList.remove("hoverConentsRevmover");
    this.setTimer(12000)
  }
  // renderSection = (d) => {
  //   if(!d) {
  //     return;
  //   }
  //   const datas = d.contents;

  //   let result = [];

  //   for (let i = 0; i < datas.length; i ++) {
  //     const dt = datas[i];
  //     result.push(
  //       <div key={1} className="Vision">
  //         <p className="show_vision" dangerouslySetInnerHTML={{__html: dt.vision[i18n.language]}}>
  //         </p>
  //       </div>
  //     )
  //   }
  // }
  
  
  
  render() {
    // const styles = {
    //   open: { opacity: [1, '!important'] }
      
    // };
    // const transitions = ["height", "opacity", ];
    // const {state, fullpageApi} = this.props;
    // 전달된 props는 기본적으로 state(fullpage의 현재 상황)와 fullpageApi(on 으로 시작하는 메서드 등) 입니다.
    const home = this.props.home;
    return (
      // 꼭 이 안에서 작업해주세요
      <Section className="imgAnimation" image={home.mainImg} classN={"one"}>
        <div className="first_">
          <p className="md_text">
            <span className="bold_text0"><h1>{(home === undefined) ? "" : home.title0}</h1></span>
            {/* <span className="bold_text">{ (home === undefined) ? "" : home.title1 }</span> */}
            {/* <span className="bold_text">{ (home === undefined) ? "" : home.title2 }</span><br/> */}
            {/* { (home === undefined) ? "" : home.title3 }<br/> */}

          </p>
          <div className="portfolio">
            {/* <NavLink to="/superman" style= {{ textDecoration: 'none', color: '#f26631'}}> */}
            <div className="contents">
              <p onClick={this.clickedLennon} className='bold_text'>LENNON</p>
              <NavLink to="/works" className ='MiddleText'><h1>WORKS</h1></NavLink> 
              {/* <Icon>arrow_right</Icon> */}

              {/* <p className="hoverContents"><Icon>arrow_forward_ios</Icon></p>
                  <p className="hoverContents"><Icon>keyboard_arrow_right</Icon></p> */}
              {/* <p className="hoverContents"><Icon>arrow_right_alt</Icon></p> */}
            </div>
            {/* <Expand styles={styles} transitions={transitions} open={this.state.open}> */}
            <p className="hoverContents" ref={ref => this.hoverContent = ref} dangerouslySetInnerHTML={{ __html: home.vision[i18n.language] }}/>
            {/* </Expand> */}
            {/* <Fragment>
              <Expand open={this.state.open}>
                <div style={{}}>
                  <p className="hoverContents">
                    <span>스튜디오 레논은 </span><br></br>
                    기업과 브랜드의 변화와 성장을 위한 모든 상상력을 구현하고자 설립되었습니다.<br></br>
                    최고의 CG/VFX/AI 기술을 가지고 아이디어의 시작부터 그 완성까지<br></br>
                    모든 크리에이티브 영역을 하나의 파이프라인으로 연결시킨<br></br>
                    국내유일의 ‘ONE-STOP CREATIVE PLATFORM’입니다.
                  </p>
                </div>
              </Expand>
            </Fragment> */}
            {/* </NavLink> */}
          </div>

          {/* <p className="time_text">
              {this.renderTime(this.state.time)}
              <span className="time_num">
                <Clock getTime={this.changeTime}/>
              </span>
            </p> */}
        </div>
        <div className="bt_text_box">
          <div className="bt_text_01">
            Imagine There is no
              </div>
          <div className="bt_text_02">
            Border
              </div>
        </div>
      </Section>
    )
  }
}

