export default [
    // 레논 영상들 여기서 썸네일 추가합니다.
    //--------------------------------------------2022-06-23 업데이트---------------------------------------------------
    {
        id: "vimeo252",
        video: "https://vimeo.com/778002489",
        name: {
            ko: "현대자동차_종합편",
            en: "hyundai_mobility_all.ver",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo251",
        video: "https://vimeo.com/778002798",
        name: {
            ko: "현대자동차_액션캠편",
            en: "hyundai_mobility_action cam.ver",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo250",
        video: "https://vimeo.com/776819576",
        name: {
            ko: "설토지빈 7주년 영상",
            en: "설토지빈 7주년 영상",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "VFX",
    },
    {
        id: "vimeo249",
        video: "https://vimeo.com/776819723",
        name: {
            ko: "설토지빈 장닝",
            en: "설토지빈 장닝",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "VFX",
    },
    {
        id: "vimeo248",
        video: "https://vimeo.com/776819894",
        name: {
            ko: "노스페이스: HEAT한 다운의 시작! ECO HEAT DOWN과 함께.",
            en: "노스페이스: HEAT한 다운의 시작! ECO HEAT DOWN과 함께.",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "VFX",
    },
    {
        id: "vimeo247",
        video: "https://vimeo.com/776820103",
        name: {
            ko: "블레이드 앤 소울 레볼루션_포술사 CG 풀버전",
            en: "블레이드 앤 소울 레볼루션_포술사 CG Full.ver",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "VFX",
    },
    {
        id: "vimeo246",
        video: "https://vimeo.com/770597547",
        name: {
            ko: "한화: 우주로 가는 길",
            en: "hanwha_PR",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo245",
        video: "https://vimeo.com/770597240",
        name: {
            ko: "코오롱: 추위를 안 타게, 안타티카 | 코오롱스포츠 22FW",
            en: "kolon_sports_ANTATICA",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo244",
        video: "https://vimeo.com/770596969",
        name: {
            ko: "블록챗: NO Server Only Blockchain",
            en: "Blockchat: NO Server Only Blockchain",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CR",
    },
    {
        id: "vimeo243",
        video: "https://vimeo.com/764394021",
        name: {
            ko: "캘러웨이 어패럴: THE BEAUTY, NEVER SEEN",
            en: "callaway_apparel: THE BEAUTY, NEVER SEEN",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo242",
        video: "https://vimeo.com/764394200",
        name: {
            ko: "네파: 22FW 롱다운",
            en: "NEPA: 22FW AIRGRAM THERMO long down",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo241",
        video: "https://vimeo.com/764394477",
        name: {
            ko: "아에르 필터 샤워기",
            en: "aer: filter shower",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo240",
        video: "https://vimeo.com/760906945",
        name: {
            ko: "The 2023 K9: Masters that inspire, 최진철감독",
            en: "KIA_K9",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo239",
        video: "https://vimeo.com/760906199",
        name: {
            ko: "롯데: 롯데가 새롭게 그려나가는 이로운 미래",
            en: "lotte_corporation",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo238",
        video: "https://vimeo.com/760903647",
        name: {
            ko: "동문건설",
            en: "dongmoon_construction",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo237",
        video: "https://vimeo.com/751567670",
        name: {
            ko: "캘러웨이 어패럴: THE BEAUTY, NEVER SEEN",
            en: "callaway_apparel: THE BEAUTY, NEVER SEEN",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo236",
        video: "https://vimeo.com/751567290",
        name: {
            ko: "네이버 장보기 튜토리얼",
            en: "naver_shopping_tutorial",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo235",
        video: "https://vimeo.com/745652344",
        name: {
            ko: "Youtube: 한국의 저력을 보다",
            en: "Youtube: 한국의 저력을 보다",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo234",
        video: "https://vimeo.com/745652065",
        name: {
            ko: "배달의민족_B마트",
            en: "배달의민족_B마트",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo233",
        video: "https://vimeo.com/745264817",
        name: {
            ko: "Blade and Soul Revolution: 싸울아비",
            en: "Blade and Soul Revolution: 싸울아비",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "VFX",
    },
    {
        id: "vimeo232",
        video: "https://vimeo.com/742110941",
        name: {
            ko: "IONIQ6: travel",
            en: "IONIQ6: travel",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo231",
        video: "https://vimeo.com/742110764",
        name: {
            ko: "IONIQ6: new ver",
            en: "IONIQ6: new ver",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo230",
        video: "https://vimeo.com/742110606",
        name: {
            ko: "IONIQ6: any ways",
            en: "IONIQ6: any ways",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo229",
        video: "https://vimeo.com/742110331",
        name: {
            ko: "IONIQ6: 4096 fits",
            en: "IONIQ6: 4096 fits",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo228",
        video: "https://vimeo.com/742109991",
        name: {
            ko: "IONIQ6: manifesto",
            en: "IONIQ6: manifesto",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo227",
        video: "https://vimeo.com/742109834",
        name: {
            ko: "E-GMP of IONIQ",
            en: "E-GMP of IONIQ",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo226",
        video: "https://vimeo.com/739906463",
        name: {
            ko: "naver pay",
            en: "naver pay",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo225",
        video: "https://vimeo.com/739906254",
        name: {
            ko: "samsung_Neo_QLED_gaming_tv",
            en: "samsung_Neo_QLED_gaming_tv",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo224",
        video: "https://vimeo.com/739906015",
        name: {
            ko: "Odyssey Ark: An epic odyssey begins 90s ver",
            en: "Odyssey Ark: An epic odyssey begins 90s ver",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo223",
        video: "https://vimeo.com/737462286",
        name: {
            ko: "Odyssey Ark: An epic odyssey begins 15s ver",
            en: "Odyssey Ark: An epic odyssey begins 15s ver",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo222",
        video: "https://vimeo.com/735282602",
        name: {
            ko: "Nexon X Hyundai Card",
            en: "Nexon X Hyundai Card",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CR",
    },
    {
        id: "vimeo221",
        video: "https://vimeo.com/735282331",
        name: {
            ko: "DENPS",
            en: "DENPS",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo220",
        video: "https://vimeo.com/735282156",
        name: {
            ko: "Samsung_Lifestyle Screens: What’s your choice? #MyChoiceMyLifestyle",
            en: "Samsung_Lifestyle Screens: What’s your choice? #MyChoiceMyLifestyle",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo219",
        video: "https://vimeo.com/730859846",
        name: {
            ko: "IONIQ 6: Window",
            en: "IONIQ 6: Window",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo218",
        video: "https://vimeo.com/730859792",
        name: {
            ko: "IONIQ 6: Pixel",
            en: "IONIQ 6: Pixel",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo217",
        video: "https://vimeo.com/730859705",
        name: {
            ko: "IONIQ 6: Fog",
            en: "IONIQ 6: Fog",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo216",
        video: "https://vimeo.com/722857267",
        name: {
            ko: "onmyoji: Judgment at the end of the century Orochi",
            en: "onmyoji: Judgment at the end of the century Orochi",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "VFX",
    },
    {
        id: "vimeo215",
        video: "https://vimeo.com/722856613",
        name: {
            ko: "Solto Jibin: Zhang Ning",
            en: "Solto Jibin: Zhang Ning",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "VFX",
    },
    {
        id: "vimeo214",
        video: "https://vimeo.com/722856233",
        name: {
            ko: "Solto Jibin: Intro",
            en: "Solto Jibin: Intro",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "VFX",
    },
    //------------------------------------------------------------------------------------------------------------------
    {
        id: "vimeo213",
        video: "https://vimeo.com/720520594",
        name: {
            ko: "Doosan Robotics: A day in the life with Rokey",
            en: "Doosan Robotics: A day in the life with Rokey",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo212",
        video: "https://vimeo.com/720520088",
        name: {
            ko: "Blade and Soul twin swordsman_full.ver",
            en: "Blade and Soul twin swordsman_full.ver",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "VFX",
    },
    {
        id: "vimeo211",
        video: "https://vimeo.com/720519553",
        name: {
            ko: "Blade and Soul twin swordsman_teaser",
            en: "Blade and Soul twin swordsman_teaser",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "VFX",
    },
    {
        id: "vimeo210",
        video: "https://vimeo.com/720519327",
        name: {
            ko: "LG game cinematics",
            en: "LG game cinematics",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "VFX",
    },
    {
        id: "vimeo209",
        video: "https://vimeo.com/716627436",
        name: {
            ko: "chevrolet_equinox",
            en: "chevrolet_equinox",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo208",
        video: "https://vimeo.com/716625993",
        name: {
            ko: "acmedelavie",
            en: "acmedelavie",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo207",
        video: "https://vimeo.com/716625879",
        name: {
            ko: "naver_shopping",
            en: "naver_shopping",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo206",
        video: "https://vimeo.com/712686282",
        name: {
            ko: "ministock",
            en: "ministock",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo205",
        video: "https://vimeo.com/712686547",
        name: {
            ko: "Meet amazing techverse in Samsung Display",
            en: "Meet amazing techverse in Samsung Display",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo204",
        video: "https://vimeo.com/712686167",
        name: {
            ko: "samsung_neo_qled_8k_2022",
            en: "samsung_neo_qled_8k_2022",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo203",
        video: "https://vimeo.com/705988064",
        name: {
            ko: "samsung_neo_qled_8k",
            en: "samsung_neo_qled_8k",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo202",
        video: "https://vimeo.com/705987039",
        name: {
            ko: "shurink_universe",
            en: "shurink_universe",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo201",
        video: "https://vimeo.com/701127142",
        name: {
            ko: "mintit",
            en: "mintit",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo200",
        video: "https://vimeo.com/701127373",
        name: {
            ko: "tams_zero",
            en: "tams_zero",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo199",
        video: "https://vimeo.com/701118330",
        name: {
            ko: "three_point_three",
            en: "three_point_three",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo198",
        video: "https://vimeo.com/696859114",
        name: {
            ko: "Let’s Do Neo QLED 8K: UnboxAndDiscove",
            en: "Let’s Do Neo QLED 8K: UnboxAndDiscove",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo197",
        video: "https://vimeo.com/696859053",
        name: {
            ko: "갤럭시 북2: Official Introduction Film",
            en: "Galaxy book2: Official Introduction Film",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo196",
        video: "https://vimeo.com/696856881",
        name: {
            ko: "제네시스_GV70_TVC",
            en: "genesis_GV70_TVC",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo195",
        video: "https://vimeo.com/696394480",
        name: {
            ko: "제네시스_GV70_vibrant_ride",
            en: "genesis_GV70_vibrant_ride",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo194",
        video: "https://vimeo.com/696394129",
        name: {
            ko: "제네시스_GV70_drive_in",
            en: "genesis_GV70_drive_in",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo193",
        video: "https://vimeo.com/696393729",
        name: {
            ko: "제네시스_GV70_party",
            en: "genesis_GV70_party",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo192",
        video: "https://vimeo.com/691688971",
        name: {
            ko: "LG_OLED_War: 파트 3",
            en: "LG_OLED_War: Part 3",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "VFX",
    },
    {
        id: "vimeo191",
        video: "https://vimeo.com/691689398",
        name: {
            ko: "LG_OLED_War: 파트 2",
            en: "LG_OLED_War: Part 2",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "VFX",
    },
    {
        id: "vimeo190",
        video: "https://vimeo.com/691689605",
        name: {
            ko: "LG_OLED_War: 파트 1",
            en: "LG_OLED_War: Part 1",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "VFX",
    },
    {
        id: "vimeo189",
        video: "https://vimeo.com/691686094",
        name: {
            ko: "콜어웨이_의류_2022_조거",
            en: "Callaway_Apparel_2022_Jogger",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo188",
        video: "https://vimeo.com/691685842",
        name: {
            ko: "콜어웨이_의류_2022_스커트",
            en: "Callaway_Apparel_2022_Skirt",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo187",
        video: "https://vimeo.com/691685609",
        name: {
            ko: "콜어웨이_의류_2022_트리플 트랙",
            en: "Callaway_Apparel_2022_Triple Track",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },

    {
        id: "vimeo186",
        video: "https://vimeo.com/675706024",
        name: {
            ko: "너구리 몰고 배민 골든티켓 찾자",
            en: "Baemin_Golden Ticket",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo185",
        video: "https://vimeo.com/675705792",
        name: {
            ko: "쉐보레 트래버스 LIVE SUPER, LIVE HIGH",
            en: "Chevrolet_Traverse_LIVE SUPER, LIVE HIGH",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo184",
        video: "https://vimeo.com/675705372",
        name: {
            ko: "캘러웨이 어패럴 2022_SPRING IS COMING",
            en: "Callaway_Apparel_2022_SPRING IS COMING",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo183",
        video: "https://vimeo.com/672986719",
        name: {
            ko: "삼쩜삼",
            en: "Three_Point_Three",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo182",
        video: "https://vimeo.com/672986324",
        name: {
            ko: "CES 2022: 스테이지 2022, 당신의 경험 시작",
            en: "CES 2022: THE STAGE 2022, Your Experience Begins",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo181",
        video: "https://vimeo.com/672986005",
        name: {
            ko: " 쉐보레 트래버스 런칭",
            en: "Chevrolet_Traverse_Launching",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo180",
        video: "https://vimeo.com/656477080",
        name: {
            ko: "얼라이언스 오메가 전쟁이 왔다!",
            en: "Alliance Omega War Is Here!",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "VFX",
    },
    {
        id: "vimeo179",
        video: "https://vimeo.com/656458668",
        name: {
            ko: "LG 틔운: 당신에게 틔운라이프",
            en: "LG_Tiiun",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo178",
        video: "https://vimeo.com/656458477",
        name: {
            ko: "트렌비",
            en: "Trenbe",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo177",
        video: "https://vimeo.com/650962165",
        name: {
            ko: "삼립호빵 코엑스 LED",
            en: "Samlip_Steamed_Coex_Led",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo176",
        video: "https://vimeo.com/650962319",
        name: {
            ko: "헤이딜러 제로",
            en: "heydealer_zero",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo175",
        video: "https://vimeo.com/646722105",
        name: {
            ko: "삼립호빵 매워편",
            en: "Samlip_Steamed_MEA-WAR",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo174",
        video: "https://vimeo.com/646721841",
        name: {
            ko: "딥 티저",
            en: "Deepp_Teaser",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo173",
        video: "https://vimeo.com/646721147",
        name: {
            ko: "피파온라인4",
            en: "Fifa_Online4",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo172",
        video: "https://vimeo.com/643376696",
        name: {
            ko: "써브웨이: 우리는 치즈였다. 녹아 없어지는 눈이 아니라",
            en: "subway_ultra_cheese",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo171",
        video: "https://vimeo.com/643376401",
        name: {
            ko: "LG 프라엘 인텐시브 멀티케어",
            en: "LG_PraL",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo170",
        video: "https://vimeo.com/643376247",
        name: {
            ko: "BMW: 멈출 수 없는 기쁨",
            en: "BMW: UNSTOPPABLE JOY. BMW x YGX",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo169",
        video: "https://vimeo.com/642569900",
        name: {
            ko: "삼성 갤럭시 북: 윈도우 11 파트너 One Team, on",
            en: "Samsung Galaxy Book Windows 11 Partners One Team, on",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo168",
        video: "https://vimeo.com/642569783",
        name: {
            ko: "나이키 OWN THE FLOOR",
            en: "NIKE OWN THE FLOOR",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo167",
        video: "https://vimeo.com/641002415",
        name: {
            ko: "G10 6주년 영상",
            en: "G10_6TH Anniversary",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "VFX",
    },
    {
        id: "vimeo166",
        video: "https://vimeo.com/641001712",
        name: {
            ko: "갤럭시 Z 플립3 비스포크 에디션: 전하지 못한 이야기",
            en: "Galaxy Z Flip3 Bespoke Edition",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo165",
        video: "https://vimeo.com/636923303",
        name: {
            ko: "LG 틔운: 당신에게 틔운라이프",
            en: "LG Tiiun",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo164",
        video: "https://vimeo.com/636922943",
        name: {
            ko: "삼립호빵 X 유재석 EATING SOON",
            en: "Samlip Steamed Teaser",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo163",
        video: "https://vimeo.com/636922716",
        name: {
            ko: "리얼베리어: 지켜라 피부장벽",
            en: "Real Barrier",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo162",
        video: "https://vimeo.com/612262170",
        name: {
            ko: "맥심 TOP",
            en: "Maxim T.O.P",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo161",
        video: "https://vimeo.com/612261218",
        name: {
            ko: "삼성 비스포크 김치플러스",
            en: "Samsung Bespoke Kimchi Plus",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo160",
        video: "https://vimeo.com/612260846",
        name: {
            ko: "까사미아 리빙룸편",
            en: "Casamia Living Room.ver",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo159",
        video: "https://vimeo.com/603663864",
        name: {
            ko: "블랙야크 시트다운플리스 아이유편",
            en: "Blackyak IU",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo158",
        video: "https://vimeo.com/603663087",
        name: {
            ko: "블랙야크 시트다운플리스 카이편",
            en: "Blackyak KAI",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo157",
        video: "https://vimeo.com/603662633",
        name: {
            ko: "머스트잇 발렌시아가편",
            en: "Must It Main Balenciaga",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo156",
        video: "https://vimeo.com/599299427",
        name: {
            ko: "내 차도 지구도, 지크로 지키다: 찐환경 엔진오일 SK ZIC ZERO의 찐환경 광고",
            en: "SK ZIC",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo155",
        video: "https://vimeo.com/599299856",
        name: {
            ko: "IKEA 새삶스럽게",
            en: "IKEA Newly",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo154",
        video: "https://vimeo.com/598568760",
        name: {
            ko: "마블 퓨처 레볼루션",
            en: "Marvel Future Revolution",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "VFX",
    },
    {
        id: "vimeo153",
        video: "https://vimeo.com/591381416",
        name: {
            ko: "에블린 마스크",
            en: "Evelyn Mask",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "VFX",
    },
    {
        id: "vimeo152",
        video: "https://vimeo.com/591381565",
        name: {
            ko: "맥스웰하우스 마스터",
            en: "Maxwell House",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo151",
        video: "https://vimeo.com/589708755",
        name: {
            ko: "삼성 생명",
            en: "Samsung Life_full",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "VFX",
    },
    {
        id: "vimeo150",
        video: "https://vimeo.com/589633061",
        name: {
            ko: "쉽잖아? 머스트잇! MUST. 반드시. IT. 있다.",
            en: "Must It Launching",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo148",
        video: "https://vimeo.com/589631895",
        name: {
            ko: "정관장 천녹",
            en: "Jeongkwanjang CheonNok",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo147",
        video: "https://vimeo.com/584753760",
        name: {
            ko: "캘러웨이 어패럴 기어편",
            en: "Callaway Gear",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo146",
        video: "https://vimeo.com/584753956",
        name: {
            ko: "캘러웨이 어패럴 패턴편",
            en: "Callaway Pattern",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo145",
        video: "https://vimeo.com/584754878",
        name: {
            ko: "이제우린소주 런칭편",
            en: "이제우린소주_launching",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo144",
        video: "https://vimeo.com/577944266",
        name: {
            ko: "블레이드 앤 소울 캐릭터 커스터마이징_남자편",
            en: "Blade and Soul character customization_man",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "VFX",
    },
    {
        id: "vimeo143",
        video: "https://vimeo.com/577944455",
        name: {
            ko: "블레이드 앤 소울 캐릭터 커스터마이징_여자편",
            en: "Blade and Soul character customization_woman",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "VFX",
    },
    {
        id: "vimeo142",
        video: "https://vimeo.com/577945465",
        name: {
            ko: "블레이드 앤 소울 티저 루핑",
            en: "Blade and Soul teaser looping",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "VFX",
    },
    // {
    //   id: "vimeo141",
    //   video: "https://vimeo.com/577943704",
    //   name: {
    //     ko: "삼성 반도체",
    //     en: "Samsung Semiconductor",
    //     jp: "",
    //     cn: "",
    //   },
    //   company: {
    //     ko: "",
    //     en: "",
    //     jp: "",
    //     cn: "",
    //   },
    //   type: "CM",
    //   // mainImg: [mainImg_141],
    // },
    {
        id: "vimeo140",
        video: "https://vimeo.com/577453469",
        name: {
            ko: "비비고 프리미엄 국물요리",
            en: "bibigo_soupdish_2021",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo139",
        video: "https://vimeo.com/577453810",
        name: {
            ko: "앤씰침대, 트랙터가 침대를 밟고 지나간다?",
            en: "ANSSil_bed",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo138",
        video: "https://vimeo.com/577453086",
        name: {
            ko: "혜택이 상상이상이상이 급여클럽 X 이상이",
            en: "Shinhan_bank",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo137",
        video: "https://vimeo.com/571075257",
        name: {
            ko: "피자 알볼로",
            en: "Pizza_alvolo",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo136",
        video: "https://vimeo.com/571073923",
        name: {
            ko: "마플샵 런칭",
            en: "Marppleshop_launching",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo135",
        video: "https://vimeo.com/571074451",
        name: {
            ko: "캘러웨이 어패럴 런칭",
            en: "Callaway_Apparel_Launching",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },

    {
        id: "vimeo134",
        video: "https://vimeo.com/569674497",
        name: {
            ko: "SK이노베이션: ESG가 간다! SK이노베이션 GO~!",
            en: "SK INNOVATION ESG",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo133",
        video: "https://vimeo.com/569673467",
        name: {
            ko: "DESKER: 또다른 가능성 앞으로, 데스커",
            en: "DESKER: Another possibility forward",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo132",
        video: "https://vimeo.com/566395039",
        name: {
            ko: "미르4 전장을 꿰뚫는 화살비, '석궁사'",
            en: "MIR 4 : Rain of arrows penetrating the battlefield, 'Crossbow'",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "VFX",
    },
    {
        id: "vimeo131",
        video: "https://vimeo.com/566008347",
        name: {
            ko: "캘러웨이 어패럴 티저",
            en: "Callaway_Apparel_Teaser",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo130",
        video: "https://vimeo.com/566008723",
        name: {
            ko: "Hyundai x Boston Dynamics : 모빌리티가 진화함에 따라 인류도 진화한다.",
            en: "Hyundai x Boston Dynamics: As mobility evolves so does humanity",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo129",
        video: "https://vimeo.com/562655623",
        name: {
            ko: "좋은 성분으로 누구나 누릴 수 있도록! 바이오 퍼블릭",
            en: "biopublic: So that everyone can enjoy it with good ingredients!",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo128",
        video: "https://vimeo.com/562655215",
        name: {
            ko: "읏맨VS은맨: 부정 끝판왕 ‘은(NO)맨’의 등장",
            en: "Eut Man VS Eun Man: The Appearance of the King of Unholy 'Silver (NO) Man'",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo127",
        video: "https://vimeo.com/562655348",
        name: {
            ko: "착한 가격으로 누구나 누릴 수 있도록! 바이오 퍼블릭",
            en: "biopublic: For everyone to enjoy at an affordable price!",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "vimeo126",
        video: "https://vimeo.com/558850490",
        name: {
            ko: "삼성 비스포크 글로벌_THAI.ver",
            en: "Samsung_Bespoke_Global_THAI.ver",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },

    {
        id: "vimeo121",
        video: "https://vimeo.com/558849436",
        name: {
            ko: "삼성 비스포크 글로벌",
            en: "Samsung_Bespoke_Global",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },
    {
        id: "vimeo124",
        video: "https://vimeo.com/558850192",
        name: {
            ko: "삼성 비스포크 글로벌_EU.ver",
            en: "Samsung_Bespoke_Global_EU",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },
    {
        id: "vimeo123",
        video: "https://vimeo.com/558818968",
        name: {
            ko: "KG그룹광고",
            en: "KG_Group_pr",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CR",
    },
    {
        id: "vimeo122",
        video: "https://vimeo.com/558818100",
        name: {
            ko: "토스증권",
            en: "Toss",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },

    {
        id: "vimeo121",
        video: "https://vimeo.com/555568637",
        name: {
            ko: "FiLite_Fresh",
            en: "FiLite_Fresh",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },
    {
        id: "vimeo120",
        video: "https://vimeo.com/555526125",
        name: {
            ko: "미르4: 연대기 제 6장 '전운' Teaser",
            en: "Mir 4: Chronicles Chapter 6 'Battle Fortune' Teaser",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },
    {
        id: "vimeo119",
        video: "https://vimeo.com/555525141",
        name: {
            ko: "세븐나이츠2 흑화에반",
            en: "Seven Knights 2 Black Evan",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },
    {
        id: "vimeo118",
        video: "https://vimeo.com/555523675",
        name: {
            ko: "Onmyoji_Full.Ver",
            en: "Onmyoji_Full.Ver",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },
    {
        id: "vimeo117",
        video: "https://vimeo.com/555522486",
        name: {
            ko: "Onmyoji_Teaser",
            en: "Onmyoji_Teaser",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },
    {
        id: "vimeo116",
        video: "https://vimeo.com/548779144",
        name: {
            ko: "쏭편_핑 퍼터로 이겨볼까요 퍼팅은 머니~ 퍼터는 핑!",
            en: "Songpyeon_Ping Let's win with a putter Putting is money~ Putting is a ping!",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CR",
    },
    {
        id: "vimeo115",
        video: "https://vimeo.com/548778700",
        name: {
            ko: "2021 핑퍼터 with 심짱",
            en: "2021 PING with 심짱",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CR",
    },
    {
        id: "vimeo114",
        video: "https://vimeo.com/548778243",
        name: {
            ko: "2021 핑퍼터 with 조아란",
            en: "2021 PING with 조아란",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CR",
    },
    {
        id: "vimeo113",
        video: "https://vimeo.com/548778059",
        name: {
            ko: "2021 핑퍼터",
            en: "2021 PING",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CR",
    },
    {
        id: "vimeo112",
        video: "https://vimeo.com/548730521",
        name: {
            ko: "달바 레드 미스트세럼",
            en: "Dalba",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },
    {
        id: "vimeo111",
        video: "https://vimeo.com/548726117",
        name: {
            ko: "일상비일상의 틈",
            en: "Teum",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },
    {
        id: "vimeo110",
        video: "https://vimeo.com/541978016",
        name: {
            ko: "호가든 Botanic",
            en: "Hoegaarden_Botanic",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },

    {
        id: "vimeo109",
        video: "https://vimeo.com/548726828",
        name: {
            ko: "신한 더모아 카드",
            en: "Shinhan_Themore_Card",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },

    {
        id: "vimeo108",
        video: "https://vimeo.com/541977516",
        name: {
            ko: "클라우드 생 드래프트",
            en: "Kloud_Raw_Draft",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },

    {
        id: "vimeo107",
        video: "https://vimeo.com/536235226",
        name: {
            ko: "제석천편_full.ver",
            en: "제석천편_full.ver",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },

    {
        id: "vimeo106",
        video: "https://vimeo.com/536235056",
        name: {
            ko: "제석천편_teaser",
            en: "제석천편_teaser",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },

    {
        id: "vimeo105",
        video: "https://vimeo.com/536234604",
        name: {
            ko: "음양사",
            en: "음양사",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },

    {
        id: "vimeo104",
        video: "https://vimeo.com/536181493",
        name: {
            ko: "차홍_볼륨컬러",
            en: "chahong",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },

    {
        id: "vimeo103",
        video: "https://vimeo.com/536180913",
        name: {
            ko: "비비고 2021 본편",
            en: "bibigo_2021_main",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },

    {
        id: "vimeo102",
        video: "https://vimeo.com/532996413",
        name: {
            ko: "삼성카드: American Express Metal Edition",
            en: "삼성카드: American Express Metal Edition",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },

    {
        id: "vimeo101",
        video: "https://vimeo.com/532197955",
        name: {
            ko: "다이나핏_트레이닝_15s",
            en: "다이나핏_트레이닝_15s",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },
    {
        id: "vimeo100",
        video: "https://vimeo.com/532197630",
        name: {
            ko: "다이나핏_트레이닝_30s",
            en: "다이나핏_트레이닝_30s",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },

    {
        id: "vimeo99",
        video: "https://vimeo.com/532197304",
        name: {
            ko: "청호정수기_필터편",
            en: "청호정수기_필터편",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },

    {
        id: "vimeo98",
        video: "https://vimeo.com/532196974",
        name: {
            ko: "청호정수기_주부대화편",
            en: "청호정수기_주부대화편",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },

    {
        id: "vimeo97",
        video: "https://vimeo.com/532196379",
        name: {
            ko: "청호정수기_신혼부부편",
            en: "청호정수기_신혼부부편",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },

    {
        id: "vimeo96",
        video: "https://vimeo.com/527140685",
        name: {
            ko: "아이소이 : 천우희? 유태오? 이제부터 나는 소이",
            en: "isoi_Blemish_Serum_Teaser",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },

    {
        id: "vimeo95",
        video: "https://vimeo.com/527139456",
        name: {
            ko: "바이옴 과학으로 진화된 NEW 그린티 씨드 세럼",
            en: "Innisfree_Green_Tea_Serum",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },

    {
        id: "vimeo94",
        video: "https://vimeo.com/523585921",
        name: {
            ko: "닥터린X송중기: 안전하고 흡수율 높은 초임계 rTG 오메가3",
            en: "Doctorlean_Omega3",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },

    {
        id: "vimeo93",
        video: "https://vimeo.com/523585095",
        name: {
            ko: "비타500: 제니와 함께 새로 시작하는 힘 ‘새로뻗침’",
            en: "vita500: 제니와 함께 새로 시작하는 힘 ‘새로뻗침’",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },

    {
        id: "vimeo92",
        video: "https://vimeo.com/519807895",
        name: {
            ko: "Unbox And Discover: lifestyle",
            en: "Unbox And Discover: lifestyle",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },

    {
        id: "vimeo91",
        video: "https://vimeo.com/519807251",
        name: {
            ko: "Unbox And Discover: premiere",
            en: "Unbox And Discover: premiere",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },

    {
        id: "vimeo90",
        video: "https://vimeo.com/519806942",
        name: {
            ko: "갤럭시 콘텐츠 페스타",
            en: "Galaxy Content Festa",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },

    {
        id: "vimeo89",
        video: "https://vimeo.com/518880354",
        name: {
            ko: "샤이니 'Don't Call Me' MV",
            en: "SHINee ‘Don’t Call Me’ MV",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },

    {
        id: "vimeo88",
        video: "https://vimeo.com/518880039",
        name: {
            ko: "코오롱글로벌 하늘채아파트 커뮤니티편",
            en: "Kolon Global 하늘채아파트 커뮤니티편",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CR",
    },

    {
        id: "vimeo87",
        video: "https://vimeo.com/518879635",
        name: {
            ko: "코오롱글로벌 하늘채아파트 유니트편",
            en: "코오롱글로벌 하늘채아파트 유니트편",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CR",
    },

    {
        id: "vimeo86",
        video: "https://vimeo.com/518879419",
        name: {
            ko: "굽네몰_듀먼 고수에게 묻다",
            en: "goobnemall_dhuman",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CR",
    },

    {
        id: "vimeo85",
        video: "https://vimeo.com/518879288",
        name: {
            ko: "PING_DRIVER - PING vs PING 편",
            en: "PING_DRIVER - PING vs PING 편",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CR",
    },

    {
        id: "vimeo84",
        video: "https://vimeo.com/518879113",
        name: {
            ko: "PING_IRONS - 결국아이언 편",
            en: "PING_IRONS - 결국아이언 편",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CR",
    },

    {
        id: "vimeo83",
        video: "https://vimeo.com/518878342",
        name: {
            ko: "PING_DRIVER - PING or nothing 편",
            en: "PING_DRIVER - PING or nothing 편",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CR",
    },

    {
        id: "vimeo82",
        video: "https://vimeo.com/518878025",
        name: {
            ko: "PING_Lineup - 박수 편",
            en: "PING_Lineup - 박수 편",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CR",
    },

    {
        id: "vimeo81",
        video: "https://vimeo.com/518877957",
        name: {
            ko: "PING_IRONS - 직선타구 편",
            en: "PING_IRONS - 직선타구 편",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CR",
    },

    {
        id: "vimeo80",
        video: "https://vimeo.com/518877809",
        name: {
            ko: "PING_Lineup - 챌린지 편",
            en: "PING_Lineup - 챌린지 편",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CR",
    },

    {
        id: "vimeo79",
        video: "https://vimeo.com/518877383",
        name: {
            ko: "MIZUNO_STz Driver",
            en: "MIZUNO_STz Driver",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CR",
    },

    {
        id: "vimeo78",
        video: "https://vimeo.com/518876347",
        name: {
            ko: "FIFA ONLINE 4_윈터페스타편",
            en: "FIFA ONLINE 4",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CR",
    },

    {
        id: "vimeo77",
        video: "https://vimeo.com/516011645",
        name: {
            ko: "SAMSUNG_CES_teasing_15s",
            en: "SAMSUNG_CES_teasing_15s",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },

    {
        id: "vimeo76",
        video: "https://vimeo.com/516011186",
        name: {
            ko: "Seven Knights Rudy 15s",
            en: "Seven Knights Rudy 15s",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },

    {
        id: "vimeo75",
        video: "https://vimeo.com/516010700",
        name: {
            ko: "세븐나이츠 루디 30s",
            en: "Seven Knights Rudy 30s",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },

    {
        id: "vimeo74",
        video: "https://vimeo.com/516010397",
        name: {
            ko: "미르4 천파를 찾아서",
            en: "Mir4 천파를 찾아서",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },

    {
        id: "vimeo73",
        video: "https://vimeo.com/516009748",
        name: {
            ko: "미르4 전설의 노래 편",
            en: "Mir 4 legendary song ver",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },

    {
        id: "vimeo72",
        video: "https://vimeo.com/516009192",
        name: {
            ko: "미르4 연대기 제 5장 '즉위식' Teaser",
            en: "Mir 4 crowning ceremony Teaser",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },

    {
        id: "vimeo71",
        video: "https://vimeo.com/516008809",
        name: {
            ko: "메이플스토리M THE KAISER Teaser",
            en: "MapleStory M THE KAISER Teaser",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },

    {
        id: "vimeo70",
        video: "https://vimeo.com/516007608",
        name: {
            ko: "LG시그니쳐 웹 전시관 영상",
            en: "LG Signature Web Exhibition Hall",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },

    // {
    //   id: "vimeo69",
    //   video: "https://vimeo.com/516006936",
    //   name: {
    //     ko: "TinyTAN_ANIMATION - Dream ON",
    //     en: "TinyTAN_ANIMATION - Dream ON",
    //     jp: "",
    //     cn: "",
    //   },
    //   company: {
    //     ko: "",
    //     en: "",
    //     jp: "",
    //     cn: "",
    //   },
    //   experience: {
    //     ko:
    //       "소리다.이것은 가치를 긴지라 가슴에 노래하며 원대하고, 찾아 운다. 이성은 같이 못할 듣는다. 우리 인생을 옷을 못할 있다. 풀이 이것은 유소년에게서 새 원질이 할지니, 이상 용기가 뿐이다. 풍부하게 목숨이 곧 이성은 동력은 것이다. 가는 낙원을 소금이라 가는 보는 있으랴? 소리다.이것은 가치를 긴지라 가슴에 노래하며 원대하고, 찾아 운다. 이성은 같이 못할 듣는다.",
    //     en: "",
    //   },
    //   extext: {
    //     ko:
    //       "<span>Work Experience</span>\n<br/> - 국내외 광고 1000편 이상 Composite 실무제작\n<br/> - 삼성, LG, 현대, SK기업PR, 네이버 CG Supervisor\n<br/> - 중국 KFC, 하웨이핸드폰 CG Supervisor\n<br/> - 영화 ‘변호인’ CG Supervisor",
    //     en: "",
    //   },
    //   subdectitle1: {
    //     ko: "얼음과 하는 밥을 이상의 황금시대다.",
    //     en: "",
    //   },
    //   subdec1: {
    //     ko:
    //       "소리다.이것은 가치를 긴지라 가슴에 노래하며 원대하고, 찾아 운다. 이성은 같이 못할 듣는다. 우리 인생을 옷을 못할 있다. 풀이 이것은 유소년에게서 새 원질이 할지니, 이상 용기가 뿐이다.",
    //     en: "",
    //   },
    //   subdectitle2: {
    //     ko: "얼음과 하는 밥을 이상의 황금시대다.",
    //     en: "",
    //   },
    //   subdec2: {
    //     ko:
    //       "소리다.이것은 가치를 긴지라 가슴에 노래하며 원대하고, 찾아 운다. 이성은 같이 못할 듣는다. 우리 인생을 옷을 못할 있다. 풀이 이것은 유소년에게서 새 원질이 할지니, 이상 용기가 뿐이다.",
    //     en: "",
    //   },
    //   jobTitle: {
    //     ko:
    //       "Project Client\n<br/> Art Director\n<br/> Senior Designer\n<br/> Senior Designer\n<br/> Junior Designer\n<br/> Junior Designer",
    //     en:
    //       "Project Client\n<br/> Art Director\n<br/> Senior Designer\n<br/> Senior Designer\n<br/> Junior Designer\n<br/> Junior Designer",
    //   },
    //   creditNames: {
    //     ko:
    //       "CGV Cinema\n<br/> Tackzun Lee\n<br/> Chan Kwon\n<br/> Suyoung Kim\n<br/> Hyeonu Shin\n<br/> Munhui Kim",
    //     en:
    //       "CGV Cinema\n<br/> Tackzun Lee\n<br/> Chan Kwon\n<br/> Suyoung Kim\n<br/> Hyeonu Shin\n<br/> Munhui Kim",
    //   },
    //   type: "VFX",
    //   mainImg: [mainImg_69],
    // },

    {
        id: "vimeo68",
        video: "https://vimeo.com/516006427",
        name: {
            ko: "blade and Soul 기권사",
            en: "blade and Soul 기권사",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },

    {
        id: "vimeo67",
        video: "https://vimeo.com/516005392",
        name: {
            ko: "A3 꼬마학자 CG 풀버전",
            en: "A3 Little scholar CG full ver",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },

    {
        id: "vimeo66",
        video: "https://vimeo.com/508246084",
        name: {
            ko: "아모레퍼시픽 프로텍트박스",
            en: "Amorepacific_Protectbox",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },

    {
        id: "vimeo65",
        video: "https://vimeo.com/508245489",
        name: {
            ko: "모하비 2021: Dreams are Young",
            en: "Mohave",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },

    {
        id: "vimeo64",
        video: "https://vimeo.com/508245105",
        name: {
            ko: "카스 제로",
            en: "Cass_Zero",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },

    {
        id: "vimeo63",
        video: "https://vimeo.com/508244656",
        name: {
            ko: "아이오페 레티놀",
            en: "Iope_Retinol",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },

    {
        id: "vimeo62",
        video: "https://vimeo.com/503312241",
        name: {
            ko: "갤럭시 버즈 프로",
            en: "Galaxy_Buds_Pro",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },

    {
        id: "vimeo61",
        video: "https://vimeo.com/496781109",
        name: {
            ko: "검은사막 업데이트",
            en: "black_Desert_Update",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },

    {
        id: "vimeo60",
        video: "https://vimeo.com/496780355",
        name: {
            ko: "프롬바이오 홍삼",
            en: "Frombio_Red_Ginseng",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },

    {
        id: "vimeo59",
        video: "https://vimeo.com/496779452",
        name: {
            ko: "프롬바이오 보스웰리아",
            en: "Frombio_Boswellia",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },

    {
        id: "vimeo58",
        video: "https://vimeo.com/496778273",
        name: {
            ko: "SAMSUNG CES Teasing",
            en: "SAMSUNG_CES_Teasing",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },

    {
        id: "vimeo57",
        video: "https://vimeo.com/493257800",
        name: {
            ko: "미즈노 JPX921 스모킹 아이언",
            en: "mizuno_JPX921_Smoking Iron",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CR",
    },

    {
        id: "vimeo56",
        video: "https://vimeo.com/493180734",
        name: {
            ko: "SBI저축은행 중금리편",
            en: "SBI_Saving Bank",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },

    {
        id: "vimeo55",
        video: "https://vimeo.com/493180058",
        name: {
            ko: "검은사막 사전생성",
            en: "Black Desert_Pre",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },

    {
        id: "vimeo54",
        video: "https://vimeo.com/488432312",
        name: {
            ko: "한화그룹: 지속가능한 친환경기술",
            en: "Hanwha",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },
    {
        id: "vimeo52",
        video: "https://vimeo.com/488431163",
        name: {
            ko: "웨이브 24시간 컨텐츠 스토어",
            en: "Wavve 24h_store",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },
    {
        id: "vimeo51",
        video: "https://vimeo.com/487929124",
        name: {
            ko: "피파온라인 NEW VIBE",
            en: "FIFA_mobile_NEW VIBE",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },
    {
        id: "vimeo51",
        video: "https://vimeo.com/485275945",
        name: {
            ko: "OK 저축은행",
            en: "OK Savings Bank_Villain",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },
    {
        id: "vimeo50",
        video: "https://vimeo.com/482910974",
        name: {
            ko: "GNM 자연의 품격",
            en: "GNM",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },
    {
        id: "vimeo49",
        video: "https://vimeo.com/482910410",
        name: {
            ko: "KDB 산업은행",
            en: "KDB_industrial_bank",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },
    {
        id: "vimeo48",
        video: "https://vimeo.com/480651673",
        name: {
            ko: "STAND ALONE, 20FW DYNAFIT",
            en: "STAND ALONE, 20FW DYNAFIT",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },
    {
        id: "vimeo47",
        video: "https://vimeo.com/475328417",
        name: {
            ko: "seven knights2 세인의 이야기",
            en: "seven knights2 - Story of Shane",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },
    {
        id: "vimeo46",
        video: "https://vimeo.com/474549518",
        name: {
            ko: "삼성 인덕션",
            en: "Samsung_induction",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },
    {
        id: "vimeo45",
        video: "https://vimeo.com/474548512",
        name: {
            ko: "SK매직 올클린 공기청정기",
            en: "SK_Magic_air_purifier",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },
    {
        id: "vimeo43",
        video: "https://vimeo.com/472460594",
        name: {
            ko: "세븐나이츠2 아일린의 이야기",
            en: "seven knights2 - Story of Eileene",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },
    {
        id: "vimeo42",
        video: "https://vimeo.com/472460088",
        name: {
            ko: "세븐나이츠2 루디의 이야기",
            en: "seven knights2 - Story of Rudy",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },
    {
        id: "vimeo41",
        video: "https://vimeo.com/472459482",
        name: {
            ko: "블레이드 앤 소울 레볼루션",
            en: "Blade & Soul Revolution",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },
    {
        id: "vimeo40",
        video: "https://vimeo.com/472459008",
        name: {
            ko: "A3 스틸얼라이브",
            en: "A3 STILL ALIVE",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },
    {
        id: "vimeo44",
        video: "https://vimeo.com/472495552",
        name: {
            ko: "A3 스틸얼라이브 티저",
            en: "A3 STILL ALIVE teaser",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },
    {
        id: "vimeo39",
        video: "https://vimeo.com/457581464",
        name: {
            ko: "미르4 K FANTASY",
            en: "Mir4 K FANTASY",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },
    {
        id: "vimeo38",
        video: "https://vimeo.com/455693810",
        name: {
            ko: "Lineage M 3주년 영상",
            en: "Lineage M",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },
    {
        id: "vimeo37",
        video: "https://vimeo.com/455687182",
        name: {
            ko: "삼성페이 할 땐 삼성페이카드 #1",
            en: "SamsungPay #1",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },
    {
        id: "vimeo36",
        video: "https://vimeo.com/455687607",
        name: {
            ko: "삼성페이 할 땐 삼성페이카드 #2",
            en: "SamsungPay #2",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },
    {
        id: "vimeo35",
        video: "https://vimeo.com/455686778",
        name: {
            ko: "갤럭시 Z Fold2 Official Introduction Film - Folding",
            en: "Galuxy Z Fold2 Official Introduction Film - Folding",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },
    {
        id: "vimeo34",
        video: "https://vimeo.com/455686492",
        name: {
            ko: "갤럭시 Z Fold2 Official Introduction Film - Display",
            en: "Galuxy Z Fold2 Official Introduction Film - Display",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },
    {
        id: "vimeo33",
        video: "https://vimeo.com/455685942",
        name: {
            ko: "갤럭시 Z Fold2 Official Introduction Film - Multitasking",
            en: "Galuxy Z Fold2 Official Introduction Film - Multitasking",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },
    {
        id: "vimeo32",
        video: "https://vimeo.com/455685721",
        name: {
            ko: "갤럭시 Z Fold2 Official Introduction Film - Flex mode",
            en: "Galuxy Z Fold2 Official Introduction Film - Flex mode",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },
    {
        id: "vimeo31",
        video: "https://vimeo.com/455685351",
        name: {
            ko: "갤럭시 Z Fold2 Official Film: Farewell, flat",
            en: "Galuxy Z Fold2 Official Film: Farewell, flat",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },
    {
        id: "vimeo30",
        video: "https://vimeo.com/453933631",
        name: {
            ko: "메이플스토리M",
            en: "Maplestory M",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "VFX",
    },
    {
        id: "vimeo29",
        video: "https://vimeo.com/453932199",
        name: {
            ko: "데시앙",
            en: "Desian",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },
    {
        id: "vimeo28",
        video: "https://vimeo.com/453148347",
        name: {
            ko: "SK 매직 스스로 정수기",
            en: "Sk Magic",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },
    {
        id: "vimeo27",
        video: "https://vimeo.com/453147780",
        name: {
            ko: "에뛰드 뮬리로맨스 컬렉션",
            en: "ETUDE",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },
    {
        id: "vimeo26",
        video: "https://vimeo.com/452060391",
        name: {
            ko: "비비고",
            en: "Bibigo",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },
    {
        id: "vimeo25",
        video: "https://vimeo.com/452060068",
        name: {
            ko: "LG 트롬 건조기",
            en: "LG Tromm Steam Dryer",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },
    {
        id: "vimeo24",
        video: "https://vimeo.com/452059815",
        name: {
            ko: "삼성페이 티저",
            en: "Samsung Pay Teaser",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },
    // 08.27 작업
    {
        id: "vimeo23",
        video: "https://vimeo.com/439850860",
        name: {
            ko: "KB 국민카드",
            en: "KB Card",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },
    {
        id: "vimeo22",
        video: "https://vimeo.com/438746302",
        name: {
            ko: "비비고",
            en: "Bibigo",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },

    {
        id: "vimeo21",
        video: "https://vimeo.com/438745288",
        name: {
            ko: "기아 셀토스",
            en: "KIA Seltos",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },

    {
        id: "vimeo20",
        video: "https://vimeo.com/438744734",
        name: {
            ko: "카닥 엔진오일",
            en: "Cardoc Engine oil",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },

    {
        id: "Beauty Inside Ending Title",
        video: "https://player.vimeo.com/video/433506524",
        name: {
            ko: "메이플스토리 어웨이크",
            en: "Maplestory AWAKE",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },
    {
        id: "Snowpiercer Title",
        video: "https://player.vimeo.com/video/433506416",
        name: {
            ko: "호가든 그린그레이프",
            en: "Hoegaarden green grape",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },
    {
        id: "Lg Optimus G",
        video: "https://player.vimeo.com/video/433504913",
        name: {
            ko: "오뚜기베이커리 피슈또핫",
            en: "Ottogi Bakery Pischto Hot",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CR",
    },
    {
        id: "Lost Kingdom",
        video: "https://player.vimeo.com/video/433504802",
        name: {
            ko: "웨이브",
            en: "WAVVE",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },
    {
        id: "POSCO Media Center",
        video: "https://player.vimeo.com/video/433504714",
        name: {
            ko: "한섬 EQL 런칭",
            en: "Handsome EQL launching",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CM",
    },
    {
        id: "Theme Interactive",
        video: "https://player.vimeo.com/video/433504054",
        name: {
            ko: "NH투자증권 문화다방 T",
            en: "NH Investment & Securities Culture Cafe T",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CR",
    },
    {
        id: "Sport Theme Game",
        video: "https://player.vimeo.com/video/433503995",
        name: {
            ko: "NH투자증권 문화다방 Photo",
            en: "NH Investment & Securities Culture Cafe Photo",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CR",
    },
    {
        id: "All New Cgv",
        video: "https://player.vimeo.com/video/433503933",
        name: {
            ko: "NH투자증권 문화다방 MEDIT",
            en: "NH Investment & Securities Culture Cafe MEDIT",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CR",
    },
    {
        id: "Survice in the Jungle",
        video: "https://player.vimeo.com/video/433503846",
        name: {
            ko: "NH투자증권 문화다방 Book",
            en: "NH Investment & Securities Culture Cafe Book",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },

        type: "CR",
    },
    {
        id: "Priest title",
        video: "https://player.vimeo.com/video/433503579",
        name: {
            ko: "유플러스 아이폰",
            en: "U-Plus I-phone",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        experience: {
            ko: "",
            en: "",
        },
        extext: {
            ko: "<span>Work Experience</span>\n<br/> - 국내외 광고 1000편 이상 Composite 실무제작\n<br/> - 삼성, LG, 현대, SK기업PR, 네이버 CG Supervisor\n<br/> - 중국 KFC, 하웨이핸드폰 CG Supervisor\n<br/> - 영화 ‘변호인’ CG Supervisor",
            en: "",
        },
        subdectitle1: {
            ko: "",
            en: "",
        },
        subdec1: {
            ko: "",
            en: "",
        },
        subdectitle2: {
            ko: "",
            en: "",
        },
        subdec2: {
            ko: "",
            en: "",
        },
        jobTitle: {
            ko: "Project Client\n<br/> Art Director\n<br/> Senior Designer\n<br/> Senior Designer\n<br/> Junior Designer\n<br/> Junior Designer",
            en: "Project Client\n<br/> Art Director\n<br/> Senior Designer\n<br/> Senior Designer\n<br/> Junior Designer\n<br/> Junior Designer",
        },
        creditNames: {
            ko: "CGV Cinema\n<br/> Tackzun Lee\n<br/> Chan Kwon\n<br/> Suyoung Kim\n<br/> Hyeonu Shin\n<br/> Munhui Kim",
            en: "CGV Cinema\n<br/> Tackzun Lee\n<br/> Chan Kwon\n<br/> Suyoung Kim\n<br/> Hyeonu Shin\n<br/> Munhui Kim",
        },

        type: "CM",
    },
    {
        id: "Onmyoji - New SP Shikigami Demoniac Shuten Doji",
        video: "https://player.vimeo.com/video/433503365",
        name: {
            ko: "아카페라 스페셜티",
            en: "Acafela Specialty",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "Www Title",
        video: "https://player.vimeo.com/video/433503280",
        name: {
            ko: "로스터리 R",
            en: "Roastery R",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "Sk Holdings Jjack",
        video: "https://player.vimeo.com/video/433503160",
        name: {
            ko: "칠성사이다 복숭아편",
            en: "Chilsungcider peach BTS",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "Sk Holdings Up",
        video: "https://player.vimeo.com/video/433502736",
        name: {
            ko: "윌라 오디오북",
            en: "welaaa audio book",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "Naver Series Prenup",
        video: "https://player.vimeo.com/video/433502600",
        name: {
            ko: "삼성 홈리모트",
            en: "Samsung home remote",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "Naver Series Goldenhour",
        video: "https://player.vimeo.com/video/433496541",
        name: {
            ko: "이마트 이워드",
            en: "Emart eward",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "My Country",
        video: "https://player.vimeo.com/video/433496474",
        name: {
            ko: "해피바스 스킨 유",
            en: "Happybath skin u",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "Manyo",
        video: "https://player.vimeo.com/video/433496280",
        name: {
            ko: "사이오스",
            en: "Syoss oleo",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
    {
        id: "Lotte Chemical",
        video: "https://player.vimeo.com/video/433494914",
        name: {
            ko: "크리스피바바",
            en: "Crispybaabaa",
            jp: "",
            cn: "",
        },
        company: {
            ko: "",
            en: "",
            jp: "",
            cn: "",
        },
        type: "CM",
    },
];
