import React, { Component } from 'react';
import Section from '../../fullpage/section';
import './styles/fourthPage.scss';
import SliderWr from './function/sliderWr';

export default class fourthPage extends Component {
  render() {
    const { fullpageApi } = this.props;

    return (
        <Section white={true} classN={"four"}>
            <div className="fourth_">
                <h1>History</h1>
                <SliderWr fullpageApi={fullpageApi} data={this.props.home} />
            </div>
        </Section>
    )
  }
}
