import mainImg_LeeMin from '../workId/childs/motion/img-01.png';
import mainImg_Onmyoji from '../workId/childs/motion/img-02.jpg';
import mainImg_samsungintronoframe from '../workId/childs/motion/img-03.jpg';
import mainImg_LiivMLaunching from '../workId/childs/motion/img-04.jpg';
import mainImg_비맥스메타 from '../workId/childs/motion/img-05.jpg';
import mainImg_FomulaE from '../workId/childs/motion/img-06.png';
import mainImg_allnewgalaxya from '../workId/childs/motion/img-07.png';
import mainImg_NewK5Teaser from '../workId/childs/motion/img-08.png';
import Samsung_Galaxy_Wallpaper_Film from '../workId/childs/motion/img-09.png';
import Www_Title from '../workId/childs/motion/img-10.png';
import Samsung_Block_Chain from '../workId/childs/motion/img-11.png';
import Sk_Holdings_Jjack from '../workId/childs/motion/img-12.png';
import Sk_Holdings_Up from '../workId/childs/motion/img-13.png';
import Naver_Series_Prenup from '../workId/childs/motion/img-14.jpg';
import Naver_Series_Goldenhour from '../workId/childs/motion/img-15.png';
import My_Country from '../workId/childs/motion/img-16.jpg';
import Manyoimg from '../workId/childs/motion/img-17.jpg';
import yoonjiyong from '../workId/childs/motion/img-18.jpg';
import kimtaeksoo from '../workId/childs/motion/img-19.png';
import minkwangsup from '../workId/childs/motion/img-20.png';
import kimhanwook from '../workId/childs/motion/img-21.png';
import kimyounghoon from '../workId/childs/motion/img-22.png';
import yoondongheon from '../workId/childs/motion/img-23.png';
import mainImg_CgvElevator from '../workId/childs/motion/img-24.jpg';
import mainImg_Chapi from '../workId/childs/motion/img-25.jpg';
import mainImg_Soofee from '../workId/childs/motion/img-26.jpg';
import seokwangsun from '../workId/childs/motion/img-27.jpg';
import kwonjunghyun from '../workId/childs/motion/img-28.jpg';
import kimdukyong from '../workId/childs/motion/img-29.jpg';
import leejonghyuk from '../workId/childs/motion/img-30.jpg';

export default [
  // 사람들 경력사항들 여기에다가 적어놨습니다. //
  {
    id: 'Onmyoji - New SP Shikigami Demoniac Shuten Doji',
    name: {
      ko: '양정림 실장',
      en: 'Yang Jungrim',
      jp: '',
      cn: '',
    },
    company: {
      ko: 'Composite',
      en: 'Composite',
      jp: '',
      cn: '',
    },
    experience: {
      ko: '現 ㈜스튜디오레논 COMPOSITE 팀_2020~현재\n<br/>前 ㈜로커스 COMPOSITE 팀_2009~2020\n<br/> 前 ㈜인디펜던스 FLAME 팀_2005~2009\n<br/> 前 미디아트, 서울비전 FLAME 팀_1995~2005',
      en: 'STUDIO LENNON COMPOSITE Team_2020~\n<br/>LOCUS COMPOSITE Team_2009~2020\n<br/> INDEPENDENCE FLAME Team_2005~2009\n<br/> MIDIART, SEOUL VISON FLAME Team_1995~2005',
    },
    extext: {
      ko: '<span>Work Experience</span>\n<br/> 현대자동차 글로벌,현대모비스,현대 브랜드필름,삼성 4K TV,삼성 QLED TV,삼성증권,삼성 건조기,삼성냉장고,kb금융,sk이노베이션,클라우드,삼성카드,삼성갤럭시버즈,모하비,LG OLED',
      en: '<span>Work Experience</span>\n<br/> Hyundai Motor Global, Hyundai Mobis, Hyundai Brand Film, Samsung 4K TV, Samsung QLED TV, Samsung Securities, Samsung Dryer, Samsung Refrigerator, kb Financial, sk Innovation, Cloud, Samsung Card, Samsung Galaxy Buzz, Mohave, LG OLED',
    },
    youtube: {
      ko: "<a href=https://youtu.be/ghWItwg2xec target='blank'>Link</a> 현대자동차: 캐스퍼 온라인 저니 필름\n<br/><a href=https://youtu.be/zD_Nz9nRgeE target='blank'>Link</a> 쉐보레 2022 TRAVERSE\n<br/> <a href=https://www.youtube.com/watch?v=Xh2wZyL-s8I target='blank'>Link</a> 삼성 비스포크 김치플러스\n<br/> <a href=https://www.youtube.com/watch?v=EfJG1DIZ1RY target='blank'>Link</a> 삼성 비스포크 인덕션\n<br/><a href=https://www.youtube.com/watch?v=qGY9LFxfjwk target='blank'>Link</a> 삼성건조기 그랑데\n<br/> <a href=https://www.youtube.com/watch?v=EvQIQlKkPio&feature=youtu.be target='blank'>Link</a> 신세계면세점\n<br/><a href=https://www.youtube.com/watch?v=jXPy5lpQZd0 target='blank'>Link</a> 롯데캐미칼\n</br>",
      en: "<a href=https://youtu.be/ghWItwg2xec target='blank'>Link</a> Hyundai Motor Company: Casper Online Jenny Film\n<br/><a href=https://youtu.be/zD_Nz9nRgeE target='blank'>Link</a> Chevrolet 2022 TRAVERSE\n<br/> <a href=https://www.youtube.com/watch?v=Xh2wZyL-s8I target='blank'>Link</a> Samsung Bespoke Kimchi Plus\n<br/> <a href=https://www.youtube.com/watch?v=EfJG1DIZ1RY target='blank'>Link</a> Samsung Bespoke Induction\n<br/><a href=https://www.youtube.com/watch?v=qGY9LFxfjwk target='blank'>Link</a> Samsung dryer grande\n<br/> <a href=https://www.youtube.com/watch?v=EvQIQlKkPio&feature=youtu.be target='blank'>Link</a> Shinsegae Duty Free\n<br/><a href=https://www.youtube.com/watch?v=jXPy5lpQZd0 target='blank'>Link</a> Lotte Chemical\n</br>",
    },
    type: 'CM STUDIO',
    mainImg: [mainImg_Onmyoji],
  },
  {
    id: 'Priest title2',
    name: {
      ko: '이민규 실장',
      en: 'Lee Mingyu',
      jp: '',
      cn: '',
    },
    company: {
      ko: 'Composite',
      en: 'Composite',
      jp: '',
      cn: '',
    },
    experience: {
      ko: '現 ㈜스튜디오레논 COMPOSITE 팀_2020~현재\n<br/>前 ㈜로커스 COMPOSITE 팀_2013~2020\n<br/>前 ㈜엘리엇 FLAME 팀_2006~2013\n<br/> 前 ㈜인디펜던스, 서울비전, 화이어웍스\n<br/> FLAME 팀_2000~2006',
      en: 'STUDIO LENNON COMPOSITE Team_2020~\n<br/>LOCUS COMPOSITE Team_2013~2020\n<br/>ELIOT FLAME Team_2006~2013\n<br/> INDEPENDENCE, SEOUL VISION, Fireworks\n<br/> FLAME Team_2000~2006',
    },
    extext: {
      ko: '<span>Work Experience</span>\n<br/> SK하이닉스,IBK,삼성카드,현대카드,NH투자증권,롯데면세점,하이트,CJ기업PR,현대기업PR,SK브로드밴드,SK매직,웨이브,토스,비비고,이마트 앱,씨젠,삼성 글로벌 use_case,OK저축은행,LG 퓨리케어 에어로타워,현대 기프트카,트렌비',
      en: '<span>Work Experience</span>\n<br/> SK Hynix, IBK, Samsung Card, Hyundai Card, NH Investment & Securities, Lotte Duty Free, Hite, CJ Enterprise PR, Hyundai Enterprise PR, SK Broadband, SK Magic, Wave, Toss, Vivigo, E-Mart App, Seegen, Samsung Global Use_case, OK Savings Bank, LG Puricare Aero Tower, Hyundai Gift Car, TRENB',
    },
    youtube: {
      ko: "<a href=https://youtu.be/fNvfEmGx7G8 target='blank'>Link</a> 배달의민족:너구리 몰고 배민 골든티켓 찾자\n<br/><a href=https://youtu.be/2oDpfHcVRBs?list=PLlao5HfFCdSbaGRvJalWod9KNuntYe4lM target='blank'>Link</a> SK매직: 올클린 공기청정기\n<br/><a href=https://youtu.be/6xWdGbJ8_ms target='blank'>Link</a> 한화그룹 디지털 광고: 친환경기술\n<br/><a href=https://www.youtube.com/watch?v=m9dq2gTPaTQ&feature=youtu.be target='blank'>Link</a> 하이트필라이트\n<br/><a href=https://youtu.be/V6tji-FUPpw target='blank'>Link</a> 갤럭시 Z플립3 비스포크 에디션\n<br/><a href=https://youtu.be/ChZwtdI5wzw target='blank'>Link</a> LG 틔운: 새 생활을 틔우다\n<br/><a href=https://youtu.be/s6zORyKjFqA target='blank'>Link</a> 미르4: 사전예약\n<br/>",
      en: "<a href=https://youtu.be/fNvfEmGx7G8 target='blank'>Link</a> Baemin: Let's drive Neoguri and find Baemin's golden ticket.\n<br/><a href=https://youtu.be/2oDpfHcVRBs?list=PLlao5HfFCdSbaGRvJalWod9KNuntYe4lM target='blank'>Link</a> SK Magic: All Clean Air Purifier\n<br/><a href=https://youtu.be/6xWdGbJ8_ms target='blank'>Link</a> Hanwha Group Digital Advertising: Eco-friendly Technology\n<br/><a href=https://www.youtube.com/watch?v=m9dq2gTPaTQ&feature=youtu.be target='blank'>Link</a> Hite filite\n<br/><a href=https://youtu.be/V6tji-FUPpw target='blank'>Link</a> Galaxy Z Flip 3 Bespoke Edition\n<br/><a href=https://youtu.be/ChZwtdI5wzw target='blank'>Link</a> LG Tewoon: Live a new life\n<br/><a href=https://youtu.be/s6zORyKjFqA target='blank'>Link</a> Mir4: Pre-reservation\n<br/>",
    },
    type: 'CM STUDIO',
    mainImg: [mainImg_LeeMin],
  },
  {
    id: 'seokwangsun',
    name: {
      ko: '서광선 실장',
      en: 'Seo KwangSun',
      jp: '',
      cn: '',
    },
    company: {
      ko: 'Composite',
      en: 'Composite',
      jp: '',
      cn: '',
    },
    experience: {
      ko: '現 ㈜스튜디오레논 COMPOSITE 팀_2020~현재\n<br/>前 ㈜비전홀딩스 FLAME 팀_2019~2020\n<br/> 前 fv㈜빅슨 스튜디오 FLAME 팀_2009~2019',
      en: 'STUDIO LENNON COMPOSITE Team_2020~\n<br/>Vision Holdings FLAME Team_2019-2020\n<br/> VICTON STUDIO FLAME Team_2009-2019',
    },
    extext: {
      ko: '<span>Work Experience</span>\n<br/> 코웨이 정수기,케토톱,포슐라,구전녹용,가연,달바 팩트 세럼',
      en: '<span>Work Experience</span>\n<br/> Coway Water Purifier, Ketotaw, Fosula, Oral Rust, Flame, Dalva Fact SerumCoway Water Purifier, Ketotaw, Fosula, Oral Rust, Flame, Dalva Fact Serum',
    },
    youtube: {
      ko: "<a href=https://www.youtube.com/watch?v=edX8kZcE4p8 target='blank'>Link</a> 삼성 UnboxAndDiscover 라이프스타일\n<br/><a href=https://www.youtube.com/watch?v=KPIqz7Pr9_I target='blank'>Link</a> 삼립호빵x유재석\n<br/> <a href=https://www.youtube.com/watch?v=Ky-1N6iRFxQ target='blank'>Link</a> 신한은행\n<br/> <a href=https://www.youtube.com/watch?v=3hBpRciOzuo target='blank'>Link</a> IKEA: 새로운 나를 발견하세요, 새삶스럽게\n<br/><a href=https://www.youtube.com/watch?v=GmKcSJq5vUk target='blank'>Link</a> 호가든 보타닉",
      en: "<a href=https://www.youtube.com/watch?v=edX8kZcE4p8 target='blank'>Link</a> Samsung UnboxAndDiscover Lifestyle\n<br/><a href=https://www.youtube.com/watch?v=KPIqz7Pr9_I target='blank'>Link</a> Samlip Hoppang x Yoo Jaeseok\n<br/> <a href=https://www.youtube.com/watch?v=Ky-1N6iRFxQ target='blank'>Link</a> Shinhan Bank\n<br/> <a href=https://www.youtube.com/watch?v=3hBpRciOzuo target='blank'>Link</a> IKEA: Discover a new me, new life\n<br/><a href=https://www.youtube.com/watch?v=GmKcSJq5vUk target='blank'>Link</a> Hogarden Botanic",
    },
    type: 'CM STUDIO',
    mainImg: [seokwangsun],
  },
  {
    id: 'Samsung intro no frame',
    name: {
      ko: '양승빈 팀장',
      en: 'Yang Seungbin',
      jp: '',
      cn: '',
    },
    company: {
      ko: 'Composite',
      en: 'Composite',
      jp: '',
      cn: '',
    },
    experience: {
      ko: '現 ㈜스튜디오레논 COMPOSITE 팀_2020~현재\n<br/>前 ㈜로커스 COMPOSITE 팀_2017~2020\n<br/> 前 ㈜ 빅슨 스튜디오 FLAME 팀_2012~2017',
      en: 'STUDIO LENNON COMPOSITE Team_2020~\n<br/>LOCUS COMPOSITE Team_2017~2020\n<br/> Vickson STUDIO FLAME Team_2012~2017',
    },
    extext: {
      ko: '<span>Work Experience</span>\n<br/>제네시스,쉐보레 트랙스,이케아,리니지레볼루션,키움증권,마플샵,신한 더모아카드, 던킨 쿨라타,락토바이옴,우르오스,페브리즈 항균 플러스편,SK 지크,호반건설,가나초콜릿',
      en: '<span>Work Experience</span>\n<br/> Genesis, Chevrolet Trax, IKEA, Lineage Revolution, Kiwoom Securities, Marple Shop, Shinhan The Moa Card,Dunkin Coolata, Lactobiome, Uros, Febreze Antibacterial Plus, SK Zik, Hoban Construction,Gana chocolate',
    },
    youtube: {
      ko: "<a href=https://www.youtube.com/watch?v=knd6_pKKHO4 target='blank'>Link</a> 캘러웨이 어패럴 런칭\n<br/><a href=https://www.youtube.com/watch?v=5_g7kAqJzaY target='blank'>Link</a> 캘러웨이 어패럴 SS22\n<br/><a href=https://www.youtube.com/watch?v=YNrVJp9SuD0 target='blank'>Link</a> 기아자동차 셀토스\n<br/><a href=https://www.youtube.com/watch?v=7cjhFRH0uec target='blank'>Link</a> 올앳미 콜라겐3270\n<br/><a href=https://www.youtube.com/watch?v=3P1CnWI62Ik target='blank'>Link</a> 한국관광공사 : 서울,전주,부산\n<br/><a href=https://www.youtube.com/watch?v=NtG68Rj0lhQ target='blank'>Link</a> 렉서스LC 태양편\n<br/> <a href=https://www.youtube.com/watch?v=36JvP5pw3po target='blank'>Link</a> 배달의민족: 우리는본래같은민족이었다\n<br/>",
      en: "<a href=https://www.youtube.com/watch?v=knd6_pKKHO4 target='blank'>Link</a> Callaway Apparel Launching\n<br/><a href=https://www.youtube.com/watch?v=5_g7kAqJzaY target='blank'>Link</a> Callaway Apparel SS22\n<br/><a href=https://www.youtube.com/watch?v=YNrVJp9SuD0 target='blank'>Link</a> Kia Motors CELTOS\n<br/><a href=https://www.youtube.com/watch?v=7cjhFRH0uec target='blank'>Link</a> All at me collagen3270\n<br/><a href=https://www.youtube.com/watch?v=3P1CnWI62Ik target='blank'>Link</a> Korea Tourism Organization: Seoul, Jeonju, Busan\n<br/><a href=https://www.youtube.com/watch?v=NtG68Rj0lhQ target='blank'>Link</a> Lexus LC Sun episode\n<br/> <a href=https://www.youtube.com/watch?v=36JvP5pw3po target='blank'>Link</a> Baedal Minjok: We were originally the same people\n<br/>",
    },
    type: 'CM STUDIO',
    mainImg: [mainImg_samsungintronoframe],
  },
  {
    id: 'LiivM Launching 30',
    name: {
      ko: '유선아 팀장',
      en: 'Yoo Sun ah',
      jp: '',
      cn: '',
    },
    company: {
      ko: 'Composite',
      en: 'Composite',
      jp: '',
      cn: '',
    },
    experience: {
      ko: '現 ㈜스튜디오레논 COMPOSITE 팀_2020~현재\n<br/>前 ㈜거스트엔게일 FLAME 팀_2017~2019\n<br/> 前 ㈜서울비전 FALME 팀_2013~2017',
      en: 'STUDIO LENNON COMPOSITE Team_2020~\n<br/>Gustngale FLAME Team_2017~2019\n<br/> SEOUL VISION FALME Team_2013~2017',
    },
    extext: {
      ko: '<span>Work Experience</span>\n<br/> 삼성 홈리모트,데스커,삼성 TV 언밸링,삼성 셰프컬렉션,에스오일,현대 엘레베이터,코웨이 정수기,이제우린 소주,GRN루테인,젝시믹스',
      en: '<span>Work Experience</span>\n<br/> Samsung Home Remote, Desker, Samsung TV Unbalanced, Samsung Chef Collection, S Oil, Hyundai Elevator, Coway Water Purifier, Now we have soju, GRN Lutein, Seximix',
    },
    youtube: {
      ko: "<a href=https://www.youtube.com/watch?v=S5k77vTzBjQ target='blank'>Link</a> 삼성 갤럭시 북: Windows 11 파트너 \n<br/> <a href=https://www.youtube.com/watch?v=RFdks-P73lc target='blank'>Link</a> BMW: UNSTOPPABLE JOY. BMW x YGX\n<br/> <a href=https://www.youtube.com/watch?v=vsQRRROrHS0 target='blank'>Link</a> LG 프라엘 인텐시브 멀티케어\n</br> <a href=https://www.youtube.com/watch?v=BrE778T1btk target='blank'>Link</a> 리얼베리어: 피부장벽을 바로지키다\n</br> <a href=https://www.youtube.com/watch?v=S6SbvYUriuw target='blank'>Link</a> 앤씰: 더 가볍고, 더 탄탄한 앤씰 매트리스\n</br> ",
      en: "<a href=https://www.youtube.com/watch?v=S5k77vTzBjQ target='blank'>Link</a> Samsung Galaxy Book: Windows 11 Partner \n<br/> <a href=https://www.youtube.com/watch?v=RFdks-P73lc target='blank'>Link</a> BMW: UNSTOPPABLE JOY. BMW x YGX\n<br/> <a href=https://www.youtube.com/watch?v=vsQRRROrHS0 target='blank'>Link</a> LG Pra.L Intensive Multicare\n</br> <a href=https://www.youtube.com/watch?v=BrE778T1btk target='blank'>Link</a> Real Barrier: Protects Skin Barrier\n</br> <a href=https://www.youtube.com/watch?v=S6SbvYUriuw target='blank'>Link</a> ANCEAL: A lighter, stronger ANCEAL mattress\n</br> ",
    },
    type: 'CM STUDIO',
    mainImg: [mainImg_LiivMLaunching],
  },
  {
    id: 'kwonjunghyun',
    name: {
      ko: '권정현 팀장',
      en: 'kwonjunghyun',
      jp: '',
      cn: '',
    },
    company: {
      ko: 'Composite',
      en: 'Composite',
      jp: '',
      cn: '',
    },
    experience: {
      ko: '現 ㈜스튜디오레논 COMPOSITE 팀_2020~현재\n<br/>前 RGB포스트 FLAME 팀_2018~2020\n<br/> 前 361VFX FLAME 팀_2015~2018\n<br/>前 머큐리포스트 FLAME 팀_2013~2015\n<br/>',
      en: 'Studio-LENON COMPOSITE team_2020~Currently\n<br/>Former RGB Post Flame Team_2018~2020\n<br/> Previously, 361VFX FLAME Team_2015-2018\n<br/>Former Mercury Post Flame Team_2013-2015\n<br/>',
    },
    extext: {
      ko: '<span>Work Experience</span>\n<br/> 코웨이 매트리스,대한민국 동행세일,위닉스 뽀송,웰컴디지털뱅크, KB 금융페스티벌,이마트,롯데마트',
      en: '<span>Work Experience</span>\n<br/> Coway Mattress, Korea Companion Sale, Winix Possong, Welcome Digital Bank, KB Financial Festival, E-Mart, Lotte Mart',
    },
    youtube: {
      ko: "<a href=https://www.youtube.com/watch?v=mlIXHEbBQ4M target='blank'>Link</a> 갤럭시 S21: 갤럭시 콘텐츠 페스타\n<br/><a href=https://youtu.be/LAy5D4eHOfU target='blank'>Link</a> 블랙야크 시트다운플리스\n<br/><a href=https://youtu.be/kodqkFZtj7A target='blank'>Link</a> 이니스프리 그린티 씨드 세럼\n<br/><a href=https://youtu.be/3N7vv_eVRcM target='blank'>Link</a> 나이키: OWN THE FLOOR\n<br/><a href=https://youtu.be/Mg5SNRNVVA8 target='blank'>Link</a> GRN\n<br/>",
      en: "<a href=https://www.youtube.com/watch?v=mlIXHEbBQ4M target='blank'>Link</a> Galaxy S21: Galaxy Content Festa\n<br/><a href=https://youtu.be/LAy5D4eHOfU target='blank'>Link</a> Black Yak Sheet Down Fleece\n<br/><a href=https://youtu.be/kodqkFZtj7A target='blank'>Link</a> Innisfree Green Tea Seed Serum\n<br/><a href=https://youtu.be/3N7vv_eVRcM target='blank'>Link</a> Nike: OWN THE FLOOR\n<br/><a href=https://youtu.be/Mg5SNRNVVA8 target='blank'>Link</a> GRN\n<br/>",
    },
    type: 'CM STUDIO',
    mainImg: [kwonjunghyun],
  },
  {
    id: 'kimdukyong',
    name: {
      ko: '김덕용 팀장',
      en: 'kimdukyong',
      jp: '',
      cn: '',
    },
    company: {
      ko: 'Composite',
      en: 'Composite',
      jp: '',
      cn: '',
    },
    experience: {
      ko: '現 ㈜스튜디오레논 COMPOSITE 팀_2022~현재\n<br/> 前 포스트아지트 FLAME 팀_2017~2022\n<br/> 前 키스톤플레이 FLAME 팀_2016~2017\n<br/> 前 로커스 FLAME 팀_2014~2016\n<br/> 前 서울비전 2D effect 팀_2013~2014\n<br/>',
      en: 'Current Studio Lennon COMPOSITE Team_2022~Present\n<br/> Former Post Agit FLAME Team_2017~2022\n<br/> Former Keystone Play FLAME Team_2016~2017\n<br/> Former Locus FLAME Team _2014~2016\n<br/> Former Seoul Vision 2D Effect Team_2013~2014\n<br/>',
    },
    extext: {
      ko: '<span>Work Experience</span>\n<br/> 아이오닉6,삼성디스플레이,블랙야크,정관장',
      en: '<span>Work Experience</span>\n<br/> Ioniq 6, Samsung Display, Black Yak, Jeong Kwan Jang',
    },
    youtube: {
      ko: "<a href=https://www.youtube.com/watch?v=mA8lvN7ZFb0 target='blank'>Link</a> 삼성디스플레이\n<br/><a href=https://www.youtube.com/watch?v=kk2N9T0Z2-c target='blank'>Link</a> 블랙야크\n<br/><a href=https://www.youtube.com/watch?v=rOnPjRa7Dtk target='blank'>Link</a> 푸르밀 프로바이오\n<br/><a href=https://www.youtube.com/watch?v=5J4xTgrUzO0 target='blank'>Link</a> 오뚜기 진라면\n<br/><a href=https://www.youtube.com/watch?v=Mjz98MzOiz8 target='blank'>Link</a> 정관장 정몰\n<br/>",
      en: "<a href=https://www.youtube.com/watch?v=mA8lvN7ZFb0 target='blank'>Link</a> Samsung Display\n<br/><a href=https://www.youtube.com/watch?v=kk2N9T0Z2-c target='blank'>Link</a> Black Yak\n<br/><a href=https://www.youtube.com/watch?v=rOnPjRa7Dtk target='blank'>Link</a> Purmil Probio\n<br/><a href=https://www.youtube.com/watch?v=5J4xTgrUzO0 target='blank'>Link</a> Ottogi Jin Ramen\n<br/><a href=https://www.youtube.com/watch?v=Mjz98MzOiz8 target='blank'>Link</a> Jeonggwanjang Jeongmall\n<br/>",
    },
    type: 'CM STUDIO',
    mainImg: [kimdukyong],
  },
  {
    id: 'leejonghyuk',
    name: {
      ko: '이종혁 팀장',
      en: 'leejonghyuk',
      jp: '',
      cn: '',
    },
    company: {
      ko: 'Composite',
      en: 'Composite',
      jp: '',
      cn: '',
    },
    experience: {
      ko: '現 ㈜스튜디오레논 COMPOSITE 팀_2022~현재\n<br/>前 포스트아지트 FLAME 팀_2019~2022\n<br/> 前 로커스 FLAME 팀_2017~2019\n<br/>前 빅슨 FLAME 팀_2014~2017\n<br/>前 화이어웍스 FLAME 팀_2013~2014\n<br/>',
      en: 'Current Studio Lennon COMPOSITE Team_2022~Present\n<br/>전 포스트아지트 FLAME팀_2019~2022\n<br/> Former Locus FLAME Team_2017~2019\n<br/>Former Vixon FLAME Team_2014~2017\n<br/>Former Fireworks FLAME Team_2013~2014\n<br/>',
    },
    extext: {
      ko: '<span>Work Experience</span>\n<br/> 르노XM3,메디힐,마켓컬리,로한M,던파모바일,코오롱몰\n<br/>',
      en: '<span>Work Experience</span>\n<br/> Renault XM3, Mediheal, Market Kurly, Rohan M, Dunfa Mobile, Kolon Mall\n<br/>',
    },
    youtube: {
      ko: "<a href=https://www.youtube.com/watch?v=o2RdMYQT6kc target='blank'>Link</a> 메디힐X에스파\n<br/><a href=https://www.youtube.com/watch?v=hAbFbEB54M8 target='blank'>Link</a> 로한M\n<br/><a href=https://www.youtube.com/watch?v=2cPD4iwpxL0 target='blank'>Link</a> 마켓컬리\n<br/><a href=https://www.youtube.com/watch?v=jJlELxSodxY target='blank'>Link</a> 코오롱몰\n<br/><a href=https://www.youtube.com/watch?v=sGzK2Tpi-lk target='blank'>Link</a> 던파모바일\n<br/>",
      en: "<a href=https://www.youtube.com/watch?v=o2RdMYQT6kc target='blank'>Link</a> Mediheal X Espa\n<br/><a href=https://www.youtube.com/watch?v=hAbFbEB54M8 target='blank'>Link</a> Rohan M\n<br/><a href=https://www.youtube.com/watch?v=2cPD4iwpxL0 target='blank'>Link</a> Market Kurly\n<br/><a href=https://www.youtube.com/watch?v=jJlELxSodxY target='blank'>Link</a> Kolon Mall\n<br/><a href=https://www.youtube.com/watch?v=sGzK2Tpi-lk target='blank'>Link</a> Dunpa Mobile\n<br/>",
    },
    type: 'CM STUDIO',
    mainImg: [leejonghyuk],
  },
  {
    id: '[비맥스 메타정] 정신피로 편',
    name: {
      ko: '조아더 팀장',
      en: 'Jo Adeo',
      jp: '',
      cn: '',
    },
    company: {
      ko: 'ART',
      en: 'ART',
      jp: '',
      cn: '',
    },
    experience: {
      ko: '現 ㈜스튜디오레논 ART 팀_2020~현재\n<br/>前 ㈜로커스 COMPOSITE 팀_2017~2020\n<br/> 前 ㈜로커스 GENERAL COMP 팀_2017~2020\n<br/> 前 ㈜빅슨 스튜디오 FLAME 팀_2015~2017',
      en: 'STUDIO LENNON ART 팀_2020~\n<br/>LOCUS COMPOSITE Team_2017~2020\n<br/> LOCUS GENERAL COMP Team_2017~2020\n<br/> Vickson STUDIO FLAME Team_2015~2017',
    },
    extext: {
      ko: '<span>Work Experience</span>\n<br/> 삼성 갤럭시 Z플립3 비스포크 에디션,삼성 갤럭시 스크린세이버,헤이딜러 ZERO,토스증권,맥스웰하우스 마스터,씨젠',
      en: '<span>Work Experience</span>\n<br/> Samsung Galaxy Z Flip 3 Bespoke Edition, Samsung Galaxy Screensaver, Hey Dealer ZERO, Toss Securities, Maxwell House Master, Seegene',
    },
    youtube: {
      ko: "<a href=https://youtu.be/V6tji-FUPpw target='blank'>Link</a> 갤럭시 Z플립3 비스포크 에디션\n<br/><a href=https://www.youtube.com/watch?v=b7PNsxZTJ78 target='blank'>Link</a> 헤이딜러 ZERO\n<br/> <a href=https://www.youtube.com/watch?v=NcQihA_GIbs target='blank'>Link</a> 토스증권\n<br/> <a href=https://www.youtube.com/watch?v=sXb2We7t2zw target='blank'>Link</a> 맥스웰하우스 마스터\n<br/> <a href=https://www.youtube.com/watch?v=274W-0Kdj1g target='blank'>Link</a> 씨젠 미래비전\n<br/>  ",
      en: "<a href=https://youtu.be/V6tji-FUPpw target='blank'>Link</a> Samsung Galaxy Z Flip 3 Bespoke\n<br/> <a href=https://www.youtube.com/watch?v=b7PNsxZTJ78 target='blank'>Link</a>Hey dealer ZERO\n<br/> <a href=https://www.youtube.com/watch?v=NcQihA_GIbs target='blank'>Link</a>Toss Securities\n<br/> <a href=https://www.youtube.com/watch?v=sXb2We7t2zw target='blank'>Link</a>Maxwell House Master\n<br/> <a href=https://www.youtube.com/watch?v=274W-0Kdj1g target='blank'>Link</a>Seegene Future Visiton\n<br/> ",
    },
    type: 'CM STUDIO',
    mainImg: [mainImg_비맥스메타],
  },
  {
    id: 'Fomula-E with방탄소년단',
    name: {
      ko: '박영하 실장',
      en: 'Park Yungha',
      jp: '',
      cn: '',
    },
    company: {
      ko: '3D',
      en: '3D',
      jp: '',
      cn: '',
    },
    experience: {
      ko: '現 ㈜스튜디오레논 3D 팀_2020~현재\n<br/>前 ㈜로커스 3D팀_2016~2020\n<br/> 前 ㈜넓은벌동쪽, 머큐리 3D팀_2012~2016\n<br/> 前 프리맨 3D팀_2004~2012\n<br/> 前 엑프엑스디멘젼, 제로윈 3D 팀_2000~2004 ',
      en: 'STUDIO LENNON 3D Team_2020~\n<br/>LOCUS 3D Team_2016~2020\n<br/> Wideeast, Mercury 3D Team_2012~2016\n<br/> Freeman 3D팀_2004~2012\n<br/> Fx-Demention, Zeroween 3D Team_2000~2004 ',
    },
    extext: {
      ko: '<span>Work Experience</span>\n<br/> 삼성 8K TV,SK매직,SK이노베이션,SK지크,LG트롬 건조기,LG퓨리케어,코웨이,캘러웨이 어패럴,토스증권,에스오일,KDB산업은행,DIOR VIP영상',
      en: '<span>Work Experience</span>\n<br/> Samsung 8K TV, SK Magic, SK Innovation, SK Zick, LG Tromm Dryer, LG Puricare, Coway, Callaway Apparel, Toss Securities, S Oil, KDB Development Bank, DIOR VIP Video',
    },
    youtube: {
      ko: "<a href=https://www.youtube.com/watch?v=EADS-ETITZk target='blank'>Link</a> 갤럭시 Z폴드2: Official Introduction Film\n<br/><a href=https://youtu.be/V6tji-FUPpw target='blank'>Link</a> 갤럭시 Z플립3 비스포크 에디션\n<br/><a href=https://www.youtube.com/watch?v=VYlobrMDRMs target='blank'>Link</a> 삼성 Neo QLED 8K\n<br/><a href=https://www.youtube.com/watch?v=D2-SKTk52EI&feature=youtu.be target='blank'>Link</a> 토스 MOON SHOT\n<br/> <a href=https://www.youtube.com/watch?v=sVz3FLdg_J4&feature=youtu.be target='blank'>Link</a> 시디즈X마블\n<br/> <a href=https://www.youtube.com/watch?v=cJozNonSAMU&feature=youtu.be target='blank'>Link</a> 참이슬 후레쉬\n<br/><a href=https://www.youtube.com/watch?v=45ycUyP0uvA target='blank'>Link</a> SK 매직 얼음정수기\n<br/>",
      en: "<a href=https://www.youtube.com/watch?v=EADS-ETITZk target='blank'>Link</a> Galaxy Z Fold 2: Official Introduction Film\n<br/><a href=https://youtu.be/V6tji-FUPpw target='blank'>Link</a> Galaxy Z Flip 3 Bespoke Edition\n<br/><a href=https://www.youtube.com/watch?v=VYlobrMDRMs target='blank'>Link</a> Samsung Neo QLED 8K\n<br/><a href=https://www.youtube.com/watch?v=D2-SKTk52EI&feature=youtu.be target='blank'>Link</a> Toss MOON SHOT\n<br/> <a href=https://www.youtube.com/watch?v=sVz3FLdg_J4&feature=youtu.be target='blank'>Link</a> Sidiz X Marble\n<br/> <a href=https://www.youtube.com/watch?v=cJozNonSAMU&feature=youtu.be target='blank'>Link</a> Chamisul Fresh\n<br/><a href=https://www.youtube.com/watch?v=45ycUyP0uvA target='blank'>Link</a> SK Magic Ice Water Purifier\n<br/>",
    },
    type: 'CM STUDIO',
    mainImg: [mainImg_FomulaE],
  },
  {
    id: 'Samsung Block Chain',
    name: {
      ko: '고성욱 실장',
      en: 'Go Sungwook',
      jp: '',
      cn: '',
    },
    company: {
      ko: 'Modeling Supv.',
      en: 'Modeling Supv.',
      jp: '',
      cn: '',
    },
    experience: {
      ko: '現 ㈜스튜디오레논_2020~현재\n<br/>前 ㈜로커스_ 2009~2020\n<br/> 前 ㈜인디펜던스 _2008~2009\n<br/> 前 영구아트무비 _2001~2008',
      en: 'STDIO LENNON_2020~\n<br/>LOCUS_ 2009~2020\n<br/> INDEPENDENCE_2008~2009\n<br/> Younggu-art Movie _2001~2008',
    },
    extext: {
      ko: '<span>Work Experience</span>',
      en: '<span>Work Experience</span>',
    },
    youtube: {
      ko: "<a href=https://www.youtube.com/watch?v=PDtKMrmqTrw target='blank'>Link</a> 신세계면세점\n<br/> <a href=https://www.youtube.com/watch?v=cYeZkxpzc48&list=TLPQMjMwNDIwMjDO4EPLbsmL-A&index=1 target='blank'>Link</a> 이니스프리\n<br/> <a href=https://www.youtube.com/watch?v=RKU3qUI2Nro target='blank'>Link</a> LG Curved 4K OLED TV\n<br/> <a href=https://www.youtube.com/watch?v=meQOpYBh89M target='blank'>Link</a> LG \n<br/><a href=https://www.youtube.com/watch?v=z-q4ozj5k24 target='blank'>Link</a> 삼성 QLED\n<br/> <a href=https://www.youtube.com/watch?v=MR6edlRaFXM target='blank'>Link</a> 닥터자르트\n<br/> <a href=https://www.youtube.com/watch?v=kJsFEAzhxj0 target='blank'>Link</a> 러시앤캐시\n<br/> <a href=https://www.youtube.com/watch?v=V0bYxOPWi3Y target='blank'>Link</a> 서울우유\n<br/> <a href=https://www.youtube.com/watch?v=iTv5pghBttU&feature=youtu.be target='blank'>Link</a> 스페셜포스",
      en: "<a href=https://www.youtube.com/watch?v=PDtKMrmqTrw target='blank'>Link</a> Shinsegae Duty Free Shop\n<br/> <a href=https://www.youtube.com/watch?v=cYeZkxpzc48&list=TLPQMjMwNDIwMjDO4EPLbsmL-A&index=1 target='blank'>Link</a> Innisfree\n<br/> <a href=https://www.youtube.com/watch?v=RKU3qUI2Nro target='blank'>Link</a> LG Curved 4K OLED TV\n<br/> <a href=https://www.youtube.com/watch?v=meQOpYBh89M target='blank'>Link</a> LG \n<br/><a href=https://www.youtube.com/watch?v=z-q4ozj5k24 target='blank'>Link</a> SamSung QLED\n<br/> <a href=https://www.youtube.com/watch?v=MR6edlRaFXM target='blank'>Link</a> Dr.Jart\n<br/> <a href=https://www.youtube.com/watch?v=kJsFEAzhxj0 target='blank'>Link</a> Rush N Cash\n<br/> <a href=https://www.youtube.com/watch?v=V0bYxOPWi3Y target='blank'>Link</a> SEOUL MILK\n<br/> <a href=https://www.youtube.com/watch?v=iTv5pghBttU&feature=youtu.be target='blank'>Link</a> Special Force",
    },
    type: 'VFX STUDIO',
    mainImg: [Samsung_Block_Chain],
  },
  {
    id: 'Sk Holdings Jjack',
    name: {
      ko: '이재성 실장',
      en: 'Lee Jaesung',
      jp: '',
      cn: '',
    },
    company: {
      ko: 'Lighting Supv.',
      en: 'Lighting Supv.',
      jp: '',
      cn: '',
    },
    experience: {
      ko: '現 ㈜스튜디오레논_2020~현재\n<br/>前 ㈜로커스 EDIT 팀_2009~2020\n<br/> 前 ㈜인디펜던스 _2008~2009\n<br/> 前 영구아트무비_2001~2008',
      en: 'STUDIO LENNON_2020~\n<br/>LOCUS EDIT Team_2009~2020\n<br/> INDEPENDENCE_2008~2009\n<br/> Younggu-art Movie_2001~2008',
    },
    extext: {
      ko: '<span>Work Experience</span>\n<br/> 벤츠 CLS, 엠넷브랜드, 삼성화재, 인도 삼성전자, LG퓨리케어, 현대기업PR, 바이오더마, 카카오페이, 네이버 크리에이터, LG V40, 듀오락 유산균, BTS 글로벌 인터렉티브 영상, GC녹십자 프로비던스',
      en: '<span>Work Experience</span>\n<br/> Benz CLS, Mnet, Mnet Brand, Samsung Fire & Marine Insurance, India Samsung Electronics, LG Furykair , Hyundai Corporation PR, Bioderma, Kakao-Pay, Naver Creator, LG V40, Duolac lactobacillus, BTS Global Interactive Video, Green Cross Providence',
    },
    youtube: {
      ko: "<a href=https://www.youtube.com/watch?v=Cp0KuqxkAOQ target='blank'>Link</a> OK저축은행 \n<br/> <a href=https://www.youtube.com/watch?v=j85LXaqAp-c target='blank'>Link</a> 한국수력원자력\n<br/> <a href=https://www.youtube.com/watch?v=JhAEuRfNjsY target='blank'>Link</a> 리니지2M tvc\n<br/> <a href=https://www.youtube.com/watch?v=DVx5A5YDAxg target='blank'>Link</a> 중국 KFC\n<br/> <a href=https://www.youtube.com/watch?v=gzymsgXG7zg target='blank'>Link</a> 삼성노트북\n<br/> <a href=https://www.youtube.com/watch?v=Dm9rAr4Lo34 target='blank'>Link</a> 넥센타이어\n<br/> <a href=https://www.youtube.com/watch?v=NSM2_lqsYAQ target='blank'>Link</a> 블랙야크\n<br/> <a href=https://www.youtube.com/watch?v=2oYMyIiJmCM target='blank'>Link</a> 여신동맹_TVC\n<br/> <a href=https://www.youtube.com/watch?v=khcCac1uC3c target='blank'>Link</a> LG_gflex2\n<br/> <a href=https://www.youtube.com/watch?v=BTy54I6mzQs target='blank'>Link</a> Nantoo",
      en: "<a href=https://www.youtube.com/watch?v=Cp0KuqxkAOQ target='blank'>Link</a> OK savings bank\n<br/> <a href=https://www.youtube.com/watch?v=j85LXaqAp-c target='blank'>Link</a> Korea Hydro & Nuclear Power\n<br/> <a href=https://www.youtube.com/watch?v=JhAEuRfNjsY target='blank'>Link</a> Lineage 2M tvc\n<br/> <a href=https://www.youtube.com/watch?v=DVx5A5YDAxg target='blank'>Link</a> China KFC\n<br/> <a href=https://www.youtube.com/watch?v=gzymsgXG7zg target='blank'>Link</a> SamSung NoteBook\n<br/> <a href=https://www.youtube.com/watch?v=Dm9rAr4Lo34 target='blank'>Link</a> NEXEN Tire\n<br/> <a href=https://www.youtube.com/watch?v=NSM2_lqsYAQ target='blank'>Link</a> BLACKYAK\n<br/> <a href=https://www.youtube.com/watch?v=2oYMyIiJmCM target='blank'>Link</a> Credit Union_TVC\n<br/> <a href=https://www.youtube.com/watch?v=khcCac1uC3c target='blank'>Link</a> LG_gflex2\n<br/> <a href=https://www.youtube.com/watch?v=BTy54I6mzQs target='blank'>Link</a> Nantoo",
    },
    type: 'VFX STUDIO',
    mainImg: [Sk_Holdings_Jjack],
  },
  {
    id: 'Naver Series Prenup',
    name: {
      ko: '김진호 팀장',
      en: '	Kim Jinho',
      jp: '',
      cn: '',
    },
    company: {
      ko: 'Animation Supv.',
      en: 'Animation Supv.',
      jp: '',
      cn: '',
    },
    experience: {
      ko: '現 ㈜스튜디오레논_2020~현재\n<br/>前 ㈜로커스_2009~2020\n<br/> 前 ㈜메타파이브 _2009\n<br/> 前 영구아트무비 _2001~2008',
      en: 'STUDIO LENNON_2020~\n<br/>LOCUS_2009~2020\n<br/> META-FIVE_2009\n<br/> Younggu-art Movie_2001~2008',
    },
    extext: {
      ko: '<span>Work Experience</span>',
      en: '<span>Work Experience</span>',
    },
    youtube: {
      ko: "<a href=https://youtu.be/BmwCdQaFuX0 target='blank'>Link</a> Talion Trailer \n<br/> <a href=https://youtu.be/11Tl8tc_Vu4 target='blank'>Link</a> 2019 S-oil광고 - '좋은기름으로 으라차차!'\n<br/> <a href=https://www.youtube.com/watch?v=kuQxoTLHWNk&feature=youtu.be target='blank'>Link</a> 꼬북칩\n<br/> <a href=https://www.youtube.com/watch?v=YGijnGcOxnM&feature=youtu.be target='blank'>Link</a> S-oil 가족\n<br/> <a href=https://www.youtube.com/watch?v=QHUk9yJYOLc&feature=youtu.be target='blank'>Link</a> S-oil 아빠와아들\n<br/> <a href=https://www.youtube.com/watch?v=ZD5GbKYDUPM&feature=youtu.be target='blank'>Link</a> 바로바로론 스트롱맨\n<br/> <a href=https://www.youtube.com/watch?v=bUtHoZmBrDE&feature=youtu.be target='blank'>Link</a> 바로바로론 마카레나\n<br/> <a href=https://www.youtube.com/watch?v=3_lUlmZCwv4 target='blank'>Link</a> Devsisters_Cookierun\n<br/>  ",
      en: "<a href=https://youtu.be/BmwCdQaFuX0 target='blank'>Link</a> Talion Trailer \n<br/> <a href=https://youtu.be/11Tl8tc_Vu4 target='blank'>Link</a> 2019 S-oil - Eurachachacha with good oil\n<br/> <a href=https://www.youtube.com/watch?v=kuQxoTLHWNk&feature=youtu.be target='blank'>Link</a> Kkobukchip\n<br/> <a href=https://www.youtube.com/watch?v=YGijnGcOxnM&feature=youtu.be target='blank'>Link</a> S-oil Familly\n<br/> <a href=https://www.youtube.com/watch?v=QHUk9yJYOLc&feature=youtu.be target='blank'>Link</a> S-oil Dad and Son\n<br/> <a href=https://www.youtube.com/watch?v=ZD5GbKYDUPM&feature=youtu.be target='blank'>Link</a> BaroBarron Strongman\n<br/> <a href=https://www.youtube.com/watch?v=bUtHoZmBrDE&feature=youtu.be target='blank'>Link</a> Baro Barron Macarena\n<br/> <a href=https://www.youtube.com/watch?v=3_lUlmZCwv4 target='blank'>Link</a> Devsisters_Cookierun\n<br/> ",
    },
    type: 'VFX STUDIO',
    mainImg: [Naver_Series_Prenup],
  },
  {
    id: 'Naver Series Goldenhour',
    name: {
      ko: '구본민 팀장',
      en: 'Gu Bonmin',
      jp: '',
      cn: '',
    },
    company: {
      ko: 'FX Supv.',
      en: 'FX Supv.',
      jp: '',
      cn: '',
    },
    experience: {
      ko: '現 ㈜스튜디오레논_2020~현재\n<br/>前 ㈜로커스_2009~2020\n<br/> 前 ㈜메타파이브 _2009\n<br/> 前 영구아트무비 _2005~2008',
      en: 'STUDIO LENNON_2020~\n<br/>LOCUS_2009~2020\n<br/> META-FIVE_2009\n<br/> Younggu-art Movie_2005~2008',
    },
    extext: {
      ko: '<span>Work Experience</span>',
      en: '<span>Work Experience</span>',
    },
    youtube: {
      ko: "<a href=https://youtu.be/FkCZa58lZsQ target='blank'>Link</a> SK ZIC \n<br/> <a href=https://youtu.be/7zkmhbzyrkc target='blank'>Link</a> 한국타이어 가속편\n<br/> <a href=https://www.youtube.com/watch?v=c99BRyayJsc target='blank'>Link</a> Webzen_Mu_Origin\n<br/> <a href=https://www.youtube.com/watch?v=y14IxVnBEaU target='blank'>Link</a> Netease_G37_TGS\n<br/> <a href=https://www.youtube.com/watch?v=lmN_ROi4Rjw&feature=youtu.be target='blank'>Link</a> 배달의 민족 b마트\n<br/> <a href=https://www.youtube.com/watch?v=m8yGgRY2hAw&feature=youtu.be target='blank'>Link</a> 베로카",
      en: "<a href=https://youtu.be/FkCZa58lZsQ target='blank'>Link</a> SK ZIC \n<br/> <a href=https://youtu.be/7zkmhbzyrkc target='blank'>Link</a> Hankook Tire Accelerators\n<br/> <a href=https://www.youtube.com/watch?v=c99BRyayJsc target='blank'>Link</a> Webzen_Mu_Origin\n<br/> <a href=https://www.youtube.com/watch?v=y14IxVnBEaU target='blank'>Link</a> Netease_G37_TGS\n<br/> <a href=https://www.youtube.com/watch?v=lmN_ROi4Rjw&feature=youtu.be target='blank'>Link</a> Baedal Ethnic B-Mart\n<br/> <a href=https://www.youtube.com/watch?v=m8yGgRY2hAw&feature=youtu.be target='blank'>Link</a> Veroka",
    },
    type: 'VFX STUDIO',
    mainImg: [Naver_Series_Goldenhour],
  },
  {
    id: 'My Country',
    name: {
      ko: '김태희 팀장',
      en: 'Kim Taehee',
      jp: '',
      cn: '',
    },
    company: {
      ko: 'CFX Supv.',
      en: 'CFX Supv.',
      jp: '',
      cn: '',
    },
    experience: {
      ko: '現 ㈜스튜디오레논_2020~현재\n<br/>前 ㈜로커스_2009~2020',
      en: 'STUDIO LENNON_2020~\n<br/>LOCUS_2009~2020',
    },
    extext: {
      ko: '<span>Work Experience</span>',
      en: '<span>Work Experience</span>',
    },
    youtube: {
      ko: "<a href=https://www.youtube.com/watch?v=UfBxtsE2dY4 target='blank'>Link</a> 아스텔리아 \n<br/> <a href=https://www.youtube.com/watch?v=cFPtKPKaUsg target='blank'>Link</a> 패스티발\n<br/> <a href= target='blank'>Link</a> 필라이트 편의점\n<br/> <a href=https://www.youtube.com/watch?v=y14IxVnBEaU target='blank'>Link</a> Netease_G37_TGS\n<br/> <a href=https://www.youtube.com/watch?v=Hi9pHRtLQLs&feature=youtu.be target='blank'>Link</a> 맥콜 전설을 맛보리\n<br/> <a href=https://www.youtube.com/watch?v=VIRkGdusYDI&feature=youtu.be target='blank'>Link</a> IBK 기은센 캐릭터 탄생편\n<br/> <a href=https://www.bilibili.com/video/av20758248?from=search&seid=11959931222292637994 target='blank'>Link</a> Tencent_QQ 현무수유",
      en: "<a href=https://www.youtube.com/watch?v=UfBxtsE2dY4 target='blank'>Link</a> Astellia \n<br/> <a href=https://www.youtube.com/watch?v=cFPtKPKaUsg target='blank'>Link</a> Festival\n<br/> <a href= target='blank'>Link</a> Filite Convenience Store\n<br/> <a href=https://www.youtube.com/watch?v=y14IxVnBEaU target='blank'>Link</a> Netease_G37_TGS\n<br/> <a href=https://www.youtube.com/watch?v=Hi9pHRtLQLs&feature=youtu.be target='blank'>Link</a> A Taste of the Legend of Macall Legend\n<br/> <a href=https://www.youtube.com/watch?v=VIRkGdusYDI&feature=youtu.be target='blank'>Link</a> IBK Ki Eun-sen character birth film\n<br/> <a href=https://www.bilibili.com/video/av20758248?from=search&seid=11959931222292637994 target='blank'>Link</a> Tencent_QQ baseless breastfeeding",
    },
    type: 'VFX STUDIO',
    mainImg: [My_Country],
  },
  {
    id: 'Manyo',
    name: {
      ko: '함종태 팀장',
      en: 'Ham Jongtae',
      jp: '',
      cn: '',
    },
    company: {
      ko: 'Pipeline Supv.',
      en: 'Pipeline Supv.',
      jp: '',
      cn: '',
    },
    experience: {
      ko: '現 ㈜스튜디오레논_2020~현재\n<br/>前 ㈜로커스_2016~2020\n<br/> 前 ㈜매크로그래프 _2007~2016',
      en: 'STUDIO LENNON_2020~\n<br/>LOCUS_2016~2020\n<br/> Macrograph_2007~2016',
    },
    extext: {
      ko: '<span>Work Experience</span>\n<br/> Lead Pipeline TD_2016~2020\n<br/> - Maya\n<br/> - Linux\n<br/> - Production work log analysis\n<br/> - 3D Equalizer\n<br/> - Mari\n<br/> - houndini\n<br/> - Shotgun API customizing development',
      en: '<span>Work Experience</span>\n<br/> Lead Pipeline TD_2016~2020\n<br/> - Maya\n<br/> - Linux\n<br/> - Production work log analysis\n<br/> - 3D Equalizer\n<br/> - Mari\n<br/> - houndini\n<br/> - Shotgun API customizing development',
    },
    youtube: {
      ko: '',
      en: '',
    },
    type: 'VFX STUDIO',
    mainImg: [Manyoimg],
  },
  {
    id: 'yoonjiyong',
    name: {
      ko: '윤지용',
      en: 'Yoon Jiyong',
      jp: '',
      cn: '',
    },
    company: {
      ko: 'CD / Storyteller',
      en: 'CD / Storyteller',
      jp: '',
      cn: '',
    },
    experience: {
      ko: '現 ㈜스튜디오레논 대표 CD / 스토리텔러_2020\n<br/>前 마켓쇼 바이럴/디지털크리에이티브 CD\n<br/>前 캠페인 기획실장\n<br/>前 파란고래, 하늘을 날다 기획실장\n<br/>前 TBWA & KOREA 아트디렉터\n<br/>前 이노션 월드와이드 아트디렉터',
      en: 'STUDIO LENNON Representative CD / Storyteller_2020\n<br/>Market Show Viral/Digital Creative CD\n<br/>Chief of Campaign Planning Office\n<br/>Blue Whales Fly in the Sky Chief of Planning\n<br/>TBWA & KOREA art director\n<br/>Innocean Worldwide Art Director',
    },
    extext: {
      ko: '<span>Work Experience</span>\n<br/>',
      en: '<span>Work Experience</span>\n<br/>',
    },
    youtube: {
      ko: "<a href=https://www.youtube.com/watch?v=n-DNSb_HV9c target='blank'>Link</a>[K2] 케이투 플라이하이크 렉스 수지(SUZY)\n<br/><a href=https://www.youtube.com/watch?v=V3YakrX7Yo4 target='blank'>Link</a>[HK이노엔] 컨디션 완벽호환 캠페인(박서준)\n<br/><a href=https://www.youtube.com/watch?v=EqpQfSdki4Y target='blank'>Link</a>[제일제당] 한뿌리 캠페인 _오승환\n<br/><a href=https://www.youtube.com/watch?v=uZcc9K-S8WU target='blank'>Link</a>[LG전자] ThinQ - How to ThinQ 반려동물\n<br/><a href=https://www.youtube.com/watch?v=sPw4YpeU3Cs target='blank'>Link</a>[커스텀멜로우] 2019 F/W Chapter 1.\n<br/><a href=https://www.youtube.com/watch?v=wNnQntQyScA target='blank'>Link</a>[NH투자증권] 투자는 누구나 하는거야\n<br/>",
      en: "<a href=https://www.youtube.com/watch?v=n-DNSb_HV9c target='blank'>Link</a>[k2] fly-hike rex(SUZY)\n<br/><a href=https://www.youtube.com/watch?v=V3YakrX7Yo4 target='blank'>Link</a>[HK Inoen] Condition Perfect Rehabilitation Campaign-Park Seojun S\n<br/><a href=https://www.youtube.com/watch?v=EqpQfSdki4Y target='blank'>Link</a>[Jeil Jedang] a root campaign-Oh Seunghwan\n<br/><a href=https://www.youtube.com/watch?v=uZcc9K-S8WU target='blank'>Link</a>[LG전자] ThinQ - How to ThinQ Pets\n<br/><a href=https://www.youtube.com/watch?v=sPw4YpeU3Cs target='blank'>Link</a>[Custom Mellow] 2019 F/W Chapter 1.\n<br/><a href=https://www.youtube.com/watch?v=wNnQntQyScA target='blank'>Link</a>[NH investment securities] Everybody invests\n<br/>",
    },
    type: 'CREATIVE STUDIO',
    mainImg: [yoonjiyong],
  },
  {
    id: 'kimtaeksoo',
    name: {
      ko: '김택수',
      en: 'Kim Taeksoo',
      jp: '',
      cn: '',
    },
    company: {
      ko: 'Director / E.PD',
      en: 'Director / E.PD',
      jp: '',
      cn: '',
    },
    experience: {
      ko: '前 ㈜마켓쇼, E P D & Director\n<br/>前 밀리그램 필름 앤 플랜, Director\n<br/>前 TBWA KOREA, Producer\n<br/>前 까치앤까치, Diretor\n<br/>',
      en: 'Market Show, E P D & Director\n<br/>milligram film & plan, Director\n<br/>TBWA KOREA, Producer\n<br/>Magpie & Magpie, Diretor\n<br/>',
    },
    extext: {
      ko: '<span>Work Experience</span>\n<br/>',
      en: '<span>Work Experience</span>\n<br/>',
    },
    youtube: {
      ko: "<a href=https://www.youtube.com/watch?v=A1BWHjcRakc target='blank'>Link</a>시티건설\n<br/><a href= target='blank'>Link</a>현대프리미어캠퍼스\n<br/><a href=https://www.youtube.com/watch?v=42nT41mH3K0 target='blank'>Link</a>K2 Ghost /플라이워크 [현빈]\n<br/><a href=https://www.youtube.com/watch?v=SjSCnfpXf34 target='blank'>Link</a>K2 Ghost /플라이워크 [현빈]\n<br/><a href=https://www.youtube.com/watch?v=VRu0iCCNfkE target='blank'>Link</a>매일우유\n<br/><a href=https://www.youtube.com/watch?v=BxNQBiOhJis target='blank'>Link</a>알바천국\n<br/><a href=https://www.youtube.com/watch?v=NabTs0uvgXU target='blank'>Link</a>LG DIOS 얼음정수기 냉장고\n<br/><a href=https://www.youtube.com/watch?v=DXHtn76KmdA target='blank'>Link</a>오로나민C\n<br/>",
      en: "<a href=https://www.youtube.com/watch?v=A1BWHjcRakc target='blank'>Link</a>City Construction\n<br/><a href= target='blank'>Link</a>Hyundai Premiership Campus\n<br/><a href=https://www.youtube.com/watch?v=42nT41mH3K0 target='blank'>Link</a>K2 Ghost /Flywalk-Hyun Bin\n<br/><a href=https://www.youtube.com/watch?v=SjSCnfpXf34 target='blank'>Link</a>K2 Ghost /Flywalk-Hyun Bin\n<br/><a href=https://www.youtube.com/watch?v=VRu0iCCNfkE target='blank'>Link</a>Maeil Milk\n<br/><a href=https://www.youtube.com/watch?v=BxNQBiOhJis target='blank'>Link</a>Alba Heaven\n<br/><a href=https://www.youtube.com/watch?v=NabTs0uvgXU target='blank'>Link</a>LG DIOS ice water purifier refrigerator \n<br/><a href=https://www.youtube.com/watch?v=DXHtn76KmdA target='blank'>Link</a>auronamine C\n<br/>",
    },
    type: 'CREATIVE STUDIO',
    mainImg: [kimtaeksoo],
  },
  // {
  //     "id": "minkwangsup",
  //     "name": {
  //         "ko": "민광섭",
  //         "en": "Min KwangSup",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "company": {
  //         "ko": "Director",
  //         "en": "Director",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "experience": {
  //         "ko":"前 TBWA\n<br/>前 휘닉스커뮤니케이션즈",
  //         "en":"TBWA\n<br/>Phoenix Communications"
  //     },
  //     "extext": {
  //         "ko":"<span>Work Experience</span>\n<br/>",
  //         "en":"<span>Work Experience</span>\n<br/>"
  //     },"youtube" : {
  //         "ko" : "<a href=https://www.youtube.com/watch?v=8CGmUkJ4pIs target='blank'>Link</a>AHC CLUSIV\n<br/><a href=https://vimeo.com/157987322 target='blank'>Link</a>FILA\n<br/><a href=https://www.youtube.com/watch?v=ruoPE9-qob0 target='blank'>Link</a>K2\n<br/><a href=https://www.youtube.com/watch?v=fHFh-XXtqmY target='blank'>Link</a>Asahi\n<br/><a href=https://www.youtube.com/watch?v=G-435cwHEHk target='blank'>Link</a>UNIQLO\n<br/>",
  //         "en" : "<a href=https://www.youtube.com/watch?v=8CGmUkJ4pIs target='blank'>Link</a>AHC CLUSIV\n<br/><a href=https://vimeo.com/157987322 target='blank'>Link</a>FILA\n<br/><a href=https://www.youtube.com/watch?v=ruoPE9-qob0 target='blank'>Link</a>K2\n<br/><a href=https://www.youtube.com/watch?v=fHFh-XXtqmY target='blank'>Link</a>Asahi\n<br/><a href=https://www.youtube.com/watch?v=G-435cwHEHk target='blank'>Link</a>UNIQLO\n<br/>"
  //     },
  //     "type": "CREATIVE STUDIO",
  //     "mainImg": [
  //         minkwangsup
  //     ]
  // },
  {
    id: 'kimhanwook',
    name: {
      ko: '김한욱',
      en: 'Kim Hanwook',
      jp: '',
      cn: '',
    },
    company: {
      ko: 'Art Director',
      en: 'Art Director',
      jp: '',
      cn: '',
    },
    experience: {
      ko: '前 TBWA\n<br/>前 휘닉스커뮤니케이션즈',
      en: 'TBWA\n<br/>Phoenix Communications',
    },
    extext: {
      ko: '<span>Work Experience</span>\n<br/>',
      en: '<span>Work Experience</span>\n<br/>',
    },
    youtube: {
      ko: "<a href=/kimhan target='blank'>View</a>Design 01\n<br/><a href=/kimhan02 target='blank'>View</a>Design 02\n<br/><a href=/kimhan03 target='blank'>View</a>Design 03\n<br/><a href=/kimhan04 target='blank'>View</a>Design 04\n<br/><a href=/kimhan05 target='blank'>View</a>Design 05\n<br/><a href=/kimhan06 target='blank'>View</a>Design 06\n<br/><a href=/kimhan07 target='blank'>View</a>Design 07",
      en: "<a href=/kimhan target='blank'>View</a>Design 01\n<br/><a href=/kimhan02 target='blank'>View</a>Design 02\n<br/><a href=/kimhan03 target='blank'>View</a>Design 03\n<br/><a href=/kimhan04 target='blank'>View</a>Design 04\n<br/><a href=/kimhan05 target='blank'>View</a>Design 05\n<br/><a href=/kimhan06 target='blank'>View</a>Design 06\n<br/><a href=/kimhan07 target='blank'>View</a>Design 07",
    },
    type: 'CREATIVE STUDIO',
    mainImg: [kimhanwook],
  },
  {
    id: 'yoondongheon',
    name: {
      ko: '유동헌 ',
      en: 'Yoo Dongheon ',
      jp: '',
      cn: '',
    },
    company: {
      ko: 'Digital Art',
      en: 'Digital Art',
      jp: '',
      cn: '',
    },
    experience: {
      ko: '前 FATBOY\n<br/>前 아모레퍼시픽/BBDO \n<br/>前 MLAB',
      en: 'FATBOY\n<br/>Amore Pacific/BBDO \n<br/>MLAB',
    },
    extext: {
      ko: '<span>Work Experience</span>\n<br/>',
      en: '<span>Work Experience</span>\n<br/>',
    },
    youtube: {
      ko: "<a href=https://www.youtube.com/watch?v=3-E0mpyr9FQ target='blank'>Link</a>YAMAN 포토플러스_김성령\n<br/><a href=https://www.youtube.com/watch?v=KeDXiW6rIBE target='blank'>Link</a>SUBWAY 박재범\n<br/><a href=https://www.youtube.com/watch?v=4LBKcHlc4OA target='blank'>Link</a>미즈노골프\n<br/><a href= target='blank'>Link</a>유진투자증권_쏙쏙계좌개설_신동엽\n<br/><a href=https://www.youtube.com/watch?v=uZcc9K-S8WU target='blank'>Link</a>LG ThinQ - How to ThinQ\n<br/><a href=https://www.youtube.com/watch?v=Qt9sMv2D0u8 target='blank'>Link</a>아로마티카  위키미키\n<br/><a href=https://www.youtube.com/watch?v=9E_NzsaEOWs target='blank'>Link</a>제일제당 [정면승부]면으로 승부한다\n<br/><a href=https://www.youtube.com/watch?v=M4ZWmbJLQ6s target='blank'>Link</a>맘스터치 딥치즈버거\n<br/>",
      en: "<a href=https://www.youtube.com/watch?v=3-E0mpyr9FQ target='blank'>Link</a>YAMAN Photo-Plus_Kim Sungryung\n<br/><a href=https://www.youtube.com/watch?v=KeDXiW6rIBE target='blank'>Link</a>SUBWAY-Park Jaebum\n<br/><a href=https://www.youtube.com/watch?v=4LBKcHlc4OA target='blank'>Link</a>MIZUNO GOLF\n<br/><a href= target='blank'>Link</a>Eugene Investment & Securities_Opening Sock Sock Account-Shin Dongyup\n<br/><a href=https://www.youtube.com/watch?v=uZcc9K-S8WU target='blank'>Link</a>LG ThinQ - How to ThinQ\n<br/><a href=https://www.youtube.com/watch?v=Qt9sMv2D0u8 target='blank'>Link</a>Aromatica-Weki Meki\n<br/><a href=https://www.youtube.com/watch?v=9E_NzsaEOWs target='blank'>Link</a>Jeil Jedang [Front Match] will win\n<br/><a href=https://www.youtube.com/watch?v=M4ZWmbJLQ6s target='blank'>Link</a>Mom's Touch Deep Cheese Burger\n<br/>",
    },
    type: 'CREATIVE STUDIO',
    mainImg: [yoondongheon],
  },
  {
    id: 'Samsung Galaxy Wallpaper Film',
    name: {
      ko: '최지원 팀장',
      en: 'Choi Jiwon',
      jp: '',
      cn: '',
    },
    company: {
      ko: 'PD',
      en: 'PD',
      jp: '',
      cn: '',
    },
    experience: {
      ko: '現 ㈜스튜디오레논_2020~현재\n<br/>前 ㈜로커스_2013~2020\n<br/>',
      en: 'STUDIO LENNON_2020~\n<br/>LOCUS_2013~2020',
    },
    extext: {
      ko: '<span>Work Experience</span>\n<br/> 삼성 Z폴드2 언팩,삼성 Z플립3 비스포크 에디션,삼성 8K TV,삼성 NEO QLED TV,삼성 UnboxAndDiscover, SK이노베이션,LG화학 브랜드필름,BMW MINI,현대자동차,롯데면세점,배달의민족',
      en: '<span>Work Experience</span>\n<br/> Samsung Z-fold 2 unpack, Samsung Z-flip 3 Bespoke Edition, Samsung 8K TV, Samsung NEO QLED TV, Samsung Unbox AndDiscover, SK Innovation, LG Chem Brand Film, BMW MINI, Hyundai Motor, Lotte Duty Free, Baedal Minjok',
    },
    youtube: {
      ko: '',
      en: '',
    },
    type: 'CM STUDIO',
    mainImg: [Samsung_Galaxy_Wallpaper_Film],
  },
  {
    id: 'Chapi',
    name: {
      ko: '강수영',
      en: 'Kang Sooyoung ',
      jp: '',
      cn: '',
    },
    company: {
      ko: 'Art Director ',
      en: 'Art Director ',
      jp: '',
      cn: '',
    },
    experience: {
      ko: '現 ㈜스튜디오레논 뉴미디어사업 아트디렉터\n<br/>前 ㈜유잠스튜디오 공간연출 및 케릭터&퍼펫 디자이너\n<br/>前 ㈜NTL-inc 원화 디자이너\n<br/>前 ㈜조이토토 원화 디자인&파트팀장\n<br/>前 ㈜웹젠 원화 디자이너\n<br/>前 ㈜드림첼 원화 디자이너\n<br/>前 ㈜사이오넥스 원화 디자이너',
      en: 'STUDIO LENON NEW MEDIA Business Art Director\n<br/>Space Production and Character & Puppet Designers of Yujam Studio\n<br/>NTL-inc Won Designer\n<br/>Joitoto Co., Ltd. Won Design & Part Team Leader\n<br/>Webzen Won Designer\n<br/>Dreamchel won designer\n<br/>Cyonex won Designer',
    },
    extext: {
      ko: '<span>Work Experience</span>\n<br/>&#60;공간연출 및 디자인&#62;\n<br/>중국 은기,대련,창롱,남경 테마파크 컨셉 디자인\n<br/>제주 한화아쿠아플라넷 ,영월 단종,평창-불꽃 퍼펫 디자인 및 무대 컨셉 디자인\n<br/>뮤지컬 정선아리랑, 공룡이살아있다 연출 컨셉 디자인\n<br/>서태지밴드,DJ-DOC,넬 무대 컨셉 디자인 및 작화\n<br/>\n<br/>&#60;Game 원화 디자인&#62;\n<br/>3D mmorpg ‘DragonBall Online’\n<br/>3D mmocfg ‘SADO’\n<br/>3D mmorpg ‘Endless Saga’\n<br/>3D mmorpg ‘August’\n<br/>3D mmorpg ‘Storia’ & 2D mmorpg ‘Arcane’',
      en: '<span>Work Experience</span>\n<br/>&#60;Space Extraction and Design&#62;\n<br/>Conceptual Design of the Chinese Eun-gi, Daeryeon, Changlong, and Nam-kyung Theme Park\n<br/>eju Hanwha Aqua Planet, Yeongwol Danjong, Pyeongchang-Flower Puppet Design and Stage Concept Design Me Won Design\n<br/>The musical Jeongseon Arirang and the dinosaur are alive. directing concept design\n<br/>SEOTAIJI Band,DJ-DOC,NEL Stage Concept Design and Composition\n<br/>\n<br/>&#60;Game Original Design&#62;\n<br/>3D mmorpg ‘DragonBall Online’\n<br/>3D mmocfg ‘SADO’\n<br/>3D mmorpg ‘Endless Saga’\n<br/>3D mmorpg ‘August’\n<br/>3D mmorpg ‘Storia’ & 2D mmorpg ‘Arcane’',
    },
    youtube: {
      ko: '',
      en: '',
    },
    type: 'NEW MEDIA STUDIO',
    mainImg: [mainImg_Chapi],
  },
  {
    id: 'Soofee',
    name: {
      ko: '이승구',
      en: 'Lee Seunggu ',
      jp: '',
      cn: '',
    },
    company: {
      ko: 'Producer',
      en: 'Producer',
      jp: '',
      cn: '',
    },
    experience: {
      ko: '現 ㈜스튜디오레논 기획 프로듀서\n<br/>前 ㈜모팩 콘텐츠사업본부 기획팀장\n<br/>前 ㈜드림써치씨앤씨 제작 프로듀서\n<br/>前 ㈜드림빌 엔터테인먼트 프로듀서 & 연출',
      en: 'Studio Lennon Co., Ltd. Planning and Producer\n<br/>Head of planning team at Moppac Content Business Headquarters\n<br/>Dream Search C&C Production Producer\n<br/>Dreamville Entertainment Producer & Director',
    },
    extext: {
      ko: '<span>Work Experience</span>\n<br/>&#60;Feature Animation&#62;\n<br/>“점박이 한반도의 공룡 3D” 제작 프로듀서\n<br/>“점박이 한반도의 공룡 2 : 새로운 낙원” 초기 기획\n<br/>“슈퍼 빼꼼 : 스파이 대작전” 후반 개봉 지원\n<br/>\n<br/>&#60;Documentary&#62;\n<br/>“끊나지 않은 전쟁” 프로듀서\n<br/>“동티모르에서 만난 맨발의 꿈” 프로듀서 & 연출\n<br/>“갈매기의 꿈” 제작지원\n<br/>\n<br/>&#60;Media Contents&#62;\n<br/>“여수 엑스포 주제관 영상” 공동 프로듀서\n<br/>“CF 서든 어택 초호화 업데이트 3탄” 프로듀서\n<br/>\n<br/>&#60;Planning Project>&#62;\n<br/>“예수의 생애” 극장판 프로모션 영상 사업 관리\n<br/>“워리어 캣츠” 극장판 시나리오 개발\n<br/>“동동” 극장판 기획 관리\n<br/>“황금판다” 시리즈 기획 및 제작 관리\n<br/>“루코레인저스” 시리즈 기획 및 제작 관리\n<br/>“쉽 인 더 아일랜드” 시리즈 기획',
      en: '<span>Work Experience</span>\n<br/>&#60;Feature Animation&#62;\n<br/>Producer and producer of “Dino 3D on Spotted Korean Peninsula”\n<br/>Early planning of “Spotted Korean Dinosaur 2: New Paradise”\n<br/>Support for the second half of “Super Peek: Spy Operation”\n<br/>Producer of the Uninterrupted War\n<br/>Producer & Director of “The Barefoot Dream I Met in East Timor”\n<br/>\n<br/>&#60;Media Contents&#62;\n<br/>Co-producer of “Yeosu Expo Topic Hall Video”\n<br/>Producer of “CF Sudden Attack Super-Luxury Update 3”’\n<br/>\n<br/>&#60;Planning Project>&#62;\n<br/>Life of Jesus “Theater Promotion Video Business Management”\n<br/>Developing a Screenplay for “Warrior Cats”\n<br/>“Dongdong” Theater Edition Planning and Management\n<br/>Planning and Production Management of the “Golden Panda” Series\n<br/>Planning and Production Management of the “Lucor Ranger” Series\n<br/>Planning the “Ship in the Island” series ',
    },
    youtube: {
      ko: '',
      en: '',
    },
    type: 'NEW MEDIA STUDIO',
    mainImg: [mainImg_Soofee],
  },
  //Supermen ID Template
  // {
  //     "id": "",
  //     "name": {
  //         "ko": "",
  //         "en": "",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "company": {
  //         "ko": "",
  //         "en": "",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "experience": {
  //         "ko":"\n<br/>前",
  //         "en":""
  //     },
  //     "extext": {
  //         "ko":"<span>Work Experience</span>\n<br/>",
  //         "en":""
  //     },"youtube" : {
  //         "ko" : "<a href= target='blank'>Link</a>\n<br/>",
  //         "en" : ""
  //     },
  //     "type": "CREATIVE STUDIO",
  //     "mainImg": [
  //         Manyoimg
  //     ]
  // },

  // {
  //     "id": "Www Title",
  //     "name": {
  //         "ko": "Drama",
  //         "en": "Drama",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "company": {
  //         "ko": "WWW - 타이틀 ",
  //         "en": "WWW - Title ",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "type": "MOTION",
  //     "mainImg": [
  //         mainImg_WwwTitle
  //     ]
  // },
  // {
  //     "id": "Samsung Block Chain",
  //     "name": {
  //         "ko": "Viral",
  //         "en": "Viral",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "company": {
  //         "ko": "삼성 블록체인",
  //         "en": "Samsung Blockchain",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "type": "MOTION",
  //     "mainImg": [
  //         mainImg_SamsungBlockchain
  //     ]
  // },
  // {
  //     "id": "Sk Holdings Jjack",
  //     "name": {
  //         "ko": "TVC",
  //         "en": "TVC",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "company": {
  //         "ko": "SK Holdings - JJAK",
  //         "en": "SK Holdings - JJAK",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "type": "MOTION",
  //     "mainImg": [
  //         mainImg_SkHoldingsJjack
  //     ]
  // },
  // {
  //     "id": "Sk Holdings Up",
  //     "name": {
  //         "ko": "TVC",
  //         "en": "TVC",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "company": {
  //         "ko": "SK Holdings - UP",
  //         "en": "SK Holdings - UP",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "type": "MOTION",
  //     "mainImg": [
  //         mainImg_SkHoldingsUp
  //     ]
  // },
  // {
  //     "id": "Naver Series Prenup",
  //     "name": {
  //         "ko": "TVC",
  //         "en": "TVC",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "company": {
  //         "ko": "Naver Series - Prenup",
  //         "en": "Naver Series - Prenup",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "type": "MOTION",
  //     "mainImg": [
  //         mainImg_NaveSeriesprenup
  //     ]
  // },
  // {
  //     "id": "Naver Series Goldenhour",
  //     "name": {
  //         "ko": "TVC",
  //         "en": "TVC",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "company": {
  //         "ko": "Naver Series - Goldenhour",
  //         "en": "Naver Series - Goldenhour",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "type": "MOTION",
  //     "mainImg": [
  //         mainImg_NaveSeriesGoldenhour
  //     ]
  // },
  // {
  //     "id": "My Country",
  //     "name": {
  //         "ko": "Drama",
  //         "en": "Drama",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "company": {
  //         "ko": "나의 나라",
  //         "en": "My Country",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "type": "MOTION",
  //     "mainImg": [
  //         mainImg_MyCountry
  //     ]
  // },
  // {
  //     "id": "Manyo",
  //     "name": {
  //         "ko": "TVC",
  //         "en": "TVC",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "company": {
  //         "ko": "Manyo",
  //         "en": "Manyo",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "type": "MOTION",
  //     "mainImg": [
  //         mainImg_Manyo
  //     ]
  // },
  // {
  //     "id": "Lotte Chemical",
  //     "name": {
  //         "ko": "TVC",
  //         "en": "TVC",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "company": {
  //         "ko": "롯데 케미칼",
  //         "en": "Lotte Chemical",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "type": "MOTION",
  //     "mainImg": [
  //         mainImg_LotteChemical
  //     ]
  // },
  // {
  //     "id": "Hyundai Card Dive",
  //     "name": {
  //         "ko": "TVC",
  //         "en": "TVC",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "company": {
  //         "ko": "Hyundaicard DIVE",
  //         "en": "Hyundaicard DIVE",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "type": "MOTION",
  //     "mainImg": [
  //         mainImg_HyundaeCardDive
  //     ]
  // },
  // {
  //     "id": "Chapi",
  //     "name": {
  //         "ko": "TVC",
  //         "en": "TVC",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "company": {
  //         "ko": "Chapi",
  //         "en": "Chapi",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "type": "MOTION",
  //     "mainImg": [
  //         mainImg_Chapi
  //     ]
  // },
  // {
  //     "id": "Soofee",
  //     "name": {
  //         "ko": "TVC",
  //         "en": "TVC",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "company": {
  //         "ko": "Soofe'e",
  //         "en": "Soofe'e",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "type": "MOTION",
  //     "mainImg": [
  //         mainImg_Soofee
  //     ]
  // },
  // {
  //     "id": "Lineage 2m Bi",
  //     "name": {
  //         "ko": "Show",
  //         "en": "Show",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "company": {
  //         "ko": "Lineage2 M BI",
  //         "en": "Lineage2 M BI",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "type": "MOTION",
  //     "mainImg": [
  //         mainImg_Lineage2M
  //     ]
  // },
  // {
  //     "id": "Blade And Soul",
  //     "name": {
  //         "ko": "TVC",
  //         "en": "TVC",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "company": {
  //         "ko": "Blade & Soul",
  //         "en": "Blade & Soul",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "type": "MOTION",
  //     "mainImg": [
  //         mainImg_BladeAndSoul
  //     ]
  // },
  // {
  //     "id": "Beauty Inside Ending Title",
  //     "name": {
  //         "ko": "Cinema",
  //         "en": "Cinema",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "company": {
  //         "ko": "뷰티인사이드 - 엔딩 타이틀",
  //         "en": "Beauty Inside Ending Title",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "type": "MOTION",
  //     "mainImg": [
  //         mainImg_BeautyInsideEndingTitle
  //     ]
  // },
  // {
  //     "id": "Snowpiercer Title",
  //     "name": {
  //         "ko": "Cinema",
  //         "en": "Cinema",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "company": {
  //         "ko": "설국열차 - 타이틀",
  //         "en": "Snowpiercer-Title",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "type": "MOTION",
  //     "mainImg": [
  //         mainImg_SnowPiercerTitle
  //     ]
  // },
  // {
  //     "id": "Lost Kingdom",
  //     "name": {
  //         "ko": "TVC",
  //         "en": "TVC",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "company": {
  //         "ko": "Lost Kingdom",
  //         "en": "Lost Kingdom",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "type": "MOTION",
  //     "mainImg": [
  //         mainImg_LostKingdom
  //     ]
  // },
  // {
  //     "id": "Lg Optimus G",
  //     "name": {
  //         "ko": "TVC",
  //         "en": "TVC",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "company": {
  //         "ko": "LG Optimus G",
  //         "en": "LG Optimus G",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "type": "MOTION",
  //     "mainImg": [
  //         mainImg_LgOptimusG
  //     ]
  // },
  // {
  //     "id": "Cgv Elevator",
  //     "name": {
  //         "ko": "Cinema",
  //         "en": "Cinema",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "company": {
  //         "ko": "CGV-ELEVATOR",
  //         "en": "CGV-ELEVATOR",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "type": "MOTION",
  //     "mainImg": [
  //         mainImg_CgvElevator
  //     ]
  // },
  // {
  //     "id": "All New Cgv",
  //     "name": {
  //         "ko": "Cinema",
  //         "en": "Cinema",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "company": {
  //         "ko": "All NEW CGV",
  //         "en": "All NEW CGV",
  //         "jp": "",
  //         "cn": ""
  //     },
  //     "type": "MOTION",
  //     "mainImg": [
  //         mainImg_AllNewCgv
  //     ]
  //     }
];
